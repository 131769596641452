import React, {useState, useEffect} from 'react';
import LeadCard from './LeadCard.component';
import NavigationTab from '../common/NavigationTab.component';
import Contact from '../leads/Contact.component';
import FilterPopup from './../leads/Filter-popup.component';
import CandidateProfilePopup from '../leads/candidateProfilePopup.component'
import '../../css/lead.css';
import '../../css/report.css'
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import * as loginService from '../../service/loginService';
import {useHistory} from 'react-router-dom';
import moment from "moment";
import 'react-dates/initialize';
import List from './MonthPicker.component'
import { getCandidatesData } from './../../service/reportService'
import { useLocation } from "react-router-dom";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import useGAEventsTracker from '../../service/useGSEventsTracker';






const ReportLeadPage = () => {

	const candidateStatus = [
        {title: 'Completed 10th Trip', color: '#52C41A', number: 122},
        {title: 'Completed 1th Trip', color: '#FF712D', number: 102},
        {title: 'Activated', color: '#FAAD14', number: 98},
        {title: 'Unique', color: '#389E0D', number: 90},
        {title: 'Non Unique', color: '#FF4D4F', number: 50},
        {title: 'In Process', color: '#8C8C8C', number: 40}
    ]
	const location = useLocation();
	const [breadcrumb, setBreadcrumb] = useState('');
	const [parameters, setParameters] = useState(JSON.parse(localStorage.getItem("data")))
	const [leads, setLeads] = useState([]);
	const [prevLeads, setPrevLeads] = useState([]);
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [showContactBottomSheet, setShowContactBottomSheet] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const [clients, setClients] = useState([
		{label: "select Client", value: 'null'},
		{label: 'Zomato', value: 'Zomato'},
		{label: 'Dunzo', value: 'Dunzo'},
		{label: 'Swiggy', value: 'Swiggy'},
		{label: 'Shadowfax', value: 'Shadowfax'},
		{label: 'Uber', value: 'Uber'},
		{label: 'Rapido', value: 'Rapido'}
	])
	const [clientSelected, setClientSelcted] = useState('null')
	const [extraSearch, setExtraSearch] = useState(false)
    const ReportGAEventsTracker = useGAEventsTracker("Reports")
    const [showMonthChip, setShowMonthChip] = useState(false)




    useEffect(() => {
		const data = decodeURIComponent(location.search.substr(1));
	   	setBreadcrumb(data);
        async function asyncCall() {
            await loginService
                .validateSession()
                .then((response) => {
                    setMitra(response.data.mitra)
					if (response.data.mitra.role && response.data.mitra.role.toLowerCase() === 'admin') {
                        setIsAdmin(true);
                    }
                })
				.catch((error) => history.push('/'));
			}
			getCandidatesData({...parameters, clientName: 'null'})
			.then(response => {
				setLoaded(true)
				setLeads(response.data.data)
				setPrevLeads(response.data.data)
			})
			asyncCall()
    }, []);



	const history = useHistory();

	const setMonth = (data) => {
		const params = parameters;
		const clientData = document.getElementById("client-filter").value;
		params.month = data.month
		params.year = data.year
		setParameters(params);
		setLoaded(false)
        setShowMonthChip(true)
		localStorage.setItem("data", JSON.stringify(params));
		getCandidatesData({...parameters, clientName: clientData})
		.then(response => {
			setLoaded(true)
			setLeads(response.data.data)
			setPrevLeads(response.data.data)
			setLoaded(true)
		})
	}

	const onClientChange = () => {
		const clientData = document.getElementById("client-filter").value;
		setLoaded(false)
		setClientSelcted(clientData)
		getCandidatesData({...parameters, clientName: clientData})
		.then(response => {
			setLoaded(true)
			setLeads(response.data.data)
			setPrevLeads(response.data.data)
			setLoaded(true)
		})

	}


	const searchCandidate = (e) => {
		if (e.target.value == '') {
			setLeads(prevLeads);
		}
		if (!prevLeads.length) {
			setPrevLeads(leads);
		}
		const searchResult = prevLeads.filter(ele => {
			return ele.phoneNumber.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase());
		})
		setLeads(searchResult);
	}

	const handleSheet = async () => {
        const columns = [
            { header: 'Name', key: 'name' },
            { header: 'Phone Number', key: 'phoneNumber' },
            { header: 'Client Name', key: 'clientName' },
            { header: 'Status', key: 'candidateStatus' },
            { header: 'Status Date', key: 'latestStatusTimestamp' },
            { header: 'Team Member Name', key: 'teamMemberName' },
        ];

        let data = [];

		let status = '';
		if(parameters.status === 'unique') status = 'Unique'
		else if(parameters.status === 'completed10thTrip') status = '10 trips Completed'
		else if(parameters.status === 'completed1stTrip') status = 'First Trip Completed'
		else if(parameters.status === 'activated') status = 'Activated'
		else if(parameters.status === 'nonUnique') status = 'Not Unique'
		else if(parameters.status === 'applicationCreated') status = 'Aplication Created'

        leads.map(lead => {
            data.push({name: lead.name, phoneNumber: lead.phoneNumber, clientName: lead.companyName, candidateStatus: status, latestStatusTimestamp: moment(lead.activatedAt).format("DD MMM YYYY"), teamMemberName: lead.mitraName})
          })

          const workSheetName = 'Worksheet-1';
            const workBookName = 'MyWorkBook';
            const myInputId = 'myInput';
            const fileName = 'Report'
          const workbook = new Excel.Workbook();

        try {
            const worksheet = workbook.addWorksheet(workSheetName);
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            data.forEach(singleData => {
                worksheet.addRow(singleData);
              });

              worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;
        
                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                  // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                  const cellAddress = singleCell._address;
        
                  // apply border
                  worksheet.getCell(cellAddress).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                  };
                });
              });
              // write the content using writeBuffer
                const buf = await workbook.xlsx.writeBuffer();

                // download the processed file
                saveAs(new Blob([buf]), `${fileName}.xlsx`);
                } catch (error) {
                console.error('<<<ERRROR>>>', error);
                console.error('Something Went Wrong', error.message);
                } finally {
                // removing worksheet's instance to create new one
                workbook.removeWorksheet(workSheetName);
                }
            }

			const deleteChip = (type, value) => {
				if(type === 'client') {
					document.getElementById("client-filter").value = 'null'
					onClientChange()
				}
				if(type === 'month') {
					const currentMonth = {month: moment().month()+1, year: moment().year()}
					setMonth(currentMonth)
					setShowMonthChip(false);
					setTimeout(() => {
						document.location.reload()
					}, 200)
				}
			}
		
			const getMonth = (month) => {
				const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
				let returnValue = monthList[month.month - 1] + ' ' + month.year
				return returnValue;
			}
		


	return (
		<div >
            <div className="mainContentZ">
				<div className="header">
					<div className="header-text">
					<span className="small-size-screen" style={{cursor: 'pointer'}}  onClick={() => history.push('/report')}><i class="fas fa-arrow-left">&nbsp;&nbsp;</i></span>
						<span className="big-screen">
							<span onClick={() => history.push('/report')} style={{cursor: 'pointer'}}>Report</span> &nbsp;&nbsp;
							<i class="fas fa-chevron-right" style={{color: '#666565'}}></i>&nbsp;&nbsp;
						</span>
						<span style={{fontWeight: '100', color: '#666565'}}>{breadcrumb}</span>
					</div>

					<div style={{display: 'flex', flexDirection: 'row', textAlign: 'right', marginLeft: 'auto'}}>
						<div style={{display: 'flex'}}>
							<div className="header-search">
								<input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
							</div>
							<div className="search-icon" onClick = {() => {if(window.innerWidth < 840) setExtraSearch(!extraSearch)}}>
									<i className="fas fa-search"></i>
							</div>
						</div>
						{ isAdmin ? 
							<div className="header-download">
								<button className="download-button" onClick={() => {handleSheet(); ReportGAEventsTracker("DownloadReportsData");}}>
									<i class="fas fa-download"></i>&nbsp;
									Export Sheets
								</button>
							</div>
						: null }
					</div>
					
				</div>
				{
					extraSearch ?
						<div style={{margin: '10px 0px', padding: '0px 30px'}}>
								<input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
						</div>
					: null
				}
				<div className="more-filters">
					<div className="filter-set-container">
						<div id="category-filter" className=" Dropdown-control">
							<List date={{month: parameters.month, year: parameters.year}} setMonth={setMonth} />&nbsp;
							{/* <i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i> */}
						</div>
						<select id="status-filter" className="Dropdown-control filter-label" style={{ width: '150px' }}
								onChange={() => {
									onClientChange()
								}}>
								{
									clients.map((single_client, index) =>
										<option key={index} className="filter-label" value={single_client.value}>{single_client.label}</option>
									)
								}
							</select>
					</div>
				</div>
				<div className="Chips-container" style={{padding: "0 5%"}}>
					{
                        showMonthChip ?
								<div className="single-chip">
									<span className="chip-text">{getMonth({month: parameters.month, year: parameters.year})}</span>
									<span className="chip-cross" onClick={() => {deleteChip('month')}}> <i className="fas fa-times"></i> </span>
								</div>
                        : null
					}

					{
                        clientSelected !== 'null' ?
                                    <div className="single-chip">
                                        <span className="chip-text">{clientSelected}</span>
                                        <span className="chip-cross" onClick={() => {deleteChip('client')}}> <i className="fas fa-times"></i> </span>
                                    </div>
                        : null
					}
				</div>

				<div className="leads-wrapper">
					{loaded ? leads.length === 0 ? (
						<div className="leads-error">No leads found</div>
					) : (
						leads.map((lead, index) => (
							<LeadCard
								key={index}
								lead={lead}
								setShowDetail={setShowDetail}
								setSelectedLead={setSelectedLead}
								setShowContactBottomSheet={setShowContactBottomSheet}
							/>
						))
					) : (
						<div className="loader_clip">
							<ClipLoader size={100} />
						</div>
					)}
					<br />
				</div>
				<div className="bottom-detail-wrapper">
					<Contact visible={showContactBottomSheet} setShowContact={setShowContactBottomSheet} lead={selectedLead} />
					<CandidateProfilePopup visible={showDetail} setShowDetail={setShowDetail} lead={selectedLead}></CandidateProfilePopup>
				</div>
			</div>
			<div className="navigation-container">
				<NavigationTab currentTab={3} />
			</div>

		</div>
	);
};

export default ReportLeadPage;
