import React, {useState, useEffect , useContext} from 'react'
import * as loginService from '../../service/loginService';
import {useHistory} from 'react-router-dom';
import FilterPopup from '../leads/Filter-popup.component';
import {getBulkUniquenessList, getScheduledIVRList} from '../../service/bulkActionService'
import {useLocation} from "react-router-dom";
import Excel from 'exceljs';
import {saveAs} from 'file-saver';
import Moment from 'react-moment';
import NavigationTab from './../common/NavigationTab.component';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import {UserContext} from '../../UserContext';

const LeadManagement = () => {
    const context = useContext(UserContext);
    // const [mitra, setMitra] = useState({})
    const [isAdmin, setIsAdmin] = useState(false);
    const DEFAULT = 'DEFAULT';
    const [selection, setSelection] = useState(true)
    const [dateSelected, setDateSelected] = useState('')
    const [openSearch, setOpenSearch] = useState(false)
    const [prevLeads, setPrevLeads] = useState([]);
    const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
    const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
    const [showIVRStatusFilterPopup, setIVRStatusShowFilterPopup] = useState(false);
    const [showResponseFilterPopup, setResponseShowFilterPopup] = useState(false);
    const [ivrLeads, setIVRLeads] = useState([])
    const [showFilterChpis, setShowFilterChips] = useState(false)
    const [showDateChip, setShowDateChip] = useState(false)
    const [clientSelected, setClientSelcted] = useState([
        {label: 'Zomato', value: false, backendValue: 'Zomato'},
        {label: 'Shadowfax', value: false, backendValue: 'Shadowfax'}
    ])
    const [bulkStatusesSelected, setBulkStatusesSelected] = useState([
        {label: 'Pending', value: false, backendValue: 'pendingStatus'},
        {label: 'Unique', value: false, backendValue: 'uniqueStatus'},
        {label: 'Not Unique', value: false, backendValue: 'nonUniqueStatus'}
    ])
    const [bulkIVRStatusesSelected, setBulkIVRStatusesSelected] = useState([
        {label: 'Call Connected', value: false, backendValue: 'Completed'},
        {label: 'Call Disconnected(Hangup)', value: false, backendValue: 'Hangup'},
        {label: 'IVR Scheduled(Queued)', value: false, backendValue: 'Initiated'},
        {label: 'IVR Scheduled', value: false, backendValue: 'Created'},
        {label: 'Call Disconnected(DND)', value: false, backendValue: 'DND User'},
        {label: 'Call Disconnected(Failed)', value: false, backendValue: 'Failed'},
    ])
    const [bulkResponseSelected, setBulkResponseSelected] = useState([
        {label: 'No Bike', value: false, backendValue: 'No Bike'},
        {label: 'Has Bike', value: false, backendValue: 'Has Bike'},
        {label: 'Interested', value: false, backendValue: 'Interested'},
        {label: 'Not Interested', value: false, backendValue: 'Not Interested'},
        {label: 'Have Driving License', value: false, backendValue: 'Have Driving License'},
        {label: 'No Driving License', value: false, backendValue: 'No Driving License'}
    ])
    const statusColorMapping = {
        "Call Connected": "rgb(75 170 90)",
        "Call Disconnected(Hangup)": "rgb(168 35 35)",
        "IVR Scheduled(Queued)": "rgb(32 184 170)",
        "IVR Scheduled": '#000',
        "Call Disconnected(DND)": 'color: rgb(255 0 0)',
        "Call Disconnected(Failed)": 'rgb(244 116 55)'
    };
    const [leads, setLeads] = useState([])
    const history = useHistory();
    const search = useLocation().search;
    const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")


    useEffect(() => {
        let previousFilters;
        const tab = new URLSearchParams(search).get('tab');
        if(tab === 'bulk-uniqueness'){
            setSelection(true)
            previousFilters = JSON.parse(localStorage.getItem("filters"))
            console.log(previousFilters)
            if(previousFilters){
                setBulkStatusesSelected(previousFilters.status);
                setDateSelected(previousFilters.date);
                if(previousFilters.date != new Date().toISOString().substr(0, 10)){
                    setShowDateChip(true)
                }
                setClientSelcted(previousFilters.client);
            }
            else{
                setDateSelected(new Date().toISOString().substr(0, 10))
            }
        }
        else{
            setSelection(false)
            previousFilters = JSON.parse(localStorage.getItem("filters"))
            if(previousFilters){
                setBulkIVRStatusesSelected(previousFilters.bulkIVRStatus);
                setDateSelected(previousFilters.date);
                if(previousFilters.date != new Date().toISOString().substr(0, 10)){
                    setShowDateChip(true)
                }
                setBulkResponseSelected(previousFilters.bulkResponse);
            }
            else{
                setDateSelected(new Date().toISOString().substr(0, 10))
            }
        }
    }, [])

    useEffect(() => {
        // const bulkUploadUniquenessLeadId = new URLSearchParams(search).get('id');
        if (selection) {
            let clientArray = [], statusArray = [];
            clientSelected.map(ele => {
                if (ele.value) {
                    clientArray.push(ele.backendValue.toLowerCase())
                }
            })
            bulkStatusesSelected.map(ele => {
                if (ele.value) {
                    statusArray.push(ele.backendValue)
                }
            })
            localStorage.setItem("filters", JSON.stringify({date: dateSelected, client: clientSelected, status: bulkStatusesSelected}))
            //  localStorage.setItem("filters", JSON.stringify({date: dateSelected, client: clientSelected, status: bulkStatusesSelected}))
            const data = {
                startDate: dateSelected + ' 00:00:01',
                endDate: dateSelected + ' 23:59:59',
                client: JSON.stringify(clientArray),
                status: JSON.stringify(statusArray),
                mitraManagerId: context.mitra?.id,
            }
            if(context.mitra){
                getBulkUniquenessList(data).then((response) => {
                    if (response.data.data)
                        setLeads(response.data.data)
                    setPrevLeads(response.data.data)
                }).catch(e => {
                    console.log(e);
                })
            }  
        }
        else {
            let IVRStatusArray = [], resposeArray = [];

            bulkIVRStatusesSelected.map(ele => {
                if (ele.value) {
                    IVRStatusArray.push(ele.label)
                }
            })

            bulkResponseSelected.map(ele => {
                if (ele.value) {
                    resposeArray.push(ele.backendValue)
                }
            })
            localStorage.setItem("filters", JSON.stringify({date: dateSelected, bulkIVRStatus: bulkIVRStatusesSelected, bulkResponse: bulkResponseSelected}))
            const data = {
                startDate: dateSelected + ' 00:00:01',
                endDate: dateSelected + ' 23:59:59',
                status: JSON.stringify(IVRStatusArray),
                response: JSON.stringify(resposeArray)
            }
            getScheduledIVRList(data).then((response) => {
                if (response.data.data)
                    setIVRLeads(response.data.data)
                setPrevLeads(response.data.data)
            }).catch(e => {
                console.log(e);
            })
        }

    }, [dateSelected, clientSelected, bulkStatusesSelected, bulkResponseSelected, bulkIVRStatusesSelected, selection ,context])


    const changeSelection = () => {
        clientSelected.map(ele => {
            ele.value = false
        })
        bulkStatusesSelected.map(ele => {
            ele.value = false;
        })
        bulkIVRStatusesSelected.map(ele => {
            ele.value = false;
        })
        bulkResponseSelected.map(ele => {
            ele.value = false;
        })
    }

    const deleteChip = (name, item) => {
        if (name === 'response') {
            const currenArray = [...bulkResponseSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkResponseSelected(currenArray)
        }
        else if (name === 'IVRStatus') {
            const currenArray = [...bulkIVRStatusesSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkIVRStatusesSelected(currenArray)
        }
        else if (name === 'status') {
            const currenArray = [...bulkStatusesSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    ele.value = false;
                }
            })
            setBulkStatusesSelected(currenArray)
        }
        else if (name === 'client') {
            const currenArray = [...clientSelected]
            currenArray.map(ele => {
                if (ele.label === item) {
                    console.log(name, item)
                    ele.value = false;
                }
            })
            setClientSelcted(currenArray)
        }
        else if (name === 'date') {
            setDateSelected(new Date().toISOString().substr(0, 10))
            document.getElementById("date").value = new Date().toISOString().substr(0, 10)
        }
    }

    const searchCandidate = (e) => {
        if (selection) {
            if (prevLeads.length === 0) {
                setPrevLeads(leads)
            }
            if (e.target.value.length === 0) {
                setLeads(prevLeads);
            }
            const searchResult = prevLeads.filter(ele => {
                return ele.phoneNumber.includes(e.target.value) || ele.firstName.toLowerCase().includes(e.target.value.toLowerCase());
            })
            setLeads(searchResult);
        }
        else {
            if (prevLeads.length === 0) {
                setPrevLeads(leads)
            }
            if (e.target.value.length === 0) {
                setIVRLeads(prevLeads);
            }
            const searchResult = prevLeads.filter(ele => {
                return ele.phoneNumber.includes(e.target.value) || ele.firstName.toLowerCase().includes(e.target.value.toLowerCase());
            })
            setIVRLeads(searchResult);
        }

    }

    const mapIVRStatus = (status) => {
        let return_value;
        bulkIVRStatusesSelected.map(ele => {
            if (status == ele.backendValue) {
                return_value = ele.label
            }
        })
        return return_value
    }


    const downloadSheet = async () => {
		let data = [];
		let columns;

		if (selection) {
			 columns = [
				{header: 'Name', key: 'name'},
				{header: 'Phone Number', key: 'phoneNumber'},
				{header: 'Status', key: 'status'},
				{header: 'Client', key: 'client'}
			];


			leads.map(ele => {
				data.push({name: ele.candidateName, phoneNumber: ele.candidatePhoneNumber, status: displayBulkUniqueStatus(ele.candidateStatus, ele.preferredClient), client: ele.preferredClient})
			})

			
		}
		else {
			 columns = [
				{header: 'Name', key: 'name'},
				{header: 'Phone Number', key: 'phoneNumber'},
				{header: 'IVRStatus', key: 'ivrStatus'},
				{header: 'Interest', key: 'interest'},
				{header: 'Driving License', key: 'dl'},
				{header: 'Bike', key: 'bike'}
			];


			ivrLeads.map(ele => {
				data.push({name: ele.firstName, phoneNumber: ele.phoneNumber, ivrStatus: mapIVRStatus(ele.status), interest: ele.answer[0], dl: ele.answer[1], bike: ele.answer[2]})
			})

			
		}


		const workSheetName = 'Worksheet-1';
			const workBookName = 'MyWorkBook';
			const myInputId = 'myInput';
			const fileName = 'Report'
			const workbook = new Excel.Workbook();

			try {
				const worksheet = workbook.addWorksheet(workSheetName);
				worksheet.columns = columns;
				worksheet.getRow(1).font = {bold: true};

				data.forEach(singleData => {
					worksheet.addRow(singleData);
				});

				// loop through all of the columns and set the alignment with width.
				worksheet.columns.forEach(column => {
					column.width = column.header.length + 5;
					column.alignment = {horizontal: 'center'};
				});

				worksheet.eachRow({includeEmpty: false}, row => {
					// store each cell to currentCell
					const currentCell = row._cells;

					// loop through currentCell to apply border only for the non-empty cell of excel
					currentCell.forEach(singleCell => {
						// store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
						const cellAddress = singleCell._address;

						// apply border
						worksheet.getCell(cellAddress).border = {
							top: {style: 'thin'},
							left: {style: 'thin'},
							bottom: {style: 'thin'},
							right: {style: 'thin'}
						};
					});
				});
				// write the content using writeBuffer
				const buf = await workbook.xlsx.writeBuffer();

				// download the processed file
				saveAs(new Blob([buf]), `${fileName}.xlsx`);
			} catch (error) {
				console.error('<<<ERRROR>>>', error);
				console.error('Something Went Wrong', error.message);
			} finally {
				// removing worksheet's instance to create new one
				workbook.removeWorksheet(workSheetName);
			}
	}

    const displayBulkUniqueStatus = (status, companyName) => {
		if(companyName.toLowerCase() === 'zomato'){
			if(status.toLowerCase() === 'uploaded'){
				return 'Unique'
			}
			else if(status.toLowerCase() === 'not uploaded'){
				return 'Not Unique'
			}
			if(status.toLowerCase() === 'api failed'){
				return 'Pending'
			}
		}
		else if(companyName.toLowerCase() === 'shadowfax'){
			if(status.toLowerCase() === 'created'){
				return 'Unique'
			}
			else if(status.toLowerCase() === 'not uploaded'){
				return 'Not Unique'
			}
			if(status.toLowerCase() === 'uploaded'){
				return 'Pending'
			}
		}
	}

    return (
        <>
            <div className="Home mainContentZ">
                <div className="header">
                    <div className="header-text">
                        <span className="small-size-screen" style={{cursor: 'pointer'}} onClick={() => history.push("/")}>
                            <i class="fas fa-arrow-left left-arrow"></i>
                        </span>
                        <span className="welcome">Lead Management</span>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', textAlign: 'right', marginLeft: 'auto'}}>
                        <div style={{display: 'flex'}}>
                            <div className="header-search">
                                <input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
                            </div>
                            <div className="search-icon" onClick={() => {if (window.innerWidth < 840) setOpenSearch(!openSearch)}}>
                                <i className="fas fa-search"></i>
                            </div>
                        </div>
                        {isAdmin ?
                            <div className="header-download">
                                <button className="download-button" onClick={() => {downloadSheet(); BulkActionGAEventsTracker("DownloadBulkActionSheet");}}>
                                    <i class="fas fa-download"></i>&nbsp;
                                    Export Sheets
                                </button>
                            </div>
                            : null}
                    </div>

                </div>


                {
                    openSearch ?
                        <div style={{padding: '3px 5px'}}>
                            <div className="header-search">
                                <input className="search-input-box" type="text" onInput={searchCandidate} placeholder="Search Candidates" />
                            </div>
                        </div>
                        : null
                }

                <div className="filter-container">
                    <div className="filter-set-container">
                        <input type='date' id="date" className="Dropdown-control" defaultValue={dateSelected} onChange={(event) => {setDateSelected(event.target.value); setShowDateChip(true)}}></input>
                        {
                            selection ?
                                <>
                                    <div id="category-filter"
                                        onClick={() => {
                                            setCategoryShowFilterPopup(true)
                                        }}
                                        className=" Dropdown-control"
                                    >
                                        <div className="filter-label">Clients</div>
                                        <i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
                                    </div>

                                    <div id="status-filter"
                                        onClick={() => {
                                            setStatusShowFilterPopup(true);
                                        }}
                                        className="Dropdown-control">
                                        <div className="filter-label">Status</div>
                                        <i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
                                    </div>
                                </>
                                :
                                <>
                                    <div id="category-filter"
                                        onClick={() => {
                                            setIVRStatusShowFilterPopup(true)
                                        }}
                                        className=" Dropdown-control"
                                    >
                                        <div className="filter-label">IVR Status</div>
                                        <i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
                                    </div>

                                    <div id="status-filter"
                                        onClick={() => {
                                            setResponseShowFilterPopup(true);
                                        }}
                                        className="Dropdown-control">
                                        <div className="filter-label">Response</div>
                                        <i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
                                    </div>
                                </>
                        }

                    </div>
                    <div className="Chips-container" style={{padding: '0px 10px'}}>
                        {
                            showDateChip ?
                                <div className="single-chip">
                                    <span className="chip-text">
                                        <Moment format="DD MMM YYYY" withTitle>{dateSelected}</Moment>
                                    </span>
                                    <span className="chip-cross" onClick={() => {deleteChip('date', dateSelected); setShowDateChip(false)}}> <i className="fas fa-times"></i> </span>
                                </div>
                                : null
                        }
                        {
                            selection ?
                                <>
                                    {
                                        clientSelected.map((ele) => {
                                            if (ele.value) {
                                                return <div className="single-chip">
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('client', ele.label)}}> <i className="fas fa-times"></i> </span>
                                                </div>
                                            }
                                        })
                                    }

                                    {
                                        bulkStatusesSelected.map((ele) => {
                                            if (ele.value) {
                                                return <div className="single-chip">
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('status', ele.label)}}> <i className="fas fa-times"></i> </span>
                                                </div>
                                            }
                                        })
                                    }
                                </>
                                : null
                        }
                        {
                            !selection ?
                                <>
                                    {
                                        bulkIVRStatusesSelected.map((ele) => {
                                            if (ele.value) {
                                                return <div className="single-chip">
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('IVRStatus', ele.label)}}> <i className="fas fa-times"></i> </span>
                                                </div>
                                            }
                                        })
                                    }

                                    {
                                        bulkResponseSelected.map((ele) => {
                                            if (ele.value) {
                                                return <div className="single-chip">
                                                    <span className="chip-text">{ele.label}</span>
                                                    <span className="chip-cross" onClick={() => {deleteChip('response', ele.label)}}> <i className="fas fa-times"></i> </span>
                                                </div>
                                            }
                                        })
                                    }
                                </>
                                : null
                        }
                    </div>
                </div>
                <div className="bg-white" id="exTab2" style={{margin: '0px', width: '100%'}}>
                    <div className="row bg-white" style={{margin: '0px'}}>
                        <div className="col-md-6 col-6"><span style={selection ? {
                            paddingBottom: '8px',
                            borderBottom: '1px solid rgb(211, 70, 18)',
                            color: '#D34612',
                            cursor: 'pointer'} : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {setSelection(true); changeSelection(); localStorage.setItem("filters", JSON.stringify({date: dateSelected, client: clientSelected, status: bulkStatusesSelected}) ) ; history.push("/lead-management?tab=bulk-uniqueness")}}>Bulk Uniqueness</span></div>
                        <div className="col-md-6 col-6"><span style={!selection ? {
                            paddingBottom: '8px',
                            borderBottom: '1px solid rgb(211, 70, 18)',
                            color: '#D34612',
                            cursor: 'pointer'
                        } : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {setSelection(false); changeSelection(); localStorage.setItem("filters", JSON.stringify({date: dateSelected, bulkIVRStatus: bulkIVRStatusesSelected, bulkResponse: bulkResponseSelected}) ) ; history.push("/lead-management?tab=bulk-IVR")}}>Bulk IVR calls</span></div>
                        <div className="col-12 col-md-12" style={{marginBottom: window.innerWidth >= 800 ? '0px' : '80px'}}>
                            <div className="list-scroll-x">
                                <table className="table table-responsive">
                                    <thead>
                                        <tr style={{backgroundColor: '#FAFAFA'}}>
                                            <th scope="col">Candidate Name</th>
                                            <th scope="col">Mobile No.</th>
                                            <th scope="col">{selection ? 'Candidate Status' : 'IVR status'}</th>
                                            <th scope="col">{selection ? 'Client Preference' : 'Candidate Response'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selection ?
                                            leads.map((lead, index) =>
                                                <>
                                                    <tr key={index}>
                                                        <td>{lead.candidateName}</td>
                                                        <td>{lead.candidatePhoneNumber}</td>
														<td style={{color: displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient) === 'Unique' ? '#D34612' : displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient) === 'Not Unique' ? '#F5222D' : displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient) === 'Pending' ? '#2F54EB' : '#262626'}}>{displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient)}</td>
                                                        <td>{lead.preferredClient}</td>
                                                    </tr>
                                                </>
                                            )
                                            :
                                            ivrLeads.map((lead, index) =>
                                                <>
                                                    <tr key={index}>
                                                        <td>{lead.firstName}</td>
                                                        <td>{lead.phoneNumber}</td>
                                                        <td style={{color: statusColorMapping[mapIVRStatus(lead.status)] ? statusColorMapping[mapIVRStatus(lead.status)] : '#000'}}>{mapIVRStatus(lead.status)}</td>
                                                        <td>
                                                            {
                                                                lead.questions.map((q, i) => (
                                                                    <>
                                                                        {q.toLowerCase().search('interest') !== -1 ? <b> Interested  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
                                                                        {q.toLowerCase().search('driving') !== -1 ? <b>  Driving License  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
                                                                        {q.toLowerCase().search('bike') !== -1 ? <b> Bike  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
                                                                    </>
                                                                ))
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        }
                                    </tbody>
                                </table>
                                {
                                    selection && leads.length === 0 ? <div className="leads-error">No leads found</div> : null
                                }
                                {
                                    !selection && ivrLeads.length === 0 ? <div className="leads-error">No leads found</div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-detail-wrapper">
                    <FilterPopup heading="Select Status" visible={showStatusFilterPopup} setShowFilterPopup={setStatusShowFilterPopup} setCategory={setBulkStatusesSelected} list={bulkStatusesSelected} />
                    <FilterPopup heading="Select Client" visible={showCategoryFilterPopup} setShowFilterPopup={setCategoryShowFilterPopup} setCategory={setClientSelcted} list={clientSelected} />
                    <FilterPopup heading="Select IVR Status" visible={showIVRStatusFilterPopup} setShowFilterPopup={setIVRStatusShowFilterPopup} setCategory={setBulkIVRStatusesSelected} list={bulkIVRStatusesSelected} />
                    <FilterPopup heading="Select Response" visible={showResponseFilterPopup} setShowFilterPopup={setResponseShowFilterPopup} setCategory={setBulkResponseSelected} list={bulkResponseSelected} />
                </div>
            </div>
            <div className="navigation-container">
                <NavigationTab currentTab={0} mitra={context.mitra} />
            </div>
        </>
    )
}

export default LeadManagement;