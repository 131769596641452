import React,{useState,useEffect} from 'react';
import './App.css';
import LogInPage from './components/login/LogInPage.component';
import LeadsPage from './components/leads/LeadsPage.component';
import SearchPage from './components/SearchPage.component';
import JobsPage from './components/jobs/AddJob.component';
import AdditionalInfoPage1 from './components/additionalInfo/AdditionalInfo1.component';
import JobDemandsPage from './components/JobDemandsPage.component';
import UploadPeriscopeDataPage from './components/UploadPeriscopeData.component';
import UploadMarketingDataPage from './components/UploadMarketingData.component';
import UploadOnBoardingDataPage from './components/UploadOnBoardingData.component';
import FileUploader from './components/FileUploader.component';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import SignupPage from './components/signup/SignupPage.component';
import AccountPage from './components/account/AccountPage.component';
import UploadDocumentsPage from './components/account/UploadMitraDocsPage.compoent';
import LogoutPage from './components/logout/LogoutPage.component';
import TermsAndConditions from './components/TermsAndConditions';
import TrainingSessionPage from './components/account/TrainingSessionPage.component';
import ReportPage from './components/report/ReportPage.component';
import ReportLeadPage from './components/report/ReportLeadPage.component';
import EarningPage from './components/report/EarningPage.component';
import * as loginService from './service/loginService';
import useMoengage from './hooks/useMoengage';
import LeadManagement from './components/bulk-action/Lead-management.component'
import BulkAction from './components/bulk-action/Bulk-action.component';
import {UserContext} from './UserContext';

ReactGA.initialize('UA-204663297-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const [createMoengageUser, trackEvent] = useMoengage();
  const [mitra, setMitra] = useState(null)

  useEffect(() => {
    loginService
			.validateSession()
			.then((response) => {
				createMoengageUser(response.data.mitra);
        setMitra(response.data.mitra)
			})
  },[]);

  

      const clearCacheData = () => {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        console.log('Complete Cache Cleared')
      };

      clearCacheData()


  return (
    <Router>
        <div className="App">
          <UserContext.Provider value={{mitra,setMitra}}>
            <Route path="/" component={LogInPage} exact />
            <Route path="/leads" component={LeadsPage} exact />
            <Route path="/lead-management" component={LeadManagement} exact />
            {/* <Route path="/search" component={SearchPage} exact /> */}
            <Route path="/report" component={ReportPage} exact />
            <Route path="/leads-page" component={ReportLeadPage} exact />
            <Route path="/earnings" component={EarningPage} exact />
            <Route path="/account/userManagement" component={LogoutPage} exact />
            <Route path="/account" component={AccountPage} exact />
            <Route path="/jobs" component={JobsPage} exact />
            <Route path="/moreInfo" component={AdditionalInfoPage1} exact />
            <Route path="/jobDemands" component={JobDemandsPage} exact />
            <Route path="/upload-periscope-data" component={UploadPeriscopeDataPage} exact />
            <Route path="/upload-marketing-data" component={UploadMarketingDataPage} exact />
            <Route path="/upload-onBoarding-data" component={UploadOnBoardingDataPage} exact />
            <Route path="/file-uploader" component={FileUploader} exact />
            <Route path="/signup" component={SignupPage} exact />
            <Route path="/account/uploadDocuments" component={UploadDocumentsPage} exact />
            <Route path="/termsAndConditions" component={TermsAndConditions} exact />
            <Route path="/account/trainingSession" component={TrainingSessionPage} exact />
            <Route path="/bulk-action" component={BulkAction} exact />
          </UserContext.Provider>
        </div>
    </Router>
  );
}

export default App;
