import React, { useEffect, useState } from "react"
import {Form} from "react-formio";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { getMitraProfileData, postMitraDocs } from "../../service/uploadMitraDocsService";
import { validateSession } from "../../service/loginService";
import NavigationTab from "../common/NavigationTab.component";
import MainLogoHeader from "../header/MainLogoHeader";
import "./../../css/uploadDocs.css"
import useMoengage from '../../hooks/useMoengage';

const UploadDocumentsPage = (props) => {

    const [mitra, setMitra] = useState(null)
    const [loading, setLoading] = useState(true)
    const [disabledFields, setDisabledFields] = useState({
        cancelledCheque: false,
        bankPassbook: false
    })
    const [submissionData, setSubmissionData] = useState({
        data: {

        }
    })
    const history = useHistory()
    const [createMoengageUser, trackEvent] = useMoengage();
    const options = {
        disabled: disabledFields,
        hooks: {
            beforeSubmit: async (submission, next) => {
                let errors = [];
                try {
                    const res = await postMitraDocs(submission.data, mitra)
                    for(let key in res.data){
                        if(res.data[key]!== "200")
                            errors.push({message: `${key}: ${res.data[key].split("Error: ").join("").split("500: ").join("")}`})
                        else{
                            if (key === "bankPassbook")
                                var value = "Passbook"
                             else if (key === "cancelledCheque")
                                var value = "Passbook"

                             trackMoengageEvent('bank_document_uploaded',{documentType: value})
                        }
                    }
                } catch (error) {
                    if(error.response)
                        errors = error.response.data.message ? [{message: error.response.data.message}]: [{message: "something went wrong"}]
                }

                if(errors.length > 0 ){
                    next(errors)
                }
                else{

                    history.replace("/account")
                }
            }
        }
    }

    useEffect(() => {

        validateSession()
			.then((response) => {
				setMitra(response.data.mitra)
                return getMitraProfileData(response.data.mitra.phoneNumber)
			})
            .then((mitraInfoRes) => {
                for(let key in mitraInfoRes.data) {
                    if(mitraInfoRes.data[key].type === 'bankPassbook' || mitraInfoRes.data[key].type === 'cancelledCheque'){
                        setDisabledFields({
                            cancelledCheque: true,
                            bankPassbook: true,
                            submit: true
                        })

                        let imageUrl, size, originalName;
                        try {
                            const parsedData = JSON.parse(mitraInfoRes.data[key].value);
                            imageUrl = parsedData.url;
                            size = parsedData.size;
                            originalName = parsedData.url;
                        } catch (error) {
                            imageUrl = undefined
                            size= undefined
                            originalName = undefined
                        }
                        setSubmissionData({
                            data: {
                                [mitraInfoRes.data[key].type]: [{
                                    storage: "url",
                                    url: imageUrl,
                                    size,
                                    originalName
                                }]
                            }
                        })
                        break;
                    }
                }
                setLoading(false)
            })
			.catch((error) => {history.replace("/")});
	}, [history]);



    const onChange = (submission) => {
        const key = submission.changed ? submission.changed.component.key : undefined
        if(key && submission.data[key] && submission.data[key].length > 0 && submission.data[key][0].data){
            submission.data[key][0].url = submission.data[key][0].data.imageUrl
            setSubmissionData({data: submission.data})
        }
    }

    const trackMoengageEvent = (event,obj) => {
        trackEvent(event, obj);
    }

    return (
        <React.Fragment>
            <div className="UploadDocs mainContentZ">
                <MainLogoHeader backButton />
                <h1>Upload bank passbook/ cheque</h1>
                <p className="warning col-12 col-sm-8 col-lg-6 col-xl-4">
                    <br></br>
                    You can not modify you documents from the app for security reasons.
                    To modify your documents please drop a mail to hr@vahan.co from your registered email Id
                </p>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4">
                        { loading ?
                            <ClipLoader size={100} /> :
                            <Form src={process.env.REACT_APP_FORM_IO_MITRA_DOCS_FORM_URL} onChange={onChange} submission={submissionData} options={options}/>
                        }
                    </div>
                    <div className="col"></div>
                </div>
            </div>
            <div className="navigation-container">
                <NavigationTab currentTab={4} />
            </div>
        </React.Fragment>
    )
}

export default UploadDocumentsPage;