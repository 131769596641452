import axios from 'axios';

export const getJobDemandsData = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getJobDemandsData`;
    return axios.get(url, { withCredentials: true });
};

export const getJobDemandsOnFiltersChange = async (city, client, vertical) => {
    const data = new FormData();
    data.append('minSalary', 'DEFAULT');
    data.append('maxSalary', 'DEFAULT');
    data.append('city', city);
    data.append('client', client);
    data.append('category', vertical);
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnFiltersChange';
    return axios.post(url, data, { withCredentials: true });
};

export const getJobDemandsOnCityChange = async (city) => {
    const data = new FormData();
    data.append('city', city);
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnCityChange';
    return axios.post(url, data, { withCredentials: true });
};

export const getJobDemandsOnCategoryChange = async (category) => {
    const data = new FormData();
    data.append('category', category);
    let url = process.env.REACT_APP_BACKEND_URL + '/getJobDemandsOnCategoryChange';
    return axios.post(url, data, { withCredentials: true });
};