import React, {useState} from 'react'
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import '../../css/report.css'
import { useHistory } from 'react-router-dom';
import NavigationTab from '../common/NavigationTab.component';




const EarningPage = () => {

	const history = useHistory();

    const getfirstDateOfMonth = () => {
		const date = moment();
		const firstDate = moment().startOf('month');
		return firstDate;
	}
    const [startDate, setStartDate] = useState(getfirstDateOfMonth());
	const [endDate, setEndDate] = useState(moment());
	const [focusedInput, setFocusedInput] = useState(null);

    const pendingTableList = [
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'},
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'}
    ]

    const paidTableList = [
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'},
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'},
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'},
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'},
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'},
        {title: 'Unit Payout - Zomato', criteria: 'Rs 200/ Placement', status: 'Pending' , Date: '12 Oct 2021', amount: '12000', numberOfPlacement: '10'}
        
    ]
    return (
        <div>
            <div className="header">
				<div className="header-text">
                    <span className="small-size-screen" style={{cursor: 'pointer'}}  onClick={() => history.push('/report')}><i className="fas fa-arrow-left">&nbsp;&nbsp;</i></span>
                    <span className="big-screen" >
					    <span onClick={() => history.push('/report')} style={{cursor: 'pointer'}}>Report</span> &nbsp;&nbsp;
                        <i className="fas fa-chevron-right" style={{color: '#666565'}}></i>&nbsp; &nbsp; 
                    </span>
                    <span style={{fontWeight: '100', color: '#666565'}}>Total Earnings</span>
				</div>
                <div style={{textAlign: 'right', width: '50%', marginLeft: 'auto'}}>
                    <div className="header-download">
                        <button className="download-button">
                            <i className="fas fa-download"></i>&nbsp;
                            Export Sheets
                        </button>
                    </div>
                </div>
			</div>
            <div className="more-filters">
				<div className="filter-set-container">
                    <div className="date-range">
                        <DateRangePicker
							startDate={startDate}
							startDateId="s_id"
							endDate={endDate}
							endDateId="e_id"
							onDatesChange={({startDate, endDate}) => {setStartDate(startDate); setEndDate(endDate)}}
							focusedInput={focusedInput}
							onFocusChange={e => setFocusedInput(e)}
							displayFormat="DD/MM/YYYY"
							isOutsideRange={(date) => {
								if (date.isAfter(moment())) {
									return true
								}
								return false;
							}}
							numberOfMonths={1}
						/>
                    </div>
				</div>
			</div>
            <div className="total-container">

                <div className="table-container">
                    <table className="table-layout">
                        <thead>
                            <tr>
                                <th className="table-heading">Payment Infomation</th>
                                <th className="table-heading"><span style={{fontWeight: 'bold', color:'black'}}>Pending Amount</span> and Candidate Details</th>
                            </tr>
                        </thead>
                        {
                            pendingTableList.map((single_item, index) => {
                                return <tbody key={index}>
                                            <tr>
                                                <td className="single-cell">
                                                    <div>{single_item.title}</div>
                                                    <div>{single_item.criteria}</div>
                                                    <span style={{fontWeight: 'bold'}}>{single_item.status}</span>
                                                    &nbsp;.&nbsp;
                                                    <span>{single_item.Date}</span>
                                                </td>
                                                <td  className="single-cell">
                                                    <div style={{color: '#D48806', fontWeight: 'bold', marginBottom: '5px'}}>
                                                        <i className="fas fa-rupee-sign"></i>&nbsp;
                                                        {single_item.amount}
                                                    </div>
                                                    <div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {history.push({pathname: '/leads-page', search: '?' + 'Eligible Placement'})}}>{single_item.numberOfPlacement} Eligible Candidates</div>
                                                </td>
                                            </tr>
                                        </tbody>
                            })
                        }
                    </table>

                    <table  className="table-layout">
                        <thead>
                            <tr  className="table-layout">
                                <th className="table-heading">Payment Infomation</th>
                                <th className="table-heading"><span style={{fontWeight: 'bold', color:'black'}}>Paid Amount </span>and Candidate Details</th>
                            </tr>
                        </thead>
                        {
                            paidTableList.map((single_item, index) => {
                                return <tbody key={index}>
                                            <tr> 
                                                <td className="single-cell">
                                                    <div>{single_item.title}</div>
                                                    <div>{single_item.criteria}</div>
                                                    <span style={{fontWeight: 'bold'}}>{single_item.status}</span>
                                                    &nbsp;.&nbsp;
                                                    <span>{single_item.Date}</span>
                                                </td>
                                                <td  className="single-cell">
                                                    <div style={{color: '#52C41A', fontWeight: 'bold', marginBottom: '5px'}}>
                                                        <i className="fas fa-rupee-sign"></i>&nbsp;
                                                        {single_item.amount}
                                                    </div>
                                                    <div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {history.push('/leads-page')}}>{single_item.numberOfPlacement} Eligible Candidates</div>
                                                </td>
                                        </tr>
                                       </tbody>
                            })
                        }
                        </table>
                </div>
            </div>

            <div className="navigation-container">
				<NavigationTab currentTab={3} />
			</div>

        </div>
    )
}

export default EarningPage;
