// import React from 'react';
// import { useHistory } from 'react-router-dom';
// import trackingConstants from '../../constants/trackingConstants';

// import '../../css/navigation.css';
// import { logEvent } from '../../service/tracking';
// import useGAEventsTracker from '../../service/useGSEventsTracker';


// const NavigationTab = ({ currentTab, mitra }) => {
// 	const history = useHistory();
	// const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	// const AccountGAEventsTracker = useGAEventsTracker("Accounts")
	// const ReportGAEventsTracker = useGAEventsTracker("Reports")
	// const JobsGAEventsTracker = useGAEventsTracker("jobs")



// 	const onNav = (tab) => {
// 		if (tab === 0) {
// 			history.push('/');
// 			// logEvent('ClickHomeTab', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_BOT_NAV);
// 		}
// 		else if (tab === 1) {
// 			GAEventsTracker("OpenAllCandidatesTab")
// 			history.push('/leads');
// 			// logEvent('ClickCandidatesTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
// 		}
// 		else if (tab === 2) {
			// JobsGAEventsTracker("View Demand Hub")
// 			history.push('/jobDemands');
// 			// logEvent('ClickJobDemandsTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
// 		}
// 		else if (tab === 3) {
// 			ReportGAEventsTracker("ViewReportsMainPage")
// 			history.push('/report');
// 			// logEvent('ClickSearchTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
// 		}
// 		else {
// 			history.push('/account');
			// AccountGAEventsTracker("View Account Section")
// 			// logEvent('ClickAccountTab', trackingConstants.EVENT_GROUP_PROFILE, trackingConstants.EVENT_SCREEN_BOT_NAV);
// 		}
// 	};

// 	return (
// 		<div className="navigation-wrapper">
// 			<div id="home-footer-icon" className="navigation-menu"  onClick={(e) => onNav(0)}>
// 				<div className="nav-icon">
// 					<i className="fas fa-home" style={{color : currentTab === 0 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
// 					{/* {currentTab === 0 ? (
// 						<img alt="Home Icon Active" src="/image/icon_botnav_home_active.png" />
// 					) : (
// 						<img alt="Home Icon Inactive" src="/image/icon_botnav_home_inactive.png" />
// 					)} */}
// 				</div>
// 				<div className={"navigation-text " + (currentTab === 0 ? 'active-navigation-tab' : null)}>Home</div>
// 			</div>
// 			<div id="candidates-footer-icon" className="navigation-menu" onClick={(e) => onNav(1)}>
// 				<div className="nav-icon" style={{color : currentTab === 1 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}>
// 				<i className="fas fa-user"></i>
// 					{/* {currentTab === 1 ? (
// 						<img alt="Candidates Active" src="/image/icon_botnav_candidates_active.png" />
// 					) : (
// 						<img alt="Candidates Inactive" src="/image/icon_botnav_candidates_inactive.png" />
// 					)} */}
// 				</div>
// 				<div className={"navigation-text " + (currentTab === 1 ? 'active-navigation-tab' : null)}>Candidates</div>
// 			</div>
// 			<div id="demand-hub-footer-icon" className="navigation-menu" onClick={(e) => onNav(2)}>
// 				<div className="nav-icon">
// 				<i className="fas fa-store-alt" style={{color : currentTab === 2 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
// 					{/* {currentTab === 2 ? (
// 						<img alt="Demand Active" src="/image/icon_botnav_demand_active.png" />
// 					) : (
// 						<img alt="Demand Inactive" src="/image/icon_botnav_demand_inactive.png" />
// 					)} */}
// 				</div>
// 				<div className={"navigation-text " + (currentTab === 2 ? 'active-navigation-tab' : null)}>Demand Hub</div>
// 			</div>
// 			 <div id="search-footer-icon" className="navigation-menu" onClick={(e) => onNav(3)}>
// 				<div className="nav-icon">
// 				<i className="fas fa-chart-pie" style={{color : currentTab === 3 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
// 					{/* {currentTab === 3 ? (
// 						<img alt="Doc not uploaded" src="/image/icon_botnav_search_active.png" />
// 					) : (
// 						<img alt="Doc not uploaded" src="/image/icon_botnav_search_inactive.png" />
// 					)} */}
// 				</div>
// 				<div className={"navigation-text " + (currentTab === 3 ? 'active-navigation-tab' : null)}>Report</div>
// 			</div>
// 			<div id="account-footer-icon" className="navigation-menu" onClick={(e) => onNav(4)}>
// 				<div className="nav-icon">
// 				<i className="fas fa-cog"  style={{color : currentTab === 4 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
// 					{/* {currentTab === 4 ? (
// 						<img alt="Account Active" src="/image/icon_botnav_account_active.png" />
// 					) : (
// 						<img alt="Account Inactive" src="/image/icon_botnav_account_inactive.png" />
// 					)} */}
// 				</div>
// 				<div className={"navigation-text " + (currentTab === 4 ? 'active-navigation-tab' : null)}>Account</div>
// 			</div>
// 		</div>
// 	);
// };

// export default NavigationTab;







import React, { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import trackingConstants from '../../constants/trackingConstants';
import '../../css/navigation.css';
import {logEvent} from '../../service/tracking';
import useGAEventsTracker from '../../service/useGSEventsTracker';

import * as loginService from '../../service/loginService'
const NavigationTab = ({currentTab}) => {
	const history = useHistory();
	const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AccountGAEventsTracker = useGAEventsTracker("Accounts")
	const ReportGAEventsTracker = useGAEventsTracker("Reports")
	const JobsGAEventsTracker = useGAEventsTracker("jobs")

	const [mitra, setMitra] = useState({})

	useEffect(() => {
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
			})
			.catch((error) => {
				 history.push('/');
			});
	}, [])


	const onNav = (tab) => {
		if (tab === 0) {
			history.push('/');
			logEvent('ClickHomeTab', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 1) {
			GAEventsTracker("OpenAllCandidatesTab")
			history.push('/leads');
			logEvent('ClickCandidatesTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 2) {
			JobsGAEventsTracker("View Demand Hub")
			history.push('/jobDemands');
			logEvent('ClickJobDemandsTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 3) {
			ReportGAEventsTracker("ViewReportsMainPage")
			history.push('/report');
			// logEvent('ClickSearchTab', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_BOT_NAV);
		}
		else if (tab === 4) {
			history.push('/account');
			AccountGAEventsTracker("View Account Section")
			logEvent('ClickAccountTab', trackingConstants.EVENT_GROUP_PROFILE, trackingConstants.EVENT_SCREEN_BOT_NAV);
		} else {
			handleLogout();
		}
	};
	const handleLogout = (e) => {
		loginService
			.inValidateSession()
			.then((response) => {
				setMitra({});
				window.location.reload()
			})
			.catch((error) => {
				console.log('Error logging out: ', error);
			});
	};
	return (
		<>
			<div className="navigation-wrapper">
				<div id="home-footer-icon" className="navigation-menu" onClick={(e) => onNav(0)}>
					<div className="nav-icon">
						<i className="fas fa-home" style={{color : currentTab === 0 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</div>
					<div className={"navigation-text"}>Home</div>
				</div>
				<div id="candidates-footer-icon" className="navigation-menu" onClick={(e) => onNav(1)}>
					<div className="nav-icon">
						<i className="fas fa-user" style={{color : currentTab === 1 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</div>
					<div className={"navigation-text"}>Candidates</div>
				</div>
				<div id="demand-hub-footer-icon" className="navigation-menu" onClick={(e) => onNav(2)}>
					<div className="nav-icon">
						<i className="fas fa-store-alt" style={{color : currentTab === 2 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</div>
					<div className={"navigation-text"}>Jobs</div>
				</div>
				<div id="search-footer-icon" className="navigation-menu" onClick={(e) => onNav(3)}>
					<div className="nav-icon">
					<i className="fas fa-chart-pie" style={{color : currentTab === 3 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</div>
					<div className={"navigation-text"}>Report</div>
				</div>
				<div id="account-footer-icon" className="navigation-menu" onClick={(e) => onNav(4)}>
					<div className="nav-icon">
						<i className="fas fa-cog"  style={{color : currentTab === 4 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</div>
					<div className={"navigation-text"}>Account</div>
				</div>
			</div>
			<div className="nav-wrapper-desktop">
				<div id="logo-footer-icon" className="navigation-menu" >
					<span className="nav-icon">

						<img style={{width: '20%'}} alt="Home Icon Inactive" src="/image/mitra-logo.png" />
					</span>
					<span className={"navigation-text"}>
						&nbsp;
						&nbsp;
						Vahan Mitra
					</span>
				</div>
				<hr />

				<div id="home-footer-icon" className="navigation-menu" onClick={(e) => onNav(0)}>
					<span className="nav-icon">
						<i className="fas fa-home fa-lg" style={{color : currentTab === 0 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</span>
					<span className={"navigation-text"}>
						&nbsp;&nbsp;&nbsp;
						Home
					</span>
				</div>
				<div id="candidates-footer-icon" className="navigation-menu" onClick={(e) => onNav(1)}>
					<span className="nav-icon">
						<i className="fas fa-user fa-lg" style={{color : currentTab === 1 ?  '#D34612' : "rgba(0, 0, 0, 0.25)", marginLeft: "2px"}}></i>
					</span>
					<span className={"navigation-text"}>
						&nbsp;
						&nbsp;
						Candidates
					</span>
				</div>
				<div id="demand-hub-footer-icon" className="navigation-menu" onClick={(e) => onNav(2)}>
					<span className="nav-icon">
						<i className="fas fa-store-alt fa-lg" style={{color : currentTab === 2 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</span>
					<span className={"navigation-text"}>
						&nbsp;&nbsp;
						Jobs
					</span>
				</div>
				<div id="account-footer-icon" className="navigation-menu" onClick={(e) => onNav(3)}>
					<span className="nav-icon">
						<i className="fas fa-chart-pie fa-lg" style={{color : currentTab === 3 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</span>
					<span className={"navigation-text"}>
						&nbsp;&nbsp;&nbsp;
						Report
					</span>
				</div>
				<div id="account-footer-icon" className="navigation-menu" onClick={(e) => onNav(4)}>
					<span className="nav-icon">
						<i className="fas fa-cog fa-lg"  style={{color : currentTab === 4 ?  '#D34612' : "rgba(0, 0, 0, 0.25)"}}></i>
					</span>
					<span className={"navigation-text"}>
						&nbsp;&nbsp;&nbsp;
						Account
					</span>
				</div>
				<div id="logout-footer-icon" className="navigation-menu" onClick={(e) => onNav(5)}>
					<span className="nav-icon">
						<img style={{width: '15px'}} alt="Account Active" src="/image/logout.png" />
					</span>
					<span className={"navigation-text"}>
						&nbsp;
						Logout
					</span>
				</div>
			</div>
		</>
	);
};

export default NavigationTab;