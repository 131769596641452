import axios from 'axios';
import moment from 'moment';


export const checkActionTaken = async (lead, button) => {
    lead.jobs.map(single_job => {
      if(single_job.displayInUI && single_job.experimentId !== null){
		    let url = process.env.REACT_APP_BACKEND_URL + '/atRiskAction';
        const data = {experimentId: single_job.experimentId, metaData: {actionTaken: button, actionTimestamp: moment().format()}}
        return axios.post(url, data, {withCredentials: true})
      }
    })
  }