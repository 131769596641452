import React, { useState, useEffect } from 'react';
import NavigationTab from '../common/NavigationTab.component';
import '../../css/report.css'
import List from './MonthPicker.component'
import { PieChart } from 'react-minimal-pie-chart';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as loginService from './../../service/loginService';
import axios from 'axios';
import { getDashboardData } from './../../service/reportService'
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import ClipLoader from 'react-spinners/ClipLoader';
import useGAEventsTracker from '../../service/useGSEventsTracker';





const ReportPage = () => {
    const history = useHistory();
    const [selectedMonth, setMonth] = useState({ month: moment().month()+1, year: moment().year() })
    const [selected, setSelected] = useState(0);
    const [hovered, setHovered] = useState(undefined);
    const [tooltipData, setTooltipData] = useState(undefined)
    const [mitra, setMitra] = useState({});
    const [isAdmin, setIsAdmin] = useState(false)
    const [teamList, setTeamList] = useState([]);
    const [showMemberFilter, setShowMemberFilter] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState(0);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [showMonthChip, setShowMonthChip] = useState(false)

    // const list = [
    //     // {title: 'Paid Amount', color: '#52C41A', value: 28, text: 'Total money you received', amount: 12893},
    //     { title: 'Total Earning', color: '#FF712D', text: 'Total money you earned', amount: 112893 },
    //     { title: 'Eligible Placement', color: '#8C8C8C', value: '', text: 'Total successful candidtes', amount: 68 },
    // ]

    const [candidateStatus, setCandidateStatus] = useState([]);
	const [loaded, setLoaded] = useState(false);
    const ReportGAEventsTracker = useGAEventsTracker("Reports")


    const chartLabel = [
        { title: '10 Trips', color: '#52C41A' },
        { title: '1 Trip', color: '#FF712D' },
        { title: 'Activated', color: '#FAAD14' },
        { title: 'Unique', color: '#237804' },
        { title: 'Non Unique', color: '#FF4D4F' },
        { title: 'Application Created', color: '#8C8C8C' }
    ]

    const routing = (title) => {
        if (title === "Eligible Placement") {
            history.push({
                pathname: '/leads-page',
                search: '?' + title
            })
        }
        else {
            history.push('/earnings')
        }
    }


    useEffect(() => {
        let m = {}, list = [], admin = false;

        async function asyncCall() {
            await loginService
                .validateSession()
                .then((response) => {
                    m = response.data.mitra
                    setMitra(response.data.mitra);
                    if (response.data.mitra.role && response.data.mitra.role.toLowerCase() === 'admin') {
                        setIsAdmin(true);
                        admin = true
                    }
                })
                .catch((error) => history.push('/'));
            await axios.get(process.env.REACT_APP_BACKEND_URL + '/getMitraByManagerId', { withCredentials: true }).then(response => {
                list.push({ name: 'Entire Team', id: m.id })
                list.push(m)
                for (let user in response.data.data) {
                    list.push(response.data.data[user]);
                }
                setTeamList(list);
            })

            let data = {}
            if (admin) {
                data = {
                    managerMitraID: m.id,
                    mitraId: 'null',
                    month: selectedMonth.month,
                    year: selectedMonth.year,
                }
            }
            else {
                data = {
                    managerMitraID: 'null',
                    mitraId: m.id,
                    month: selectedMonth.month,
                    year: selectedMonth.year,
                }
            }
            getDashboardData(data)
                .then(response => {
                    const candidatesData = [
                        { title: 'Completed 10th Trip', APIData: 'completed10thTrip', color: '#52C41A', value: response.data.data.completed10thTripCandidatesCount === null ? 0 : Number(response.data.data.completed10thTripCandidatesCount) },
                        { title: 'Completed 1st Trip',APIData: 'completed1stTrip', color: '#FF712D', value: response.data.data.completed1stTripCandidatesCount === null ? 0 : Number(response.data.data.completed1stTripCandidatesCount) },
                        { title: 'Activated',APIData: 'activated', color: '#FAAD14', value: response.data.data.activatedCandidatesCount === null ? 0 : Number(response.data.data.activatedCandidatesCount) },
                        { title: 'Unique', APIData: 'unique', color: '#389E0D', value: response.data.data.uniqueCandidatesCount === null ? 0 : Number(response.data.data.uniqueCandidatesCount) },
                        { title: 'Non Unique', APIData: 'nonUnique', color: '#FF4D4F', value: response.data.data.notUniqueCandidatesCount === null ? 0 : Number(response.data.data.notUniqueCandidatesCount) },
                        { title: 'Application Created', APIData:'applicationCreated' , color: '#8C8C8C', value: response.data.data.applicationCreatedCandidatesCount === null ? 0 : Number(response.data.data.applicationCreatedCandidatesCount) }
                    ]
                    setCandidateStatus(candidatesData)
                    setTotalCandidates(response.data.data.totalCandidatesCount)
				    setLoaded(true)
                })
        }
        asyncCall()
    }, [])

    const onChangeHandler = (month, member) => {
        let managerId = 'null', mitraId = 'null';
            if(member === undefined){
                if (selectedTeamMember === 0) {
                    managerId = mitra.id
                }
                else {
                    mitraId = teamList[selectedTeamMember].id;
                }
            }
            else{
                if (member == 0) {
                    managerId = mitra.id
                }
                else {
                    mitraId = teamList[member].id;
                }
            }

        const data = {
            managerMitraID: managerId,
            mitraId,
            month: month.month,
            year: month.year,
        }
		setLoaded(false)
        getDashboardData(data)
                .then(response => {
                    const candidatesData = [...candidateStatus];
                    candidatesData[0].value = response.data.data.completed10thTripCandidatesCount === null ? 0 : Number(response.data.data.completed10thTripCandidatesCount)
                    candidatesData[1].value = response.data.data.completed1stTripCandidatesCount === null ? 0 : Number(response.data.data.completed1stTripCandidatesCount)
                    candidatesData[2].value = response.data.data.activatedCandidatesCount === null ? 0 : Number(response.data.data.activatedCandidatesCount)
                    candidatesData[3].value = response.data.data.uniqueCandidatesCount === null ? 0 : Number(response.data.data.uniqueCandidatesCount)
                    candidatesData[4].value = response.data.data.notUniqueCandidatesCount === null ? 0 : Number(response.data.data.notUniqueCandidatesCount)
                    candidatesData[5].value = response.data.data.applicationCreatedCandidatesCount === null ? 0 : Number(response.data.data.applicationCreatedCandidatesCount)
                    setCandidateStatus(candidatesData)
                    setTotalCandidates(response.data.data.totalCandidatesCount)
				    setLoaded(true)
                })
    }

    const pieChartData = candidateStatus.map((entry, i) => {
        if (hovered === i) {
            return {
                ...entry,
                color: '#71A5D6',
            };
        }
        return entry;
    });

    const getPercent = (index) => {
        let sum = 0;
        candidateStatus.map(item => {
            sum += Number(item.value);
        })
        const percent = (candidateStatus[index].value * 100) / sum;
        return Math.round(percent * 10) / 10;
    }

    const handleSheet = async () => {
        const columns = [
            { header: 'Title', key: 'title' },
            { header: 'Value', key: 'value' }
        ];

        let data = [];
        data.push({title: "Month", value: moment.months(selectedMonth.month - 1) + " " + selectedMonth.year})
        data.push ({title: '', value: ''})
        data.push ({title: 'Overall Perfromance', value: ''})
        data.push ({title: '', value: ''})

        candidateStatus.map(single_status => {
            data.push({title: single_status.title, value: single_status.value})
          })

          const workSheetName = 'Worksheet-1';
            const workBookName = 'MyWorkBook';
            const myInputId = 'myInput';
            const fileName = 'Report'
          const workbook = new Excel.Workbook();

        try {
            const worksheet = workbook.addWorksheet(workSheetName);
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            data.forEach(singleData => {
                worksheet.addRow(singleData);
              });

            worksheet.getRow(4).font = { bold: true };

              // loop through all of the columns and set the alignment with width.
            worksheet.columns.forEach(column => {
                column.width = column.header.length + 5;
                column.alignment = { horizontal: 'center' };
            });

              worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;
        
                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                  // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                  const cellAddress = singleCell._address;
        
                  // apply border
                  worksheet.getCell(cellAddress).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                  };
                });
              });
              // write the content using writeBuffer
                const buf = await workbook.xlsx.writeBuffer();

                // download the processed file
                saveAs(new Blob([buf]), `${fileName}.xlsx`);
                } catch (error) {
                console.error('<<<ERRROR>>>', error);
                console.error('Something Went Wrong', error.message);
                } finally {
                // removing worksheet's instance to create new one
                workbook.removeWorksheet(workSheetName);
                }
            }

    const deleteChip = (type, value) => {
        if(type === 'teamMember') {
            setSelectedTeamMember(0);
            onChangeHandler(selectedMonth, 0)
            document.getElementById("team-filter").value = 0
        }
        if(type === 'month') {
            const currentMonth = {month: moment().month(), year: moment().year()}
            setMonth(currentMonth)
            setShowMonthChip(false);
            document.location.reload()
        }
    }

    const getMonth = (month) => {
        const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        let returnValue = monthList[month.month - 1] + ' ' + month.year
        return returnValue;
    }


    return (
        <div>
            <div className="mainContentZ">
                <div className="header">
                    <div className="header-text">
                        Report
                    </div>
                    {isAdmin ?
                        <div style={{ textAlign: 'right', width: '50%', marginLeft: 'auto' }}>
                            <div className="header-download">
                                <button className="download-button" onClick = {() => {
                                    handleSheet()
                                    ReportGAEventsTracker("DownloadReportsData");
                                }}>
                                    <i className="fas fa-download"></i>&nbsp;
                                    Export Sheets
                                </button>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="more-filters">
                    <div className="filter-set-container">
                        <div id="category-filter" className=" Dropdown-control">
                            <List setMonth={setMonth} getData={onChangeHandler} />&nbsp;
                            {/* <i style={{ lineHeight: 'inherit' }} className="fas fa-chevron-down"></i> */}
                        </div>
                        {isAdmin ?
                            <select id="team-filter" className="Dropdown-control filter-label" style={{ width: '150px' }}
                                onChange={() => {
                                    setSelectedTeamMember(document.getElementById("team-filter").value);
                                    onChangeHandler(selectedMonth, document.getElementById("team-filter").value)
                                    ReportGAEventsTracker("ClickOnReportTeamFilter")
                                }}>
                                {
                                    teamList ? teamList.map((single_user, index) =>
                                        <option key={index} className="filter-label" value={index}>{single_user.name}</option>
                                    )
                                        : null
                                }
                            </select>
                            : null}

                    </div>
                </div>

                <div className="Chips-container" style={{padding: "0 5%"}}>
					{
                        showMonthChip ?
								<div className="single-chip">
									<span className="chip-text">{getMonth(selectedMonth)}</span>
									<span className="chip-cross" onClick={() => {deleteChip('month')}}> <i className="fas fa-times"></i> </span>
								</div>
                        : null
					}

					{
                        selectedTeamMember !== 0 ?
                                    <div className="single-chip">
                                        <span className="chip-text">{teamList[selectedTeamMember].name}</span>
                                        <span className="chip-cross" onClick={() => {deleteChip('teamMember')}}> <i className="fas fa-times"></i> </span>
                                    </div>
                        : null
					}
			    </div>

                <div className="main-container">
                    {/* {isAdmin ?
                        <div className="visible">
                            <div className="total-earnings">
                                <div className="total-earning-header">
                                    <div className="total-earning-header-text">
                                        <i className="fas fa-rupee-sign" style={{ position: 'relative', color: '#D34612' }}></i>&nbsp;
                                        Total Earnings
                                    </div>
                                    <div className="total-earning-header-date">
                                        Till 14th Oct
                                    </div>
                                </div>
                                <div className="horizontal-bar-container">
                                    <div className="horizontal-bar">
                                        <progress id="file" value="60" max="100" className="progressBar"> </progress>
                                    </div>
                                    <div className="horizontal-bar-text">
                                        <div className="paid-amount-container">
                                            <div>
                                                <i className="fas fa-circle" style={{ color: '#52C41A' }}></i>&nbsp;
                                                Paid Amount
                                            </div>
                                            <div className="percentage">60%</div>
                                        </div>
                                        <div className="unpaid-amount-container">
                                            <div className="right-box">
                                                <div>
                                                    <i className="fas fa-circle" style={{ color: '#D48806' }}></i>&nbsp;
                                                    Eligible Payout
                                                </div>
                                                <div className="percentage">40%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cards-container">
                                    {
                                        list.map((single_item, index) => {
                                            return <div key={index} className="single-card" style={{ backgroundColor: single_item.color, cursor: 'pointer' }} onClick={() => routing(single_item.title)}>
                                                <div className="card-left">
                                                    <div className="card-left-top">{single_item.title}</div>
                                                    <div className="card-left-bottom">{single_item.text}</div>
                                                </div>
                                                <div className="card-right">
                                                    <div className="card-right-bottom">
                                                        {single_item.title !== 'Eligible Placement' ? <i className="fas fa-rupee-sign" style={{ position: 'relative' }}></i> : null}
                                                        {single_item.amount}
                                                    </div>
                                                </div>
                                                <div className="report-right-arrow">
                                                    <i className="fas fa-chevron-circle-right" style={{ color: single_item.color, border: '1px solid white', background: 'white', borderRadius: '7px' }}></i>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ height: '5px' }}></div>
                        </div>
                        : null} */}

                        {
                            loaded ? 
                                <div className="visible">
                                    <div className="overall-performance">
                                        <div className="overal-performance-heading">
                                            <i className="fas fa-chart-line"></i>&nbsp;
                                            Overall Performance
                                        </div>
                                        <div className="chart-container">
                                            <div className="chart">
                                                <div className="tool-tip">
                                                    <PieChart
                                                        data={pieChartData}
                                                        lineWidth={40}
                                                        lengthAngle={-360} animate
                                                        radius={PieChart.defaultProps.radius - 6}
                                                        segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                                                        onClick={(event, index) => {
                                                            setSelected(index === selected ? undefined : index);
                                                        }}
                                                        onMouseOver={(_, index) => {
                                                            setHovered(index);
                                                            setTooltipData(getPercent(index) + "% " + candidateStatus[index].title)
                                                        }}
                                                        onMouseOut={() => {
                                                            setHovered(undefined);
                                                            setTooltipData(undefined)
                                                        }}
                                                    />
                                                    <span className="tooltiptext" style={{ visibility: tooltipData !== undefined && hovered !== undefined ? 'visible' : 'hidden', left: '30%', width: '230px' }}>
                                                        {tooltipData !== undefined && hovered !== undefined ? tooltipData : null}
                                                    </span>
                                                    <div class="total-candidates">Total candidates: {totalCandidates}</div>
                                                </div>
                
                                            </div>
                                            <div className="chart-details">
                                                {
                                                    chartLabel.map((single_item, index) => {
                                                        return <div key={index} className="single-label-container">
                                                            <div className="label-dot">
                                                                <i className="fas fa-circle" style={{ color: single_item.color }}></i>&nbsp;
                                                            </div>
                                                            <div className="label-text">{single_item.title}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="cards-container">
                                            {
                                                candidateStatus.map((single_item, index) => {
                                                    return <div key={index} className="single-card" style={{ backgroundColor: single_item.color, cursor: 'pointer' }} onClick={() => {
                                                        history.push({
                                                            pathname: '/leads-page',
                                                            search: '?' + single_item.title
                                                        })
                                                        ReportGAEventsTracker("ViewReportsFunnel: " + single_item.title);
                                                        localStorage.setItem("data", JSON.stringify({
                                                            managerMitraID: selectedTeamMember == 0 ? teamList[selectedTeamMember].id : 'null',
                                                            mitraId: selectedTeamMember == 0 ? 'null' : teamList[selectedTeamMember].id,
                                                            month: selectedMonth.month,
                                                            year: selectedMonth.year,
                                                            status: single_item.APIData
                                                        }))
                                                    }}>
                                                        <div className="card-left-overall-performance">
                                                            <div className="card-left-top">{single_item.title}</div>
                                                        </div>
                                                        <div className="card-right">
                                                            <div className="card-right-top">{single_item.value} 
                                                                {
                                                                    single_item.value == 1 || single_item.value == 0 ? 
                                                                        ' Candidate'
                                                                    : ' Candidates'
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="report-right-arrow">
                                                            <i className="fas fa-chevron-circle-right" style={{ color: single_item.color, border: '1px solid white', background: 'white', borderRadius: '7px', margin: '4px 0px' }}></i>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="space-needed"></div>
                                </div>
                            : 
                                <div className="loader_clip">
                                    <ClipLoader size={100} />
                                </div>
                        }

                    
                </div>
            </div>
            <div className="navigation-container">
                <NavigationTab currentTab={3} />
            </div>
        </div>
    )
}

export default ReportPage
