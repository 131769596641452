import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {logEvent} from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
import '../../css/lead.css';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import moment from 'moment';
import {AdditionalFormsURLEnum} from '../../utils/utility';
import {useHistory} from 'react-router-dom';
import useMoengage from '../../hooks/useMoengage';
import {checkActionTaken} from './../../service/leadService'


const overlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.4)'
}

const getInnewWidth = () => {
  return window.innerWidth < 370 ? "340px" : window.innerWidth < 400 ? "350px" : window.innerWidth < 500 ? '400px' : "500px"
}

const contentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  height: '460px',
  width: getInnewWidth(),
  border: '1px solid #ccc',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  borderRadius: '4px',
  outline: 'none',
  padding: '0px'
}


const CandidateProfilePopup = ({visible, setShowDetail, lead, statuses}) => {
  const history = useHistory();
  const [createMoengageUser, trackEvent] = useMoengage();
  // let candidateUniquenessCheck = ''
  // let activated = ''
  // let firstTrip = ''
  // let activatedDate = 'NA'
  // let firstTripDate = 'NA'
  // let tenthTripDate = 'NA'
  // let incentiveDate = 'NA'
  // let uniqueText = 'Pending for Referral'

  const graphItems = [
    // "Candidate Uniqueness Check", "Activated", "1st Trip"
    // {title: "Client Name", text: " "},
    // {title: "Application Date", text: `Created on . `},
    // {title: "Candidate Uniqueness Check", text: ""},
    // {title: "Activated", text: "Activated On "},
    // {title: "1st Trip", text: "Completed On "}
  ]


  // const setData = (lead) => {
  //   if(lead.jobs && lead.jobs[0].status){
  //     lead.jobs[0].status.map(single_status => {
  //       if(single_status.title && single_status.title.toLowerCase() === 'created' && lead.companyName && (lead.companyName.toLowerCase() === 'shadowfax' || lead.companyName.toLowerCase() === 'shadowfax_3pl')){
  //         candidateUniquenessCheck = true
  //         uniqueText = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
  //       }
  //       if(single_status.title && single_status.title.toLowerCase() === 'uploaded' && lead.companyName.toLowerCase() === 'zomato'){
  //         candidateUniquenessCheck = true
  //         uniqueText = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")

  //       }
  //       if(single_status.title && single_status.title === ''){
  //         candidateUniquenessCheck = true
  //         uniqueText = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")

  //       }
  //       if(single_status.title && single_status.title.toLowerCase() === 'not uploaded'){
  //         candidateUniquenessCheck = true
  //         uniqueText = 'Not Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")

  //       }
  //       if(single_status.title && single_status.title.toLowerCase() === 'activated'){
  //         activated = true
  //         activatedDate = moment(single_status.StatusTimeStamp).format("DD MMM")
  //       }
  //       if(single_status.title &&  single_status.title.toLowerCase() === 'work started'){
  //         firstTrip = true
  //         firstTripDate = moment(single_status.StatusTimeStamp).format("DD MMM")
  //       }
  //       // if(single_status.title === '10th_order_date'){
  //       //   tenthTrip = true
  //       //   tenthTripDate = moment(single_status.StatusTimeStamp).format("DD MMM")
  //       // }
  //     })
  //   }
  // }

  const setData = (single_job, item) => {
    if (item.title === "Application Date") {
      return `Created on . ${moment(single_job.createdAt).format("DD MMM")}`;
    }

    if (item.title === "Client Name") {
      return single_job ? single_job.companyName + " . " + moment(single_job.createdAt).format("DD MMM") : null;
    }

    if (item.title === "Activated") {
      let data = ''

      single_job.status.map(single_status => {
        if (single_status.title && (single_status.title.toLowerCase() === 'activated' || single_status.title.toLowerCase() === 'activation_date')) {
          data = `Activated at . ${moment(single_status.StatusTimeStamp).format("DD MMM")}`
        }
      })
      if (data === '') {
        return `Not Activated Yet`
      }
      else return data;
    }

    if (item.title === "1st Trip") {
      let data = ''
      single_job.status.map(single_status => {
        if (single_status.title && (single_status.title.toLowerCase() === 'work started' || single_status.title.toLowerCase() === 'first_date_of_work')) {
          data = `Completed On . ${moment(single_status.StatusTimeStamp).format("DD MMM")}`
        }
      })
      if (data === '') {
        return `Not Started Yet`
      }
      else return data;
    }

    if (item.title === "Candidate Uniqueness Check") {
      let data = ''
      single_job.status.map(single_status => {
        if (single_status.title && single_status.title.toLowerCase() === 'created' && single_job.companyName && (single_job.companyName.toLowerCase() === 'shadowfax' || single_job.companyName.toLowerCase() === 'shadowfax_3pl')) {
          // candidateUniquenessCheck = true
          // uniqueText = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
          data = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
        }
        else if (single_status.title && single_status.title.toLowerCase() === 'uploaded' && single_job.companyName.toLowerCase() === 'zomato') {
          // candidateUniquenessCheck = true
          // uniqueText = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
          data = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
        }
        // else if (single_status.title && single_status.title === '') {
        //   // candidateUniquenessCheck = true
        //   // uniqueText = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
        //   data = 'Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
        // }
        else if (single_status.title && single_status.title.toLowerCase() === 'not uploaded') {
          // candidateUniquenessCheck = true
          // uniqueText = 'Not Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
          data = 'Not Unique. ' + moment(single_status.StatusTimeStamp).format("DD MMM")
        }
      })
      if (data === '') {
        return 'Pending for Referral'
      }
      else return data
    }



  }



  const show = (item, single_job) => {
    if (item.title && (item.title === "Application Date" || item.title === "Client Name")) {
      return true;
    }
    if (item.title && item.title === "Candidate Uniqueness Check" && single_job) {
      let data = false
      single_job.status.map(single_status => {
        if (single_status.title && single_status.title.toLowerCase() === 'created' && single_job.companyName && (single_job.companyName.toLowerCase() === 'shadowfax' || single_job.companyName.toLowerCase() === 'shadowfax_3pl')) {
          data = true
        }
        else if (single_status.title && single_status.title.toLowerCase() === 'uploaded' && single_job.companyName.toLowerCase() === 'zomato') {
          data = true
        }
        else if (single_status.title && single_status.title === '') {
          data = true
        }
        else if (single_status.title && single_status.title.toLowerCase() === 'not uploaded') {
          data = true
        }
      })
      return data
    }
    if (item.title && item.title === 'Activated') {
      let data = false

      single_job.status.map(single_status => {
        if (single_status.title && (single_status.title.toLowerCase() === 'activated' || single_status.title.toLowerCase() === 'activation_date')) {
          data = true
        }
      })
      return data;
    }

    if (item.title && item.title === '1st Trip') {
      let data = false
      single_job.status.map(single_status => {
        if (single_status.title && (single_status.title.toLowerCase() === 'work started' || single_status.title.toLowerCase() === 'first_date_of_work')) {
          data = true
        }
      })
      return data;
    }
    return false
  };


  const completionCheck = (item, single_job) => {
    for (let status of item.status) {
      const exist = single_job.status.find(ele => {
        return ele.title && ele.title.toLowerCase() == status.name.toLowerCase()
      })
      if (exist) {
        return status.tag + (exist.StatusTimeStamp ? '.' + moment(exist.StatusTimeStamp).format("DD MMM")
          : '')
      }
    }

  }

  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

  const checkAtRisk = (lead) => {
    let data = false
    if (lead && lead.jobs) {
      lead.jobs.map(single_job => {
        const DateObj = new Date();
        const differnceInDates = (DateObj.getTime() - Date.parse(single_job.status[0].StatusTimeStamp)) / (1000 * 3600 * 24);
        if (differnceInDates >= 7 && differnceInDates <= 14 && single_job && single_job.displayInUI && single_job.status && single_job.status[0].title.toLowerCase() === "activated"  && single_job.companyName && (single_job.companyName.toLowerCase() === "shadowfax" || single_job.companyName.toLowerCase() === "shadowfax_3pl")) {
          data =  true
        }
      })
    }
    return data
  }
  const checkClientStatus = (name) => {
    const status = statuses.find(ele => {return ele.title.includes(name.toLowerCase())});
    if (status) return status
    else return false
  }
  const viewAdditionalForm = (lead,event,obj) => {
    trackEvent(event, obj);
    localStorage.setItem('lead', JSON.stringify(lead))
    if (lead.scheduledInterviewId) {
      history.push('/moreInfo')

    } else {
      history.push({
        pathname: '/moreInfo',
        route: 'jobRecommendationPage'
      })
    }
  }


  return (

    <Modal
      isOpen={visible && lead}
      onRequestClose={(e) => setShowDetail(false)}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: overlayStyle,
        content: contentStyle
      }}
      scrollable={true}
    >
      <div className="details-component-container">
        <div style={{padding: '14px', paddingBottom: '8px'}}>
          <div className="Contact-details-header">
            <div className="Candidate-details">
              <div className="head-details">
                <div className="user-image" >{lead ? lead.name ? lead.name.charAt(0).toUpperCase() : null : null}
                  {
                    lead ? lead.jobs ? checkAtRisk(lead) ? <div className="counter counter-lg"><i className="fas fa-exclamation" style={{paddingLeft: '2px'}}></i></div> : null : null : null
                  }
                </div>
                <div className="name-number-container">
                  <div className="lead-name">{lead.name}</div>
                  <div className="lead-number">{lead.phoneNumber}</div>
                </div>
              </div>
            </div>
            <div className="close-popup" onClick={() => setShowDetail(false)}>
              <i className="far fa-times-circle"></i>
            </div>
          </div>

        <div className="contact-buttons">

          <a
            href={`tel:+91-${lead.phoneNumber}`}
            className="call-now"
            style={{width: '40%', padding: '2px'}}
            onClick={(e) => {GAEventsTracker("CallCandidate");
            checkActionTaken(lead, "Call now").then(response => console.log(response))
            logEvent('CallCandidate', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE)}}
          >
            <button style={{color: '#3a3939'}}>
              <i className="fas fa-phone"></i>
              &nbsp;Call Now
            </button>
          </a>

          &nbsp;

          <a
            rel="noopener noreferrer"
            style={{width: '40%', height: '100%', padding: '2px',textDecoration: "none"}}
            href={`https://api.whatsapp.com/send?phone=+91${lead.phoneNumber}&text=Dear%20${lead.name},%20for%20your%20${lead.jobs ? lead.jobs[0].companyName : null}%20delivery%20job%20application%20created%20by%20Vahan%20Mitra%20Leader:%20${lead.jobs ? lead.jobs[0].mname : null},%20you%20can%20track%20realtime%20status%20updates%20by%20clicking%20here:%20https://mitra.vahan.co/status?userId=${lead.userId}`}
            onClick={(e) => {GAEventsTracker("MessageCandidate");
              checkActionTaken(lead, "Message").then(response => console.log(response))
             logEvent('WhatsAppCandidate', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE)}}
            target="_blank"
            className="message-button"
          >
            <button style={{color: 'white'}}>
              <i className="fab fa-whatsapp"></i>
              &nbsp;Message
            </button>
          </a>

          </div>

        </div>

        <div style={{height: '2px', backgroundColor: '#D9D9D9'}}></div>
        <div style={{overflowY: 'scroll', height: '336px'}}>
          {lead && lead.jobs && lead.jobs.map((single_job, index) =>
            <div className="flow-diagram" key={index}>
              {
                ["Client Name", "Application Date"].map((item, index) =>
                  <div key={index} className="single-path" >
                    <div className="symbol" >
                      <div className="circle-symbol">
                        <i className="far fa-check-circle" style={{color: show({title: item}, single_job) ? "#D34612" : "rgba(0, 0, 0, 0.45)"}}></i>
                      </div>

                      <div className="pipe-symbol" style={{color: show({title: item}, single_job) ? "#D34612" : "rgba(0, 0, 0, 0.45)"}}>|</div>

                    </div>
                    <div className="single-path-data">
                      <div className="path-title" style={{color: show({title: item}, single_job) ? "black" : "rgba(0, 0, 0, 0.45)", fontWeight: show({title: item}, single_job) ? "bold" : "none"}}>{item}</div>
                      <div className="path-text">{setData(single_job, {title: item})}
                      </div>
                    </div>
                    {
                      (item === "Client Name") ?
                        <div style={{width: '37%', textAlign: 'right'}} >
                          <img style={{width: '96px'}} src={"/image/" + single_job.companyName + ".png"}></img>
                        </div> : null
                    }
                    {
                      (item === "Application Date") && AdditionalFormsURLEnum[single_job.companyName] ?
                        single_job.scheduledInterviewId ? <div className="popupButton">
                          <button onClick={() => {viewAdditionalForm(single_job)}} style={{color: '#D34612'}}> <i className="fas fa-upload" aria-hidden="true"></i> Edit/View Application</button>
                        </div> : <div className="popupButton">

                          <button onClick={() => {viewAdditionalForm(single_job)}} style={{color: '#D34612'}}> <i className="fas fa-upload" aria-hidden="true"></i> Complete Application</button>
                        </div> : null
                    }
                  </div>
                )
              }









              {(checkClientStatus(single_job.companyName) ? checkClientStatus(single_job.companyName).name : graphItems).map((item, index) =>
                <div key={index} className="single-path" >
                  <div className="symbol" >
                    <div className="circle-symbol">
                      <i className="far fa-check-circle" style={{color: completionCheck(item, single_job) ? "#D34612" : "rgba(0, 0, 0, 0.45)"}}></i>
                    </div>
                    {/* <div className="pipe-symbol" style={{color: show({title: item}, single_job) ? "#D34612" : "rgba(0, 0, 0, 0.45)"}}>|</div> */}
                    {
                      ((checkClientStatus(single_job.companyName) ? checkClientStatus(single_job.companyName).name : graphItems).length !== index + 1) ? <div className="pipe-symbol" style={{color: completionCheck(item, single_job) ? "#D34612" : "rgba(0, 0, 0, 0.45)"}}>|</div> : null
                    }
                  </div>
                  <div className="single-path-data">
                    <div className="path-title" style={{color: completionCheck(item, single_job) ? "black" : "rgba(0, 0, 0, 0.45)", fontWeight: completionCheck(item, single_job) ? "bold" : "none"}}>{item.tag}</div>
                    <div className="path-text">{completionCheck(item, single_job)}
                    </div>
                  </div>
                  {/* {
                    (item.title === "Client Name") ?
                      <div style={{width: '37%', textAlign: 'right'}} >
                        <img style={{width: '96px'}} src={"/image/" + single_job.companyName + ".png"}></img>
                      </div> : null
                  }
                  {
                    (item.title === "Application Date") && AdditionalFormsURLEnum[single_job.companyName] ?
                      single_job.scheduledInterviewId ? <div className="popupButton">
                        <button onClick={()=>{viewAdditionalForm(single_job , 'view_application_clicked_on_application_status_page',{candidate_phone_number : lead.phoneNumber} ); }} style={{color: '#D34612'}}> <i className="fas fa-upload" aria-hidden="true"></i> Edit/View Application</button>
                      </div> : <div className="popupButton">

                        <button onClick={()=>{viewAdditionalForm(single_job , 'complete_application_clicked_on_application_status_page',{candidate_phone_number : lead.phoneNumber } );    }} style={{color: '#D34612'}}> <i className="fas fa-upload" aria-hidden="true"></i> Complete Application</button>
                      </div> : null
                  } */}
                </div>

              )
              }
              {
                index !== lead.jobs.length - 1 ? <hr></hr> : <br></br>
              }
            </div>
          )}
        </div>
      </div>

    </Modal>
  )
}
export default CandidateProfilePopup;