import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import {uploadBulkUniquenessList, getpopularCities, bulkIVRUpload, getAudioFile} from './../../service/bulkActionService'
import * as loginService from '../../service/loginService';
import '../../css/bulkAction.css'
import ClipLoader from 'react-spinners/ClipLoader';
import useGAEventsTracker from './../../service/useGSEventsTracker';




const BulkAction = ({setParentHeader}) => {

    const [bulkActionType, setBulkActionType] = useState('')
    const [header, setHeader] = useState('Bulk Actions')
    const [clientSelected, setClientSelcted] = useState('');
    const [mitra, setMitra] = useState({})
    const [fileUploaded, setFilUploaded] = useState([])
    const [loading, setLoading] = useState(false);
    const [clientError, setClientError] = useState(false)
    const [cityError, setCityError] = useState(false)
    const [fileError, setFileError] = useState(false);
    const [languageError, setLanguageError] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(false)
    const [uploadedLeadsData, setUploadedLeadsData] = useState('')
    const [cities, setCities] = useState([]);
    const [citySelected, setCitySelected] = useState('')
    const DEFAULT = 'default'
    const history = useHistory();
    const [langSelected, setLangSelected] = useState('null');
    const [audioURL, setAudioURL] = useState('');
    const [playing, setPlaying] = useState(false);
    const [uniquenessError, setUniquenessError] = useState('')
    const [IVRError, setIVRError] = useState('')

    const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")

    useEffect(() => {
        loginService
            .validateSession()
            .then((response) => {
                setMitra(response.data.mitra);
            })
            .catch((error) => history.push('/'));

        getpopularCities().then((response) => {
            setCities(response.data.premiumCities)
        })

    }, [])

    const handleClientChange = (e) => {
        setClientError(false)
        setClientSelcted(e.target.value)
    }

    const handleFileUpload = (e) => {
        setFileError(false)
        setFilUploaded(e.target.files[0]);
    }

    const handleCityChange = (e) => {
        setCitySelected(e.target.value)
    }
    const handleLanguageChnage = (e) => {
        setLangSelected(e.target.value);
        if (e.target.value != '') {
            getAudioFile({type: 'delivery_workers_web', language: e.target.value}).then(response => {
                if (response.data.data) {
                    setAudioURL(response.data.data)
                }
            })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }
    const handleSubmit = () => {

        if (clientSelected === '' || clientSelected === undefined || clientSelected === DEFAULT) {
            setClientError(true)
            return;
        }

        if (!fileUploaded || fileUploaded.length === 0) {
            setFileError(true)
            return;
        }

        if(clientSelected === 'Zomato' && citySelected === ''){
            setCityError(true);
            return;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('files', fileUploaded);
        formData.append('city', citySelected);
        formData.append('companyName', clientSelected);
        formData.append('mitraManagerId', mitra.id);

        uploadBulkUniquenessList(formData).then(response => {
            if (response.data.status) {
                setLoading(false)
                setUploadSuccess(response.data.data.bulkUploadUniquenessLeadId)
            }
        })
            .catch(err => {
                if(err.response && err.response.data){
                    setUniquenessError(err.response.data.error)
                }
                setLoading(false)
            })
    }
    const playPauseAudio = (flag) => {
        const x = document.getElementById("myAudio");
        if (x.getAttribute('listener') !== 'true') {
            x.addEventListener('timeupdate', handleAudioProgress);
        }
        if (flag) {
            x.play()
        } else {
            x.pause();
        }

    }
    const handleAudioProgress = () => {
        const player = document.getElementById("myAudio");
        var currentTime = player.currentTime;
        var duration = player.duration;
        const slider = document.getElementById("hp_range");
        if (slider)
            slider.style.width = (currentTime + .25) / duration * 100 + '%';
    }
    const handleSubmitIVR = () => {
        if (langSelected == 'null' || !langSelected) {
            setLanguageError(true)
            return;
        }
        if (!fileUploaded || fileUploaded.length === 0) {
            setFileError(true)
            return;
        }
        const formData = new FormData();
        formData.append('file', fileUploaded);
        formData.append('language', langSelected);
        setLoading(true)
        bulkIVRUpload(formData).then(response => {
            if (response.data) {
                setLoading(false)
                setUploadedLeadsData(response.data.data.bulkUploadUniquenessLeadId)
                setUploadSuccess(true)
                if (response.data.data.file) {
                    window.open(response.data.data.file, '_blank')
                }
            }
        })
            .catch((err) => {
                if(err.response && err.response.data){
                    setIVRError(err.response.data.message)
                }
                setLoading(false)
            })
    }

    return (
        <div className="bulk-action-container">
            {
                window.innerWidth < 550 ?
                    <div className="header">
                        <div style={{textAlign: 'left', paddingLeft: '10px'}}>
                            <i className="fas fa-arrow-left" onClick={() => {if (header === 'Bulk Actions') {history.push("/")} else {window.location.reload()} }} style={{cursor: 'pointer', marginRight: '10px'}}></i>
                            <span className="welcome">{header}</span>
                        </div>
                    </div>
                    : null
            }
            {
                bulkActionType == '' && !loading ?
                    <div>
                        <button style={{width: '90%', padding: '20px', display: 'flex', flexDirection: 'row', margin: '10px auto'}} type="button" className="btn btn-outline-danger" onClick={() => {setBulkActionType('Bulk Uniqueness'); setHeader('Bulk Uniqueness Check'); BulkActionGAEventsTracker("ClickOnBulkUniqueness"); if (setParentHeader !== undefined) {setParentHeader('Bulk Uniqueness Check')} }}>
                            <i className="fas fa-users vertical-middle" style={{width: '10%', marginRight: '4px'}}></i>
                            <span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">Bulk Uniqueness</span>
                            <span style={{border: 'solid 1px', borderRadius: '100px', padding: '4px 12px'}}>
                                <i className="fas fa-angle-right"></i>
                            </span>
                        </button>

                        <button style={{width: '90%', padding: '20px', display: 'flex', flexDirection: 'row', margin: 'auto', marginBottom: "10px"}} type="button" className="btn btn-outline-danger" onClick={() => {setBulkActionType('Bulk IVR calls'); setHeader('Run Bulk IVR Calls'); BulkActionGAEventsTracker("ClickOnBulkIVR"); if (setParentHeader !== undefined) {setParentHeader('Run Bulk IVR Calls')} }}>
                            <i className="fas fa-users vertical-middle" style={{width: '10%', marginRight: '4px'}}></i>
                            <span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">Run Bulk IVR calls</span>
                            <span style={{border: 'solid 1px', borderRadius: '100px', padding: '4px 12px'}}>
                                <i className="fas fa-angle-right"></i>
                            </span>
                        </button>
                        <br />
                        <div style={{padding: '0px 30px', display: 'flex', flexDirection: 'row'}}>
                            <div>
                                <span style={{border: 'solid 1px', borderRadius: '100px', padding: '3px 8px', fontSize: '12px', marginRight: '5px'}}>
                                    i
                                </span>
                            </div>
                            <span style={{textAlign: 'justify'}}>
                                Interactive Voice Response(IVR) is an automated phone system technology that allows incoming callers to access information via a voice response system of pre recorded messages.
                            </span>
                        </div>
                    </div>
                    : null
            }

            {/* {
                bulkActionType === 'Bulk Uniqueness' ? 
                    <div style={{marginTop: '10px'}}>
                        <span style={{textAlign: 'left'}}>
                            Client Name
                        </span>
                        <div className="col-12 col-md-12"  >
                            <select style={{width: '90%'}} name="Client" placeholder="Client" value={''}>
                                <option selected value={DEFAULT} disabled>
                                    Client
                                </option>
                            </select>
                        </div>
                        <div className="col-12 col-md-12" style={{marginTop: '10px'}}>
                            <button style={{width: '90%', padding: '20px'}} type="button" className="btn btn-outline-danger" onClick={() => {}}>
                                Upload lead
                            </button>
                        </div>
                        <div className="col-12 col-md-12" style={{marginTop: '10px'}}>
                            <button style={{width: '90%', padding: '20px'}} type="button" className="btn btn-outline-secondary" onClick={() => {}}>
                                Download Template
                            </button>
                        </div>
                    </div> 
                : null
            } */}
            <div style={{marginTop: '10px'}}>
                {
                    bulkActionType === 'Bulk IVR calls' && !loading && !uploadSuccess ?
                        <div>
                            <div className="col-12 col-md-12"  >
                                <select style={{width: '90%'}} name="language" placeholder="language" value={langSelected} onChange={(e) => {handleLanguageChnage(e); BulkActionGAEventsTracker("ClickOnLanguageSelectorBulkIVR")}}>
                                    <option value={''}>
                                        Select Language
                                    </option>
                                    {['Hindi'].map((lang) => (
                                        <option className="Dropdown-option" key={lang} value={lang}>
                                            {lang}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {
                                languageError ?
                                    <div className="client-error">
                                        * Language is required
                                    </div>
                                    : null
                            }

                            {
                                audioURL ? <div className="row">
                                    <div className="col-2 col-md-2"  >
                                        <button style={{fontSize: '40px',color: 'rgb(211, 70, 18)'}} onClick={() => {setPlaying(!playing); playPauseAudio(!playing)}}><i className={playing ? 'fas fa-pause-circle' : 'fas fa-play-circle'}></i></button>
                                        <audio id="myAudio">
                                            <source src={audioURL} type="audio/mpeg" />
                                        </audio>
                                    </div>
                                    <div className="col-8 col-md-8">
                                        {playing ? <div className="hp_slide">
                                            <div className="hp_range" id="hp_range"></div>
                                        </div> : null}
                                    </div>
                                </div> : null
                            }

                            <div className="noteHeading">Note: Headers of the file must be candidateName and candidatePhoneNumber</div>

                            <div style={{marginTop: '10px'}}>
                                <div style={{}} type="file" className="upload-leads">
                                    <label htmlFor="file-upload" className="btn upload-leads-label" style={{color: '#D34612', textAlign: 'center'}}>
                                        <i className="fas fa-file-excel"></i>&nbsp;&nbsp;
                                        Upload Leads
                                    </label>
                                    <input id="file-upload" style={{visibility: "hidden"}} type="file" onChange={(e) => {handleFileUpload(e); BulkActionGAEventsTracker("ClickOnUploadLeadsBulkIVR")}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div>

                                {
                                    fileUploaded.length !== 0 ?
                                        <div onClick={() => {setFilUploaded([]); document.getElementById('file-upload').value = []}} className="file-uploaded">
                                            <div style={{width: '90%', textAlign: 'left'}}>
                                                <i className="fas fa-file-excel"></i>&nbsp;&nbsp;
                                                {fileUploaded.name}
                                            </div>

                                            <i className="far fa-times-circle vertical-middle" style={{width: '10%', textAlign: 'right'}}></i>
                                        </div>
                                        : null
                                }

                                {
                                    fileError ?
                                        <div className="client-error">
                                            * Upload the Leads document.
                                        </div>
                                        : null
                                }

                            </div>

                            <a type="button" className="download-template" target="_blank" href="https://docs.google.com/spreadsheets/d/1O_vq_3ngvPlgkwFVypMvRVIn_2_ukIcTrpwNQYG6jb4/edit?usp=gmail_thread&ts=61a75458"onClick={() => {BulkActionGAEventsTracker("ClickOnDownloadTemplateBulkIVR")}}>
                                <i className="fas fa-file-excel"></i>&nbsp;&nbsp;
                                Download Template
                            </a>

                            {
                                IVRError !== '' ? 
                                    <div style={{color: 'red', padding: '5px 35px', textAlign: 'justify'}}>* {IVRError}</div>
                                : null
                            }

                            <div style={{textAlign: 'center', margin: '10px auto'}}>
                                <div className="submit-button" onClick={() => {handleSubmitIVR(); BulkActionGAEventsTracker("SsubmitBulkIVRForm")}}>Submit</div>
                            </div>
                        </div>
                        : null
                }


                {
                    bulkActionType !== '' && bulkActionType !== 'Bulk IVR calls' && !loading && !uploadSuccess ?
                        <div>
                            <div style={{textAlign: 'left', padding: '5px 30px'}}>
                                <span style={{color: 'red', marginRight: '2px'}}>*</span>
                                <span>Client Name</span>
                            </div>
                            <div className="col-12 col-md-12"  >
                                <select style={{width: '90%'}} name="Client" placeholder="Client" value={clientSelected} onChange={(e) => {handleClientChange(e); BulkActionGAEventsTracker("ClickOnClientSelectorBulkUniqueness")}}>
                                    <option defaultValue={DEFAULT} >
                                        Select Client
                                    </option>
                                    <option value='Zomato' >
                                        Zomato
                                    </option><option value='Shadowfax' >
                                        Shadowfax
                                    </option>
                                </select>
                            </div>

                            {
                                clientError ?
                                    <div className="client-error">
                                        * Client name is required
                                    </div>
                                    : null
                            }

                            {
                                clientSelected === 'Zomato' ?
                                    <div className="col-12 col-md-12" style={{marginTop: '10px'}} >
                                        <select style={{width: '90%'}} name="Client" placeholder="Client" value={citySelected} onChange={(e) => {handleCityChange(e); BulkActionGAEventsTracker("CLickOnCitySelectorBulkUniqueness")}}>
                                            <option defaultValue={DEFAULT} >
                                                Select City
                                            </option>
                                            {
                                                cities.map(single_city => {
                                                    return <option value={single_city} >
                                                        {single_city}
                                                    </option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    : null
                            }

                            {
                                cityError && (clientSelected === 'Zomato' ?
                                    <div className="client-error">
                                        * City name is required
                                    </div>
                                    : null)
                            }

                            

                            <div className="noteHeading">Note: Headers of the file must be candidateName and candidatePhoneNumber</div>

                            <div style={{marginTop: '10px'}}>
                                <div style={{}} type="file" className="upload-leads">
                                    <label htmlFor="file-upload" className="btn upload-leads-label" style={{color: '#D34612', textAlign: 'center'}}>
                                        <i className="fas fa-file-excel"></i>&nbsp;&nbsp;
                                        Upload Leads
                                    </label>
                                    <input id="file-upload" style={{visibility: "hidden"}} type="file" onChange={(e) => {handleFileUpload(e); BulkActionGAEventsTracker("ClickOnUploadLeadsBulkUniqueness")}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </div>

                                {
                                    fileUploaded.length !== 0 ?
                                        <div onClick={() => {setFilUploaded([]); document.getElementById('file-upload').value = []}} className="file-uploaded">
                                            <div style={{width: '90%', textAlign: 'left'}}>
                                                <i className="fas fa-file-excel"></i>&nbsp;&nbsp;
                                                {fileUploaded.name}
                                            </div>

                                            <i className="far fa-times-circle vertical-middle" style={{width: '10%', textAlign: 'right'}}></i>
                                        </div>
                                        : null
                                }

                                {
                                    fileError ?
                                        <div className="client-error">
                                            * Upload the Leads document.
                                        </div>
                                        : null
                                }

                            </div>
                            <a type="button" className="download-template" target="_blank" href="https://docs.google.com/spreadsheets/d/1O_vq_3ngvPlgkwFVypMvRVIn_2_ukIcTrpwNQYG6jb4/edit?usp=gmail_thread&ts=61a75458" onClick={() => {BulkActionGAEventsTracker("ClickOnDownloadTemplateBulkUniqueness")}}>
                                <i className="fas fa-file-excel"></i>&nbsp;&nbsp;
                                Download Template
                            </a>

                            {
                                uniquenessError !== '' ? 
                                     <div style={{color: 'red', padding: '5px 35px', textAlign: 'justify'}}>* {uniquenessError}</div>
                                : null
                            }

                            <div style={{textAlign: 'center', margin: '10px auto'}}>
                                <div className="submit-button" onClick={() => {handleSubmit(); BulkActionGAEventsTracker("submitBulkUniquenessForm");}}>Submit</div>
                            </div>
                        </div>
                        : null
                }

                {
                    loading ?
                        <div className="loader_clip">
                            <ClipLoader size={100} />
                            <div>Please do not refresh or Press back button file is uploading! </div>
                        </div>
                        : null
                }

                {
                    uploadSuccess ?
                        <div style={{textAlign: 'center'}}>
                            <i className="fas fa-check-circle fa-7x" style={{margin: '40px auto', color: '#52C41A'}}></i>
                            <div style={{textAlign: 'center', marginBottom: '10px'}}>File Upladed Successfully!</div>
                            <div className="view-leads" onClick={() => {if(bulkActionType === 'Bulk IVR calls') { history.push("/lead-management?tab=bulk-IVR")} else {history.push("/lead-management?tab=bulk-uniqueness")} localStorage.removeItem("filters")}}> View Leads </div>
                        </div>
                        : null

                }
            </div>
        </div>
    )
}

export default BulkAction;