import React, {useEffect, useState} from 'react';
// import axios from 'axios';
import '../css/jobRecommendation.css';
import {
  createJobRecommendationAction,
  getAllClientNames,
  getRecommendedJobs,
  submitJobs,
  uniquenessCheck,
} from '../service/jobRecommendation';
// import { getJobDemandsOnFiltersChange } from '../service/jobDemandsService';
import {useHistory} from 'react-router-dom';
import BasicInfoForm from './BasicInfoForm';
import mitras from "../constants/crossReferralMitras"
import * as loginService from '../service/loginService';
import useMoengage from '../hooks/useMoengage';

const JobRecommendationaPage = (props) => {
  const [companies, setCompanies] = useState(undefined);
  const [jobs, setJobs] = useState([]);
  const [jobContainer, setJobContainer] = useState([]);
  const [selectedClient, setselectedClient] = useState('');
  const [seeMoreFlag, setseeMoreFlag] = useState(false);
  const [selectPreSets, setSelectPreSets] = useState(null);
  const [jobFlag, setJobFlag] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [preferedJobsFlag, setPreferedJobsFlag] = useState(true);
  const [goBack, setGoBack] = useState(false);
  const [splashScreen, setSplashScreen] = useState(null);

  const [mitra, setMitra] = useState();
  const [createMoengageUser, trackEvent] = useMoengage();
  const history = useHistory();

  useEffect(() => {
    // getAllClientNames(props.basicFormData.cityPreference, props.basicFormData.desiredRole).then(function (result) {
    //   if (result) {
    //     setCompanies(result.data.clientNames);
    //   }
    // });

    loginService
      .validateSession()
      .then((response) => {
        const loggedInMitra = response.data.mitra;
        setMitra(loggedInMitra);
        handleClientName(props.basicFormData.clientPreference, loggedInMitra)

        if (localStorage.getItem('splashFlag') || props.basicFormData.clientPreference != 'Zomato' || !mitras.includes(response.data.mitra.id)) {
          setSplashScreen(false);
        } else {
          setSplashScreen(true);

        }
      })
      .catch((error) => {
        history.push({
          pathname: '/',
          state: {
            redirectTo: '/jobs'
          }
        })
      });
  }, []);
  const handleClientName = (e, mitra) => {
    setJobFlag(false);
    setselectedClient(e);
    setseeMoreFlag(true);
    setJobs([]);
    getRecommendations(
      e,
      props.basicFormData.firstLocalityPreference,
      props.basicFormData.desiredRole,
      props.basicFormData.phoneNumber,
      mitra
    );
  };
  const handleClientRequirementSelection = (e, i) => {
    jobs.map((ele) => {
      ele.partTime = undefined;
    });

    jobs[i].partTime = e.target.value;
    setJobs(jobs);
    setSelectPreSets(i);
  };
  const seemoreRecommendation = () => {
    setseeMoreFlag(false);
    setJobs([...jobContainer.slice(0, 6)]);
  };
  const getRecommendations = (company, location, jobCategory, phoneNumber, mitra) => {
    setPreferedJobsFlag(true);

    getRecommendedJobs(company, location, jobCategory, phoneNumber, mitra).then(function (result) {
      if (result) {
        setJobContainer(result.data.data.slice());
        setJobs(result.data.data.splice(0, 3));
        setJobFlag(true);

        if (jobContainer.length) {
          let flag = jobContainer.filter((ele) => {
            return ele.companyName.toLowerCase() == company.toLowerCase();
          });

          if (flag.length == 0) {
            setPreferedJobsFlag(false);
            console.log(preferedJobsFlag);
          }
        }
      }
    });
  };
  const capitalizeName = (name) => {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };
  const conditionalOptions = (job) => {
    if (job.isPartTimeAvailable) {
      return (
        <div className="d-flex justify-content-around jumbotron" >
          <div>
            <input type="radio" value="false" name="requirement" />Full Time
          </div>
          <div>
            <input type="radio" value="true" name="requirement" />Part Time
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-around jumbotron" >
          <div>
            <input type="radio" value="false" name="requirement" />Full Time

          </div>
        </div>
      );
    }
  };

  const goBackToBasicInfoForm = () => {
    setGoBack(true);
  };
  const checkForCrossReferalInstruction = () => {
    let flag = false;
    let finalJobs = jobs.filter((element, index) => {
      if (element.companyName === 'Zomato' && ['true', 'false'].includes(element.partTime)) {
        flag = true;
      }
      return ['true', 'false'].includes(element.partTime);
    });
    if (finalJobs.length && flag && mitras.includes(mitra.id)) {
      document.getElementById('myModal').style.display = 'block';
    } else {
      submit()
    }
  }
  const submit = async () => {
    setDisableSubmit(true);
    let finalJobs = jobs.filter((element, index) => {
      return ['true', 'false'].includes(element.partTime);
    });
    if (finalJobs.length < 1) {
      alert('Please select a job');
      setDisableSubmit(false);
      return;
    }
    let errors = [];
    try {
      const checkFlag = finalJobs.findIndex(ele => ele.companyName === 'uber')
      if (checkFlag === -1) {
        const uniquenessRes = await uniquenessCheck(finalJobs[0], props.basicFormData, props.mitra);

        if (uniquenessRes.data.uniqueness !== '200') {
          alert(uniquenessRes.data.uniqueness);
          setDisableSubmit(false);
          return;
        }
      }

      const createJobRecommendationActionRes = await createJobRecommendationAction(
        finalJobs[0],
        props.basicFormData,
        props.mitra
      );

      if (createJobRecommendationActionRes.data.createJobRecommendation !== '200') {
        alert(createJobRecommendationActionRes.data.createJobRecommendation);
        setDisableSubmit(false);
        return;
      }

      const res = await submitJobs(finalJobs[0], props.basicFormData, props.mitra);
      if (res.checkAdditionalForm) {
        localStorage.setItem(
          'lead',
          JSON.stringify({
            ...props.basicFormData,
            companyName: finalJobs[0].companyName,
            jobId: finalJobs[0].jobid,
            jobDemandId: finalJobs[0].jobdemandid,
            companyCity: finalJobs[0].city
          })
        );

        return history.push({pathname: '/moreInfo', route: 'jobRecommendationPage'});
      }
      for (let key in res.data) {
        if (res.data[key] !== "200")
          errors.push(`${key}: ${res.data[key]}`)
      }
    } catch (error) {
      // console.log(error)
      errors = error.response ? [error.response.data.message] : ["something went wrong"]
    }

    if (errors.length > 0) {
      alert(errors[0])
    }
    else {
      trackEvent('single_application_created', {
        client_name : props.basicFormData.clientPreference,
        application_city_name : props.basicFormData.cityPreference,
        source_id : props.mitra.id
      });
      history.replace("/leads")
    }

    setDisableSubmit(false);
  };
  const renderRecommendationButton = () => {
    // if (companies.length === 0) {
    //   return (
    //     <>
    //       <br />
    //       <div>
    //         No jobs available based on your job category and city preference. Please go back and choose another options.
    //       </div>
    //       <br />
    //       <button
    //         type="button"
    //         className="btn btn-dark mt-3"
    //         onClick={goBackToBasicInfoForm}
    //         disabled={disableSubmit}
    //         style={{marginBottom: '100px'}}
    //       >
    //         Go Back
    //       </button>
    //     </>
    //   )
    // }
    if (jobs.length && seeMoreFlag) {
      return (
        <div className="bottomMargin">
          <button type="button" onClick={seemoreRecommendation} className="btn btn-dark">
            See More Recommendation
          </button>
          <br />
          <button
            type="button"
            className="btn btn-dark mt-3"
            onClick={goBackToBasicInfoForm}
            disabled={disableSubmit}
          >
            Go Back
          </button>
          <button
            type="button"
            onClick={checkForCrossReferalInstruction}
            className="btn btn-dark mt-3"
            disabled={disableSubmit}
          >
            Submit
          </button>
        </div>
      );
    } else if (jobs.length == 6) {
      return (
        <>
          <div>
            You have generated the max limit of 6 job recommendations. Please choose one of the jobs
            to proceed with the application.
          </div>
          <button
            type="button"
            className="btn btn-dark mt-3"
            onClick={goBackToBasicInfoForm}
            disabled={disableSubmit}
            style={{marginBottom: '100px'}}
          >
            Go Back
          </button>
          <button
            style={{marginBottom: '100px'}}
            type="button"
            onClick={checkForCrossReferalInstruction}
            className="btn btn-dark mt-3"
            disabled={disableSubmit}
          >
            Submit
          </button>
        </>
      );
    } else if (jobs.length) {
      return (
        <>
          <button
            type="button"
            className="btn btn-dark mt-3"
            onClick={goBackToBasicInfoForm}
            disabled={disableSubmit}
            style={{marginBottom: '100px'}}
          >
            Go Back
          </button>
          <button
            style={{marginBottom: '100px'}}
            type="button"
            onClick={checkForCrossReferalInstruction}
            className="btn btn-dark mt-3"
            disabled={disableSubmit}
          >
            Submit
          </button>
        </>
      );
    } else {
      return (
        <>
          <br />
          <div>
            No jobs available based on your job category and city preference. Please go back and choose another options.
          </div>
          <br />
          <button
            type="button"
            className="btn btn-dark mt-3"
            onClick={goBackToBasicInfoForm}
            disabled={disableSubmit}
            style={{marginBottom: '100px'}}
          >
            Go Back
          </button>
        </>
      )
    }
  };
  const NoDataAvailableMessage = () => {
    if (!jobs.length && jobFlag) {
      return (
        <span>
          'No jobs are presently available based on your selected preferences. Go back to Home Page
          to create a new application for this lead'
        </span>
      );
    } else if (jobs.length && !preferedJobsFlag) {
      return (
        <span>
          We presently do not have any {selectedClient} jobs in your city/locality at the moment.
          But we have other client jobs available for you to choose from.
        </span>
      );
    }
  };
  const splashClose = (e) => {
    setSplashScreen(false);
  }
  const dontShowSplash = () => {
    localStorage.setItem('splashFlag', true);
    setSplashScreen(false);
  }
  const allSelection = (value) => {
    jobs.map((ele) => {
      if (value) {
        if (ele.isPartTimeAvailable) ele.partTime = value.toString();
      } else {
        ele.partTime = value.toString();
      }
    });
    setJobs(jobs);
  };
  const sticker = (job) => {
    if (selectedClient == job.companyName) {
      return 'Your preferred client'
    } else {
      return 'Recommended by vahan'

    }
  }
  if (goBack) {
    return (
      <BasicInfoForm
        mitra={props.mitra}
        name={props.basicFormData.name}
        phoneNumber={props.basicFormData.phoneNumber}
        alternatePhoneNumber={props.basicFormData.alternatePhoneNumber}
      />
    );
  }

  return (
    <div className="container">
      {splashScreen ?
        <>
          <img className="img-fluid" src="/image/CR Image.png" />
          <div style={{textAlign: 'left', marginTop: '10px'}}>
            <h6><b>Zomato X Shadowfax</b></h6>
            <h5><b>Cross Refer</b></h5>
            <br />
            <p>Mitra leaders who cross refer their candidates to Shadowfax are likely to earn upto Rs 1300 extra per eligible placement.</p>
            <ul>
              <li>
                This feature is currently available for Zomato and Shadowfax.
              </li>
              <li>If the candidate is not unique for Zomato, Vahan will automatically check candidate’s uniqueness for Shadowfax so that you can refer the candidate to Shadowfax in 2 quick steps!</li>
            </ul>
            <div className="progress">
              <div className="progress-bar bg-danger" role="progressbar" style={{width: '50%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div className="text-center" style={{marginTop: '10px'}}>
              <button type="button" className="btn btn-dark" onClick={splashClose}>
                Got it
              </button>
              <br />

              <p style={{marginTop: '5px', cursor: 'pointer', marginTop: '25px', }} onClick={dontShowSplash}>

                Don't Show me again
              </p>
            </div>
          </div>
        </> :
        <>
          <h1>Job Recommendation</h1>
          {/* <div className="input-text-title field-required">Client Preference</div>
          <select
            id="basic-form-clientName"
            name="clientName"
            onChange={handleClientName}
            value={selectedClient}
          >
            <option value="" disabled>
              Client Name
            </option>
            {companies && companies.map((client) => (
              <option className="Dropdown-option" key={client} value={client}>
                {client}
              </option>
            ))}
          </select> */}

          <div style={{margin: '0 auto', width: '80%'}} className="mt-2">
            {/* Multi select option Commented for V2 */}
            {/* <div key="kebabIcon" className="mb-3 dropdown">
          <img data-toggle="dropdown" style={{ height: '25px', float: 'right' }} src="/menu.png" />
          <div className="dropdown-content">
            <p className="hoverClass" onClick={() => allSelection(false)}>
              All Full Time
            </p>
            <p className="hoverClass" onClick={() => allSelection(true)}>
              All Part Time
            </p>
          </div>
        </div> */}
            {NoDataAvailableMessage()}

            {jobs.map((job, index) => (

              <div className="card" style={{padding: '10px', textAlign: 'left', marginTop: '10px'}} key={'card' + index}>
                <div className="row">
                  <div className="offset-md-1 col-md-3 col-3">
                    <img style={{marginTop: '20px'}} className="img-fluid imageLogo" src={'/image/' + job.companyName + '.png'} />
                  </div>
                  <div className="col-md-4 col-9">
                    <div style={{marginTop: '20px'}}>
                      <h6>{sticker(job)}</h6>
                      <h5>Upto {job.baseSalary}</h5>
                    </div>
                    <div>
                      {job.name ? <span className="badges" style={{marginTop: '3px'}}> {job.name}</span> : null}
                      {job.vertical ? <span className="badges" style={{marginTop: '3px'}}> {job.vertical}</span> : null}

                    </div>
                    <div>
                      {job.companyName.toLowerCase() == 'zomato' ? <><img src="/image/icon_info.png" alt="" />Cross Referral available.</> : null}

                    </div>
                  </div>
                  <div className="col-md-3 col-12  ">
                    <div onChange={($event) => handleClientRequirementSelection($event, index)}>
                      {conditionalOptions(job)}
                    </div>
                    {/* <div className="d-flex justify-content-around jumbotron" >
                    <div>
                      <input type="radio" id="html" name="fav_language" value="HTML" />
                      <label for="html">HTML</label></div>
                    <div>
                      <input type="radio" id="css" name="fav_language" value="CSS" />
                      <label for="css">CSS</label>
                    </div>
                  </div> */}
                  </div>

                </div>
              </div>


            ))}
            {renderRecommendationButton()}
          </div></>}
      <div id="myModal" className="modal">
        <div className="modal-contents">
          <div className="row">
            <div className="col-10" style={{textAlign: 'left'}}>
              <h5>How cross referral works?</h5>
            </div>
            <div className="col-2">
              <h6 className="close" onClick={() => {
                document.getElementById('myModal').style.display = 'none'
              }}>&times;</h6>
            </div>

          </div>
          <hr />

          <ol style={{textAlign: 'left'}}>
            <li className="liPadding">
              <h6> <strong>Select Prefered job</strong></h6>
              When you select the Zomato job, we unlock the Cross Referral for Shadowfax as well!
            </li>

            <li className="liPadding">
              <h6><strong>Check Uniqueness</strong></h6>
              If candidate is Not-Unique for Zomato, then we will automatically refer candidate to Shadowfax
            </li>

            <li className="liPadding">
              <h6><strong>Create 2nd application in no time</strong></h6>
              If candidate is Unique for Shadowfax, you can complete the Shadowfax application in 2 quick steps!
            </li>

          </ol>
          <button type="button" className="btn btn-dark" style={{width: '80%'}} onClick={submit}>
            Next
          </button>
        </div>

      </div>
    </div>
  );
};

export default JobRecommendationaPage;