import React, { useRef } from 'react'
import { Form } from 'react-formio';
import {logEvent} from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';

export default function OnboardingQuestions(props) {

    const QUESTION_KEY_MAPPINGS = useRef({
        areYouWorkingWithAnyDeliveryClientsCurrently: 'Are you working with any Delivery clients currently?',
        haveYouRecruitedGeneratedLeadsForDeliveryBoysPartnersBefore: 'Have you recruited/generated leads for delivery boys/partners before?',
        howManyMembersAreInYourRecruitmentTeam: 'How many members are in your recruitment team?',
        isYourOccupationFullTimeOrPartTime: 'Is your occupation Full time or part time?',
        whatIsYourCurrentOccupation: 'What is your current occupation?',
        whichClientAreYouWorkingWithCurrently: 'which client are you working with currently?',
    })

    const {signupData, sendOTP, onboardingQuestions} = props;

    const onboardingFormOptions = {
		hooks: {
			beforeSubmit: async (submission, next) => {
				for(const key in submission.data) {
                    if(submission.data[key] && QUESTION_KEY_MAPPINGS.current[key]) {
                        onboardingQuestions.current.push({
                            key,
                            question: QUESTION_KEY_MAPPINGS.current[key],
                            answer: submission.data[key]
                        })
                    }
                }
				logEvent('RequestSignupOTP', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);

				if (window.location.href.toLowerCase().includes('?source')) {
					let regex = /(?<=source=).*?(?=&|$)/;
					let source = regex.exec(window.location.href);
					signupData.acquisitionSource = source.length ? source[0] : '';
				} else {
					signupData.acquisitionSource = 'Self Signup';
				}
				try {
					await sendOTP(signupData.phoneNumber)
				} catch (error) {
					next([{message: error.response ? error.response.data.message : "Service Unavailable"}])
				}
			}
		}
	}

    return (
        <div>
            <Form src={process.env.REACT_APP_FORM_IO_ONBOARDING_QUESTIONS_URL} options={onboardingFormOptions} />
        </div>
    )
}
