import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Button} from '@rmwc/button';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import '../css/home.css'
import * as homeService from './../service/homeService';
import NavigationTab from './common/NavigationTab.component';
import {logEvent, setTrackingUser} from '../service/tracking';
import trackingConstants from '../constants/trackingConstants';
import JobsTable from './JobsTable.component';
import BasicInfoForm from './BasicInfoForm';
import useGAEventsTracker from '../service/useGSEventsTracker';
import FilterPopup from './leads/Filter-popup.component';
import useMoengage from './../hooks/useMoengage';
import BulkActionSidesheet from './bulk-action/Bulk-action-sidesheet.component'
import moment from 'moment';
import {getBulkUniquenessList, getScheduledIVRList} from './../service/bulkActionService'
import Excel from 'exceljs';
import {saveAs} from 'file-saver';
import Moment from 'react-moment';

const DEFAULT = 'DEFAULT';
let deferredPrompt;
const Home = ({mitra, comingFromLoginPage}) => {
	const history = useHistory();
	const {register, handleSubmit, errors} = useForm();
	const [category, setCategory] = useState(DEFAULT);
	const [availableCategories, setAvailableCategories] = useState([]);
	const [companyCategoryMapping, setCompanyCategoryMapping] = useState([]);
	const [city, setCity] = useState(DEFAULT);
	const [company, setCompany] = useState(DEFAULT);
	const [jobLocation, setJobLocation] = useState(DEFAULT);
	const [leadAlreadyExistsMsg, setLeadAlreadyExistsMsg] = useState('');
	const [clients, setClients] = useState([]);
	const [cities, setCities] = useState([]);
	const [jobs, setJobs] = useState([]);
	const [jobLocations, setJobLocations] = useState([]);
	const [jobId, setJobId] = useState('');
	const [jobDemandId, setJobDemandId] = useState([]);
	const [candidateForm, setCandidateForm] = useState(false);
	const [homeScreenModal, sethomeScreenModal] = useState(true);
	const [openSearch, setOpenSearch] = useState(false)
	const [prevLeads, setPrevLeads] = useState([]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [name, setName] = useState('');
	const [phoneNumberVerified, setPhoneNumberVerified] = useState(null);
	const [errorsFile, setErrorsFile] = useState([]);
	const [bulkCandidateUploadForm, setBulkCandidateUploadForm] = useState(false);
	const [bulkCandidateFile, setBulkCandidateFile] = useState('');
	const [dlNumber, setDlNumber] = useState(null);
	const [extraRequiredFields, setExtraRequiredFields] = useState([]);
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [selection, setSelection] = useState(true)
	const [bulkAction, setBulkAction] = useState(false)
	const [bulkActionType, setBulkActionType] = useState('')
	const [isAdmin, setIsAdmin] = useState(false);
	const [searchValue, setSearchValue] = useState('')
	const [dateSelected, setDateSelected] = useState(new Date().toISOString().substr(0, 10))
	const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
	const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
	const [showIVRStatusFilterPopup, setIVRStatusShowFilterPopup] = useState(false);
	const [showResponseFilterPopup, setResponseShowFilterPopup] = useState(false);
	const [showSideSheet, setShowSideSheet] = useState(false);
	const [ivrLeads, setIVRLeads] = useState([])
	const [showFilterChpis, setShowFilterChips] = useState(false)
	const [showDateChip, setShowDateChip] = useState(false)
	const [clientSelected, setClientSelcted] = useState([
		{label: 'Zomato', value: false, backendValue: 'Zomato'},
		{label: 'Shadowfax', value: false, backendValue: 'Shadowfax'}
	])
	const [bulkStatusesSelected, setBulkStatusesSelected] = useState([
		{label: 'Pending', value: false, backendValue: 'pendingStatus'},
		{label: 'Unique', value: false, backendValue: 'uniqueStatus'},
		{label: 'Not Unique', value: false, backendValue: 'nonUniqueStatus'}
	])
	const [bulkIVRStatusesSelected, setBulkIVRStatusesSelected] = useState([
		{label: 'Call Connected', value: false, backendValue: 'Completed'},
		{label: 'Call Disconnected(Hangup)', value: false, backendValue: 'Hangup'},
		{label: 'IVR Scheduled(Queued)', value: false, backendValue: 'Initiated'},
		{label: 'IVR Scheduled', value: false, backendValue: 'Created'},
		{label: 'Call Disconnected(DND)', value: false, backendValue: 'DND User'},
		{label: 'Call Disconnected(Failed)', value: false, backendValue: 'Failed'},
	])
	const [bulkResponseSelected, setBulkResponseSelected] = useState([
		{label: 'No Bike', value: false, backendValue: 'No Bike'},
		{label: 'Has Bike', value: false, backendValue: 'Has Bike'},
		{label: 'Interested', value: false, backendValue: 'Interested'},
		{label: 'Not Interested', value: false, backendValue: 'Not Interested'},
		{label: 'Have Driving License', value: false, backendValue: 'Have Driving License'},
		{label: 'No Driving License', value: false, backendValue: 'No Driving License'}
	])
	const [leads, setLeads] = useState([])
	const statusColorMapping = {
        "Call Connected": "rgb(75 170 90)",
        "Call Disconnected(Hangup)": "rgb(168 35 35)",
        "IVR Scheduled(Queued)": "rgb(32 184 170)",
        "IVR Scheduled": '#000',
        "Call Disconnected(DND)": 'color: rgb(255 0 0)',
        "Call Disconnected(Failed)": 'rgb(244 116 55)'
    };

	const GAEventsTracker = useGAEventsTracker("Referral Flow")
	const BulkActionGAEventsTracker = useGAEventsTracker("bulkActions")

	const [createMoengageUser, trackEvent] = useMoengage();
	useEffect(() => {
		setTrackingUser(mitra);
		if (mitra.role === 'admin') {
			setIsAdmin(true);
		}
		if (comingFromLoginPage) {
			sethomeScreenModal(false)
		}

		logEvent('OpenHomeTab', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);

		window.addEventListener("beforeinstallprompt", (e) => {
			// Prevent the mini-infobar from appearing on mobile
			e.preventDefault();
			// Stash the event so it can be triggered later.
			deferredPrompt = e;
			// Update UI notify the user they can install the PWA
			sethomeScreenModal(false);
		});

		const getPremiumJobsCities = () => {
			homeService.getPremiumJobsCities().then((result) => {
				if (result && result.data.cities) {
					const cities = result.data.cities;
					const totalCities = cities.map((c) => c.city);
					setCities(Object.values(totalCities));
				}
			});
		};
		getPremiumJobsCities();

	}, []);

	useEffect(() => {
		if (selection) {
			let clientArray = [], statusArray = [];
			clientSelected.map(ele => {
				if (ele.value) {
					clientArray.push(ele.backendValue.toLowerCase())
				}
			})
			bulkStatusesSelected.map(ele => {
				if (ele.value) {
					statusArray.push(ele.backendValue)
				}
			})
			const data = {
				startDate: dateSelected + ' 00:00:01',
				endDate: dateSelected + ' 23:59:59',
				client: JSON.stringify(clientArray),
				status: JSON.stringify(statusArray),
				mitraManagerId: mitra.id,
			}
			getBulkUniquenessList(data).then((response) => {
				if (response.data.data)
					setLeads(response.data.data.length > 5 ? response.data.data.splice(0,5) : response.data.data)
					setPrevLeads(response.data.data.length > 5 ? response.data.data.splice(0,5) : response.data.data)
			}).catch(e => {
				console.log(e);
			})
		}
		else {
			let IVRStatusArray = [], responseArray = [];

			bulkIVRStatusesSelected.map(ele => {
				if (ele.value) {
					IVRStatusArray.push(ele.label)
				}
			})

			bulkResponseSelected.map(ele => {
				if (ele.value) {
					responseArray.push(ele.backendValue)
				}
			})
			const data = {
				startDate: dateSelected + ' 00:00:01',
				endDate: dateSelected + ' 23:59:59',
				status: JSON.stringify(IVRStatusArray),
				response: JSON.stringify(responseArray),
				limit: 5
			}
			getScheduledIVRList(data).then((response) => {
				if (response.data.data)
					setIVRLeads(response.data.data)
				setPrevLeads(response.data.data)
			}).catch(e => {
				console.log(e);
			})
		}

	}, [dateSelected, clientSelected, bulkStatusesSelected, bulkResponseSelected, bulkIVRStatusesSelected, selection])

	const handleJobTypeChange = async (e) => {
		const chosenCategory = e.target.value;
		setCategory(chosenCategory);
		if (company && phoneNumber.length === 10 && chosenCategory) {
			const duplicateLead = await validateLeadPhoneNumber(phoneNumber, company, chosenCategory);
			if (duplicateLead) {
				setPhoneNumberVerified(true);
			} else {
				setPhoneNumberVerified(false);
				getJobLocations(city, company);
			}
		} else if (bulkCandidateUploadForm && company && chosenCategory) {
			getJobLocations(city, company);
		}
	};

	const handleCityChange = (e) => {
		setCity(e.target.value);
		setCompanyCategoryMapping([]);
		setJobs([]);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobLocations([]);
		setPhoneNumberVerified(false);
		getClientsAndCompanies(e.target.value);
	};

	const handleCityChangeForBulkUpload = (e) => {
		setCity(e.target.value);
		setCompanyCategoryMapping([]);
		setJobs([]);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobLocations([]);
		setPhoneNumberVerified(false);
		getClientsAndCompaniesForBulkUpload(e.target.value);
	};

	const handleJobChange = (jobId, jobDemandId) => {
		setJobId(jobId);
		setJobDemandId(jobDemandId);
		setPhoneNumberVerified(false);
	};

	const handleJobLocationChange = (e) => {
		const chosenJobLocation = e.target.value;
		setJobLocation(chosenJobLocation);
		getJobs(category, city, company, chosenJobLocation);
	};

	const handleClientChange = (e) => {
		setPhoneNumberVerified(false);
		const chosenClient = e.target.value;
		setCompany(chosenClient);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setAvailableCategories(companyCategoryMapping[chosenClient]);
		setJobs([]);
		setJobLocations([]);
		homeService.getExtraRequiredFields(chosenClient)
			.then((result) => {
				if (result && result.data) {
					setExtraRequiredFields(result.data.extraRequiredFields);
				}
			});
	};

	const handleDLNumberChange = (e) => {
		setDlNumber(e.target.value);
	}

	const closeModal = async () => {
		sethomeScreenModal(true);
	};

	const handleInstallClick = (e) => {
		sethomeScreenModal(true);
		deferredPrompt.prompt();
		deferredPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
				console.log('User accepted the install prompt');
			} else {
				console.log('User dismissed the install prompt');
			}
		});
	};

	const handleNumberChange = (event) => {
		setPhoneNumberVerified(false);
		setPhoneNumber(event.target.value);
	};

	const handleNameChange = (event) => {
		setName(event.target.value);
	};

	const validateLeadPhoneNumber = async (phoneNumber, company, category) => {
		try {
			if (phoneNumber.length === 10 && company) {
				let url = `${process.env.REACT_APP_BACKEND_URL}/lead/validate?phoneNumber=${encodeURIComponent(
					phoneNumber)}&companyName=${(company)}&category=${(category)}`;
				let response = await axios.get(url, {withCredentials: true});
				const duplicateLead = response.data.validLead === false;
				if (duplicateLead) {
					setLeadAlreadyExistsMsg(response.data.message);
					logEvent('LeadIsDuplicate', trackingConstants.EVENT_GROUP_ADD_LEAD, trackingConstants.EVENT_SCREEN_ADD_LEAD);
				}
				return duplicateLead;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleGetPhoneNumberClick = async () => {
		setBulkAction(false);
		setCandidateForm(true);
		logEvent('AddLead', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_HOME_TAB);
		trackEvent('add_single_candidate_button_clicked', {});
	};

	const openBulkCandidateUploadForm = () => {
		setBulkCandidateUploadForm(true);
		logEvent('BulkAddLead', trackingConstants.EVENT_GROUP_HOME, trackingConstants.EVENT_SCREEN_HOME_TAB);
		trackEvent('add_multiple_candidates_button_clicked', {});
	}

	const handleBackArrowClick = () => {
		setCandidateForm(false);
		setBulkCandidateUploadForm(false);
		setLeadAlreadyExistsMsg('');
		setPhoneNumber('');
		setCity(DEFAULT);
		setCompany(DEFAULT);
		setCategory(DEFAULT);
		setJobLocation(DEFAULT);
		setJobs([]);
		setClients([]);
		setBulkCandidateFile('');
		setErrorsFile([]);
		setBulkAction(false)
		setBulkActionType('');
	};

	const getClientsAndCompanies = (city) => {
		if (city) {
			homeService.getClientsAndCompanies(city).then((result) => {
				if (result && result.data.viewData.companies) {
					const companyAndCategories = result.data.viewData.companies;
					setClients(Object.keys(companyAndCategories));
					setCompanyCategoryMapping(companyAndCategories);
				}
			});
		}
	};

	const getClientsAndCompaniesForBulkUpload = (city) => {
		if (city) {
			homeService.getClientsAndCompaniesForBulkUpload(city).then((result) => {
				if (result && result.data.viewData.companies) {
					const companyAndCategories = result.data.viewData.companies;
					setClients(Object.keys(companyAndCategories));
					setCompanyCategoryMapping(companyAndCategories);
				}
			});
		}
	};

	const getJobLocations = (city, company) => {
		if (city === 'Work from Home') {
			setJobLocations(['Work from Home']);
		} else {
			if (city && company) {
				homeService.getJobLocations(city, company).then((result) => {
					if (result && result.data.viewData.jobLocations) {
						let jobLocations = result.data.viewData.jobLocations;
						jobLocations = jobLocations.map((c) => c.name);
						setJobLocations(jobLocations);
					}
				});
			}
		}
	};

	const getJobs = (category, city, companyName, jobLocation) => {
		if (city && category && companyName && jobLocation) {
			homeService.getJobs(category, city, companyName, jobLocation).then((result) => {
				if (result && result.data.viewData.jobs) {
					setJobs(result.data.viewData.jobs);
				}
			});
		}
	};

	const handleChangeFile = (file) => {
		setBulkCandidateFile(file);
	}

	const submitBulkUploadForm = () => {
		homeService.postBulkUploadForm(bulkCandidateFile, city, category, company, jobId, jobLocation, jobDemandId)
			.then((result) => {
				alert(result.data);
				console.log('Mohit checkBulk', {data: result.data, bulkCandidateFile, city, category, company, jobId, mitra, jobLocation, jobDemandId})
				trackEvent('bulk_application_created', {
					client_name: company,
					application_city_name: city,
					source_id: mitra.id
					// count
				});
				history.push('/leads');
			})
			.catch((error) => {
				setErrorsFile(error.response.data);
			});
	};

	const downloadErrorFile = () => {
		var CsvString = "";
		errorsFile.map((RowItem) => {
			RowItem.map((ColItem) => {
				CsvString += ColItem;
			});
			CsvString += "\r\n";
		});
		CsvString = "data:application/csv," + encodeURIComponent(CsvString);
		var x = document.createElement("A");
		x.setAttribute("href", CsvString);
		x.setAttribute("download", "error.csv");
		document.body.appendChild(x);
		x.click();
	}

	const submitBasicForm = async () => {
		setDisableSubmit(true);
		const basicInfo = {
			name,
			phoneNumber,
			city,
			category,
			company,
			mitraId: mitra.id,
			jobId,
			jobDemandId,
			jobLocation,
			companyName: company
		};
		if (extraRequiredFields.includes('dlNumber')) {
			basicInfo.dlNumber = dlNumber;
		}
		homeService.postBasicInfoForm(basicInfo).then((response) => {
			if (response.data) {
				delete basicInfo.mitraId
				localStorage.setItem("lead", JSON.stringify(basicInfo))
				logEvent('AddLead', trackingConstants.EVENT_GROUP_ADD_LEAD, trackingConstants.EVENT_SCREEN_ADD_LEAD);
				alert('You have submitted your candidate info. You can check their referral status on the candidates page after some time!');
				history.push("/moreInfo");
			}
		});
	};

	const isBasicFormValid = () => {
		return phoneNumber
			&& city !== DEFAULT
			&& category !== DEFAULT
			&& company !== DEFAULT
			&& !phoneNumberVerified
			&& jobLocation !== DEFAULT
			&& jobId !== ''
			&& (!extraRequiredFields.includes('dlNumber') || dlNumber);
	};

	const isBulkCandidateUploadFormValid = () => {
		return city !== DEFAULT && category !== DEFAULT && company !== DEFAULT && jobLocation !== DEFAULT && jobId !== '' && bulkCandidateFile !== '';
	}
	const changeSelection = (flag) => {
		setSelection(flag);

		clientSelected.map(ele => {
			ele.value = false
		})
		bulkStatusesSelected.map(ele => {
			ele.value = false;
		})
		bulkIVRStatusesSelected.map(ele => {
			ele.value = false;
		})
		bulkResponseSelected.map(ele => {
			ele.value = false;
		})

		// if (!flag) {
		// 	getScheduledIVRList({limit: 5, startDate: dateSelected + ' 00:00:01', endDate: dateSelected + ' 23:59:59'}).then(response => {
		// 		if (response.data.data) {
		// 			setIVRLeads(response.data.data)
		// 			setPrevLeads(response.data.data)
		// 		}
		// 	}).catch(e => {
		// 		console.log(e);
		// 	})
		// }
		// else {
		// 	const data = {
		// 		startDate: dateSelected + ' 00:00:01',
		// 		endDate: dateSelected + ' 23:59:59',
		// 		client: clientSelected[0].backendValue,
		// 		status: bulkStatusesSelected[0].backendValue,
		// 		mitraManagerId: mitra.id
		// 	}
		// 	getBulkUniquenessList(data).then((response) => {
		// 		setLeads(response.data.data)
		// 		setPrevLeads(response.data.data)
		// 	})
		// }


	}
	// const bulkActions = () => {
	// 	if (window.innerWidth > 500) {
	// 		document.getElementById('home').classList.add('blur-bg');
	// 		setBulkAction(true)
	// 	} else {
	// 		setBulkAction(true)
	// 		setCandidateForm(true)
	// 	}
	// }
	// const closeModalUnique = () => {
	// 	setBulkAction(false);
	// 	setBulkActionType('');
	// 	document.getElementById('home').classList.remove('blur-bg');

	// }

	const mapIVRStatus = (status) => {
		let return_value;
		bulkIVRStatusesSelected.map(ele => {
			if (status == ele.backendValue) {
				return_value = ele.label
			}
		})
		return return_value
	}


	const searchCandidate = (e) => {
		if (selection) {
			if (prevLeads.length === 0) {
				setPrevLeads(leads)
			}
			if (e.target.value.length === 0) {
				setLeads(prevLeads);
			}
			const searchResult = prevLeads.filter(ele => {
				return ele.phoneNumber.includes(e.target.value) || ele.firstName.toLowerCase().includes(e.target.value.toLowerCase());
			})
			setLeads(searchResult);
		}
		else {
			if (prevLeads.length === 0) {
				setPrevLeads(leads)
			}
			if (e.target.value.length === 0) {
				setIVRLeads(prevLeads);
			}
			const searchResult = prevLeads.filter(ele => {
				return ele.phoneNumber.includes(e.target.value) || ele.firstName.toLowerCase().includes(e.target.value.toLowerCase());
			})
			setIVRLeads(searchResult);
		}

	}

	const downloadSheet = async () => {
		let data = [];
		let columns;

		if (selection) {
			 columns = [
				{header: 'Name', key: 'name'},
				{header: 'Phone Number', key: 'phoneNumber'},
				{header: 'Status', key: 'status'},
				{header: 'Client', key: 'client'}
			];


			leads.map(ele => {
				data.push({name: ele.candidateName, phoneNumber: ele.candidatePhoneNumber, status: displayBulkUniqueStatus(ele.candidateStatus, ele.preferredClient), client: ele.preferredClient})
			})

			
		}
		else {
			 columns = [
				{header: 'Name', key: 'name'},
				{header: 'Phone Number', key: 'phoneNumber'},
				{header: 'IVRStatus', key: 'ivrStatus'},
				{header: 'Interest', key: 'interest'},
				{header: 'Driving License', key: 'dl'},
				{header: 'Bike', key: 'bike'}
			];


			ivrLeads.map(ele => {
				data.push({name: ele.firstName, phoneNumber: ele.phoneNumber, ivrStatus: mapIVRStatus(ele.status), interest: ele.answer[0], dl: ele.answer[1], bike: ele.answer[2]})
			})

			
		}


		const workSheetName = 'Worksheet-1';
			const workBookName = 'MyWorkBook';
			const myInputId = 'myInput';
			const fileName = 'Report'
			const workbook = new Excel.Workbook();

			try {
				const worksheet = workbook.addWorksheet(workSheetName);
				worksheet.columns = columns;
				worksheet.getRow(1).font = {bold: true};

				data.forEach(singleData => {
					worksheet.addRow(singleData);
				});

				// loop through all of the columns and set the alignment with width.
				worksheet.columns.forEach(column => {
					column.width = column.header.length + 5;
					column.alignment = {horizontal: 'center'};
				});

				worksheet.eachRow({includeEmpty: false}, row => {
					// store each cell to currentCell
					const currentCell = row._cells;

					// loop through currentCell to apply border only for the non-empty cell of excel
					currentCell.forEach(singleCell => {
						// store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
						const cellAddress = singleCell._address;

						// apply border
						worksheet.getCell(cellAddress).border = {
							top: {style: 'thin'},
							left: {style: 'thin'},
							bottom: {style: 'thin'},
							right: {style: 'thin'}
						};
					});
				});
				// write the content using writeBuffer
				const buf = await workbook.xlsx.writeBuffer();

				// download the processed file
				saveAs(new Blob([buf]), `${fileName}.xlsx`);
			} catch (error) {
				console.error('<<<ERRROR>>>', error);
				console.error('Something Went Wrong', error.message);
			} finally {
				// removing worksheet's instance to create new one
				workbook.removeWorksheet(workSheetName);
			}
	}

	const deleteChip = (name, item) => {
		if (name === 'response') {
			const currenArray = [...bulkResponseSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setBulkResponseSelected(currenArray)
		}
		else if (name === 'IVRStatus') {
			const currenArray = [...bulkIVRStatusesSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setBulkIVRStatusesSelected(currenArray)
		}
		else if (name === 'status') {
			const currenArray = [...bulkStatusesSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					ele.value = false;
				}
			})
			setBulkStatusesSelected(currenArray)
		}
		else if (name === 'client') {
			const currenArray = [...clientSelected]
			currenArray.map(ele => {
				if (ele.label === item) {
					console.log(name, item)
					ele.value = false;
				}
			})
			setClientSelcted(currenArray)
		}
		else if (name === 'date') {
			setDateSelected(new Date().toISOString().substr(0, 10))
			document.getElementById("date").value = new Date().toISOString().substr(0, 10)
		}
	}

	const displayBulkUniqueStatus = (status, companyName) => {
		if(companyName.toLowerCase() === 'zomato'){
			if(status.toLowerCase() === 'uploaded'){
				return 'Unique'
			}
			else if(status.toLowerCase() === 'not uploaded'){
				return 'Not Unique'
			}
			if(status.toLowerCase() === 'api failed'){
				return 'Pending'
			}
		}
		else if(companyName.toLowerCase() === 'shadowfax'){
			if(status.toLowerCase() === 'created'){
				return 'Unique'
			}
			else if(status.toLowerCase() === 'not uploaded'){
				return 'Not Unique'
			}
			if(status.toLowerCase() === 'uploaded'){
				return 'Pending'
			}
		}
	}

	if (bulkCandidateUploadForm) {
		return (
			<>
				<div className="Home mainContentZ">
					<h2 className="heading_1 add-candidate">
						<Button id="back-to-home" icon={require('./../arrow_back.svg')}
							onClick={handleBackArrowClick}
						/>
						Bulk Add Candidates
					</h2>
					<h4 className="heading_1 form-sub-heading">Basic Details</h4>
					<form>
						<div className="form_control">
							<h5 className="extraCompanies">The following clients are not supported for bulk upload: Shadowfax, Shadowfax 3PL, Dunzo. Please use the "Add Single Candidate" option for these clients.</h5>
							<div className="input-text-title field-required">City</div>
							<select
								id="basic-form-city"
								name="city"
								onChange={handleCityChangeForBulkUpload}
								defaultValue={DEFAULT}
								ref={register({required: true})}
								value={city}
							>
								<option value={DEFAULT} disabled>
									City
								</option>
								{cities.map((city) => (
									<option className="Dropdown-option" key={city} value={city}>
										{city}
									</option>
								))}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Company / Client</div>
							<select
								id="basic-form-company"
								name="company"
								onChange={handleClientChange}
								defaultValue={DEFAULT}
								ref={register({required: true})}
								value={company}
							>
								<option value={DEFAULT} disabled>
									Company / Client
								</option>
								{clients.map((company) => (
									<option className="Dropdown-option" key={company} value={company}>
										{company}
									</option>
								))}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Job Category</div>
							<select
								id="basic-form-category"
								name="category"
								onChange={handleJobTypeChange}
								defaultValue={DEFAULT}
								ref={register({required: true})}
								value={category}
							>
								<option value={DEFAULT} disabled>Job Category</option>
								{availableCategories.map((category) => {
									return <option key={category} value={category}>{category}</option>
								})
								}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Job Location</div>
							<select
								id="basic-form-job-location"
								name="jobLocation"
								onChange={handleJobLocationChange}
								defaultValue={DEFAULT}
								ref={register({required: true})}
								value={jobLocation}
							>
								<option value={DEFAULT} disabled>Job Location</option>
								{jobLocations.map(jobLocation => {
									return <option key={jobLocation} value={jobLocation}>{jobLocation}</option>
								})
								}
							</select>
						</div>
						{jobs && jobs.length ? (<div className="form_control padding-class"><JobsTable rows={jobs} onJobChange={job => handleJobChange(job.id, job.jobDemandId)}></JobsTable></div>)
							: null}

						<div className="form_control" >
							<div className="input-text-title field-required">Candidate Details</div>
							<input
								id="basic-form-bulk-upload"
								name="file"
								type="file"
								accept=".xlsx"
								ref={register({required: true})}
								onChange={e => handleChangeFile(e.target.files[0])}
							/>
							<h5 className="blue">Headers Name should be [candidateName, candidatePhoneNumber]</h5>
							<h5 className="blue">candidateName, candidatePhoneNumber are mandatory fields</h5>
						</div>
						<div className="form_control">
							{errorsFile.length > 0 ? (
								<h5 className="instruction"><b>Upload failed, <button onClick={() => downloadErrorFile()}>click here</button> to view errors. Reupload the entire file again after correcting the errors.</b></h5>
							) : <></>}
						</div>
						<input id="basic-form-bulk-submit" className="Home" type="button" value="Upload" disabled={!isBulkCandidateUploadFormValid()} onClick={() => {GAEventsTracker("Submit Add Multiple Candidates"); submitBulkUploadForm()}} />
					</form>

				</div>
				<div className="navigation-container">
					<NavigationTab currentTab={0} mitra={mitra} />
				</div>
			</>
		);
	} else if (!candidateForm) {
		return (
			<>
				<div className="Home mainContentZ" id="home">
					<div className="header">
						<div className="header-text">
							<div className="welcome">Welcome</div>
							<span className="mitra-name">{mitra.name}</span>
						</div>
						{
							isAdmin ?
								<div style={{display: 'flex', flexDirection: 'row', textAlign: 'right', marginLeft: 'auto'}}>
									<div style={{display: 'flex'}}>
										<div className="header-search">
											<input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
										</div>
										<div className="search-icon" onClick={() => {if (window.innerWidth < 840) setOpenSearch(!openSearch)}}>
											<i className="fas fa-search"></i>
										</div>
									</div>
									<div className="header-download">
										<button className="download-button" onClick={() => {downloadSheet(); BulkActionGAEventsTracker("DownloadBulkActionSheet");}}>
											<i class="fas fa-download"></i>&nbsp;
											Export Sheets
										</button>
									</div>
								</div>
								: null
						}
					</div>
					{
						openSearch && isAdmin ?
							<div style={{padding: '3px 5px'}}>
								<div className="header-search">
									<input className="search-input-box" type="text" onInput={searchCandidate} placeholder="Search Candidates" />
								</div>
							</div>
							: null
					}
					{
						isAdmin ?
							<>
								<div className="filter-container">
									<div className="filter-set-container">
										<input type='date' id="date" className="Dropdown-control" defaultValue={dateSelected} onChange={(event) => {setDateSelected(event.target.value); setShowDateChip(true)}}></input>
										{
											selection ?
												<>
													<div id="category-filter"
														onClick={() => {
															setCategoryShowFilterPopup(true)
														}}
														className=" Dropdown-control"
													>
														<div className="filter-label">Clients</div>
														<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
													</div>

													<div id="status-filter"
														onClick={() => {
															setStatusShowFilterPopup(true);
														}}
														className="Dropdown-control">
														<div className="filter-label">Status</div>
														<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
													</div>
												</>
												:
												<>
													<div id="category-filter"
														onClick={() => {
															setIVRStatusShowFilterPopup(true)
														}}
														className=" Dropdown-control"
													>
														<div className="filter-label">IVR Status</div>
														<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
													</div>

													<div id="status-filter"
														onClick={() => {
															setResponseShowFilterPopup(true);
														}}
														className="Dropdown-control">
														<div className="filter-label">Response</div>
														<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
													</div>
												</>
										}

									</div>
								</div>
								<div className="Chips-container" style={{padding: '0px 10px'}}>
									{
										showDateChip ?
											<div className="single-chip">
												<span className="chip-text">
													<Moment format="DD MMM YYYY" withTitle>{dateSelected}</Moment>
												</span>
												<span className="chip-cross" onClick={() => {deleteChip('date', dateSelected); setShowDateChip(false)}}> <i className="fas fa-times"></i> </span>
											</div>
											: null
									}
									{
										selection ?
											<>
												{
													clientSelected.map((ele) => {
														if (ele.value) {
															return <div className="single-chip">
																<span className="chip-text">{ele.label}</span>
																<span className="chip-cross" onClick={() => {deleteChip('client', ele.label)}}> <i className="fas fa-times"></i> </span>
															</div>
														}
													})
												}

												{
													bulkStatusesSelected.map((ele) => {
														if (ele.value) {
															return <div className="single-chip">
																<span className="chip-text">{ele.label}</span>
																<span className="chip-cross" onClick={() => {deleteChip('status', ele.label)}}> <i className="fas fa-times"></i> </span>
															</div>
														}
													})
												}
											</>
											: null
									}
									{
										!selection ?
											<>
												{
													bulkIVRStatusesSelected.map((ele) => {
														if (ele.value) {
															return <div className="single-chip">
																<span className="chip-text">{ele.label}</span>
																<span className="chip-cross" onClick={() => {deleteChip('IVRStatus', ele.label)}}> <i className="fas fa-times"></i> </span>
															</div>
														}
													})
												}

												{
													bulkResponseSelected.map((ele) => {
														if (ele.value) {
															return <div className="single-chip">
																<span className="chip-text">{ele.label}</span>
																<span className="chip-cross" onClick={() => {deleteChip('response', ele.label)}}> <i className="fas fa-times"></i> </span>
															</div>
														}
													})
												}
											</>
											: null
									}
								</div>
							</>
							: null
					}
					<div className="row bg-white" style={{margin: '0px', marginTop: '5px'}}>
						<div className="col-12 col-md-4 col-sm-12" style={{textAlign: 'center', margin: 'auto'}}>
							<button style={{width: '95%', padding: '20px', display: 'flex', flexDirection: 'row', margin: '3px auto', marginTop: '5px'}} type="button" className="btn btn-outline-danger" onClick={handleGetPhoneNumberClick}>
								<i className="fas fa-user vertical-middle" style={{width: '10%', marginRight: '4px'}}></i>
								<span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">
									Add Single candidate
								</span>
								<span style={{border: 'solid 1px', borderRadius: '100px', padding: '4px 12px'}}>
									<i className="fas fa-angle-right"></i>
								</span>
							</button>
						</div>

						<div className="col-12 col-md-4 col-sm-12" style={{textAlign: 'center', margin: 'auto'}}>
							<button id="add-multiple-candidate" style={{width: '95%', padding: '20px', display: 'flex', flexDirection: 'row', margin: '3px auto', marginTop: '5px'}} type="button" className="btn btn-outline-danger" onClick={() => {GAEventsTracker("Open Add Multiple Candidates"); openBulkCandidateUploadForm()}}>
								<i className="fas fa-users vertical-middle" style={{width: '10%', marginRight: '4px'}}></i>
								<span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">
									Add Multiple Candidate
								</span>
								<span style={{border: 'solid 1px', borderRadius: '100px', padding: '4px 12px'}}>
									<i className="fas fa-angle-right"></i>
								</span>
							</button>
						</div>

						{
							isAdmin ?
								<div className="col-12 col-md-4 col-sm-12">
									<button style={{width: '95%', padding: '20px', display: 'flex', flexDirection: 'row', margin: '3px auto'}} type="button" className="btn btn-outline-danger" onClick={() => {BulkActionGAEventsTracker("ClickOnBulkActions"); if (window.innerWidth > 550) {setShowSideSheet(true);} else {history.push('/bulk-action')} }}>
										<i className="fas fa-users vertical-middle" style={{width: '10%', marginRight: '4px'}}></i>
										<span style={{width: '75%', textAlign: 'left'}} className="vertical-middle">
											Bulk Actions
											<span className="new-iocn">
												New
											</span>
										</span>
										<span style={{border: 'solid 1px', borderRadius: '100px', padding: '4px 12px'}}>
											<i className="fas fa-angle-right"></i>
										</span>
									</button>
								</div>
								: null
						}

					</div>

					{
						isAdmin ?
							<div className="bg-white" id="exTab2" style={{margin: '0px', width: '100%'}}>
								<div className="row bg-white" style={{margin: '0px', marginTop: '5px'}}>
									<div className="col-md-6 col-6" style={{textAlign: 'left'}}><h6>Lead Management	</h6></div>
									<div className="col-md-6 col-6" style={{textAlign: 'right', color: '#D34612'}} ><span onClick={() => {if(selection) {localStorage.setItem("filters", JSON.stringify({date: dateSelected, client: clientSelected, status: bulkStatusesSelected}) ) ; history.push("/lead-management?tab=bulk-uniqueness")} else {localStorage.setItem("filters", JSON.stringify({date: dateSelected, bulkIVRStatus: bulkIVRStatusesSelected, bulkResponse: bulkResponseSelected}) ) ; history.push("/lead-management?tab=bulk-IVR")}}} style={{cursor: 'pointer'}}>See All</span></div>
								</div>
								<div className="row bg-white" style={{margin: '0px'}}>
									<div className="col-md-6 col-6"><span style={selection ? {
										paddingBottom: '8px',
										borderBottom: '1px solid rgb(211, 70, 18)',
										color: '#D34612',
										cursor: 'pointer'

									} : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {changeSelection(true)}}>Bulk Uniqueness</span></div>
									<div className="col-md-6 col-6"><span style={!selection ? {
										paddingBottom: '8px',
										borderBottom: '1px solid rgb(211, 70, 18)',
										color: '#D34612',
										cursor: 'pointer'
									} : {cursor: 'pointer', color: '#8C8C8C'}} onClick={() => {changeSelection(false)}}>Bulk IVR calls</span></div>
									<div className="col-12 col-md-12" style={{margin: '0px', marginTop: '20px', marginBottom: window.innerWidth >= 800 ? '0px' : '80px'}}>
										<div className="list-scroll-x">
											<table className="table table-responsive">
												<thead>
													<tr style={{backgroundColor: '#FAFAFA'}}>
														<th scope="col">Candidate Name</th>
														<th scope="col">Mobile No.</th>
														<th scope="col">{selection ? 'Candidate Status' : 'IVR status'}</th>
														<th scope="col">{selection ? 'Client Preference' : 'Candidate Response'}</th>
													</tr>
												</thead>
												<tbody>
													{selection ?
														leads.map((lead, index) =>
															<>
																<tr key={index}>
																	<td>{lead.candidateName}</td>
																	<td>{lead.candidatePhoneNumber}</td>
																	<td style={{color: displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient) === 'Unique' ? '#D34612' : displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient) === 'Not Unique' ? '#F5222D' : displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient) === 'Pending' ? '#2F54EB' : '#262626'}}>{displayBulkUniqueStatus(lead.candidateStatus, lead.preferredClient)}</td>
																	<td>{lead.preferredClient}</td>
																</tr>
															</>
														)
														:
														ivrLeads.map((lead, index) =>
															<>
																<tr key={index}>
																	<td>{lead.firstName}</td>
																	<td>{lead.phoneNumber}</td>
																	<td style={{color: statusColorMapping[mapIVRStatus(lead.status)] ? statusColorMapping[mapIVRStatus(lead.status)] : '#000'}}>{mapIVRStatus(lead.status)}</td>
																	<td>
																		{
																			lead.questions.map((q, i) => (
																				<>
																					{q.toLowerCase().search('interest') !== -1 ? <b> Interested  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
																					{q.toLowerCase().search('driving') !== -1 ? <b>  Driving License  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
																					{q.toLowerCase().search('bike') !== -1 ? <b> Bike  -{lead.answer[i] ? lead.answer[i] : ''}<br /></b> : null}
																				</>
																			))
																		}
																	</td>
																</tr>
															</>
														)
													}
												</tbody>
											</table>
											{
												selection && leads.length === 0 ? <div className="leads-error">No leads found</div> : null
											}
											{
												!selection && ivrLeads.length === 0 ? <div className="leads-error">No leads found</div> : null
											}
										</div>
										
										{
											selection && leads.length >= 4 ? 
												<div style={{textAlign: 'center', margin: '10px auto'}}>
													<div className="see-all-button" onClick={() => {localStorage.setItem("filters", JSON.stringify({date: dateSelected, client: clientSelected, status: bulkStatusesSelected}) ) ; history.push("/lead-management?tab=bulk-uniqueness")}}>See All</div>
												</div>
											: null
										}

										{
											!selection && ivrLeads.length >= 4 ? 
												<div style={{textAlign: 'center', margin: '10px auto'}}>
													<div className="see-all-button" onClick={() => {localStorage.setItem("filters", JSON.stringify({date: dateSelected, bulkIVRStatus: bulkIVRStatusesSelected, bulkResponse: bulkResponseSelected})) ; history.push("/lead-management?tab=bulk-IVR")}}>See All</div>
												</div>
											: null
										}
									</div>
								</div>
							</div>
							: null
					}

					{/* <img src = "/image/Mitra-monogram.png" className="mitra-small-logo"></img>
				<h2 className="heading_1" style={{padding: '2rem 0'}}>Hi, {mitra.name}</h2>
  				<div>
					<h4 className="add-candidate-title">Add Candidates</h4>
					<div className="buttons-contained-enabled-copy single-candidate">
						<Button id="add-single-candidate" raised label="Add Single Candidate" onClick={handleGetPhoneNumberClick} />
					</div>
					<div className="buttons-contained-enabled-copy">
						<Button id="add-multiple-candidate" raised label="Add Multiple Candidates" onClick={() => {GAEventsTracker("Open Add Multiple Candidates"); openBulkCandidateUploadForm()}} />
					</div>
				</div>
				{!homeScreenModal ? (
					<div className="otp-popup">
						<div className="otp-popup-inner">
							<div className="icon-body" onClick={closeModal}>
								<img alt="close" src="/image/icon_close.png" style={{ padding: '2rem 2rem 0' }} />
							</div>
							<h2 className="heading_1">
								Hi {mitra.name}, <br />Welcome to Vahan{' '}
							</h2>
							<h3 className="subheading-popup">
								Please Add to Home screen<br /> for easy access
							</h3>
							<div className="btn-home-screen">
								<Button id="add-home-screen" raised label="ADD TO HOME SCREEN" onClick={handleInstallClick} />
							</div>
							<h5 className="footer-popup">It takes very less space on your phone</h5>
						</div>
					</div>
				) : null} */}
				</div >

				<div className="bottom-detail-wrapper">
					<FilterPopup heading="Select Status" visible={showStatusFilterPopup} setShowFilterPopup={setStatusShowFilterPopup} setCategory={setBulkStatusesSelected} list={bulkStatusesSelected} />
					<FilterPopup heading="Select Client" visible={showCategoryFilterPopup} setShowFilterPopup={setCategoryShowFilterPopup} setCategory={setClientSelcted} list={clientSelected} />
					<FilterPopup heading="Select IVR Status" visible={showIVRStatusFilterPopup} setShowFilterPopup={setIVRStatusShowFilterPopup} setCategory={setBulkIVRStatusesSelected} list={bulkIVRStatusesSelected} />
					<FilterPopup heading="Select Response" visible={showResponseFilterPopup} setShowFilterPopup={setResponseShowFilterPopup} setCategory={setBulkResponseSelected} list={bulkResponseSelected} />
				</div>

				<div className="navigation-container">
					<NavigationTab currentTab={0} mitra={mitra} />
				</div>

				<div>
					<BulkActionSidesheet open={showSideSheet} setOpen={setShowSideSheet} />
				</div>
			</>
		)
	} else {
		return (
			<>

				<div className="Home mainContentZ">
					<h2 className="heading_1 add-candidate">
						<Button id="back-to-home" icon={require('./../arrow_back.svg')}
							onClick={handleBackArrowClick}
						/>
						Add Candidate
					</h2>
					<BasicInfoForm mitra={mitra} />
					{/* <form>
						<div className="form_control">
							<div className="input-text-title field-required">Candidate Name</div>
							<input
								id="basic-form-candidate-name"
								label="Candidate Name"
								name="name"
								type="text"
								onChange={handleNameChange}
								placeholder="Candidate Name"
								ref={register({ required: true, minLength: 2 })}
							/>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Candidate Phone number</div>
							<input
								id="basic-form-phone-number"
								label="Phone Number"
								name="phoneNumber"
								type="tel"
								onChange={handleNumberChange}
								placeholder="Candidate Phone Number"
								ref={register({ required: true, minLength: 10, maxLength: 10 })}
							/>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">City</div>
							<select
								id="basic-form-city"
								name="city"
								onChange={handleCityChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={city}
							>
								<option value={DEFAULT} disabled>
									City
								</option>
								{cities.map((city) => (
									<option className="Dropdown-option" key={city} value={city}>
										{city}
									</option>
								))}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Company / Client</div>
							<select
								id="basic-form-company"
								name="company"
								onChange={handleClientChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={company}
							>
								<option value={DEFAULT} disabled>
									Company / Client
								</option>
								{clients.map((company) => (
									<option className="Dropdown-option" key={company} value={company}>
										{company}
									</option>
								))}
							</select>
						</div>
						<div className="form_control" hidden={!extraRequiredFields.includes('dlNumber')}>
							<div className="input-text-title field-required">DL Number</div>
							<input
								id="basic-form-dl-number"
								label="DL Number"
								name="dlNumber"
								type="text"
								onChange={handleDLNumberChange}
								placeholder="Driving License Number"
								ref={register({ required: extraRequiredFields.includes('dlNumber'), minLength: 5 })}
							/>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Job Category</div>
							<select
								id="basic-form-category"
								name="category"
								onChange={handleJobTypeChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={category}
							>
								<option value={DEFAULT} disabled>Job Category</option>
								{	availableCategories.map((category) => {
										return <option key={category} value={category}>{category}</option>})
								}
							</select>
						</div>
						<div className="form_control">
							<div className="input-text-title field-required">Job Location</div>
							<select
								id="basic-form-job-location"
								name="jobLocation"
								onChange={handleJobLocationChange}
								defaultValue={DEFAULT}
								ref={register({ required: true })}
								value={jobLocation}
							>
								<option value={DEFAULT} disabled>Job Location</option>
								{	jobLocations.map(jobLocation => {
										return <option key={jobLocation} value={jobLocation}>{jobLocation}</option>})
								}
							</select>
						</div>
						{phoneNumberVerified === true ? (
							<div className="form_control">
								<span className="errMsg">{leadAlreadyExistsMsg}</span>
							</div>
						) : null}
						{jobs && jobs.length ? (<div className="form_control padding-class"><JobsTable rows={jobs} onJobChange={job => handleJobChange(job.id, job.jobDemandId)}></JobsTable></div>)
						:null}
						<input id="basic-form-submit" className="Home" type="button" value="Next" disabled={!isBasicFormValid() || disableSubmit} onClick={submitBasicForm} />
					</form> */}

				</div>
				<div className="navigation-container">
					<NavigationTab currentTab={0} mitra={mitra} />
				</div>
			</>
		);
	}
};

export default Home;