import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as amplitude from 'amplitude-js';
import "formiojs/dist/formio.full.min.css"
import "formiojs/dist/formio.full.min.js"
import "bootstrap/dist/css/bootstrap.min.css"

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
// eslint-disable-next-line no-undef
window.Moengage = moe({
	app_id: 'A7BUJ1RRESMUMVUOLEOLJ1XD',
	debug_logs: process.env.NODE_ENV === 'production' ? 0 : 1,
  });
ReactDOM.render(
		<App />,
	document.getElementById('root')
);

serviceWorker.register();
