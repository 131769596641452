import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { validateSession } from "../../service/loginService"
import NavigationTab from "../common/NavigationTab.component"
import MainLogoHeader from "../header/MainLogoHeader"
import "./../../css/accountPage.css"
import useGAEventsTracker from '../../service/useGSEventsTracker';
import useMoengage from '../../hooks/useMoengage';

const accountPagesEnum = {
    uploadDocuments: "Update Bank Passbook/Cheque",
    userManagement: "User Management",
    termsAndConditions: "Terms And Conditions",
    trainingSession: "View/Edit Training Session"
}

const AccountPage = (props) => {

    const history = useHistory()
	const AccountGAEventsTracker = useGAEventsTracker("Accounts")
    const [createMoengageUser, trackEvent] = useMoengage();

    const trackMoengageEvent = (event) => {
        trackEvent(event, {});
    }
    useEffect(() => {
        trackMoengageEvent('account_hub_viewed');
		validateSession()
			.then((response) => {

			})
			.catch((error) => history.push('/'));
    })


    const handleAccountPages = (route,el) => {
        if(route === "termsAndConditions"){
            trackMoengageEvent('terms_conditions_opened');
            return window.open("/termsAndConditions")
        }
        if(accountPagesEnum[el] === "User Management"){
            AccountGAEventsTracker("Open User Management")
        }
        if (route === 'uploadDocuments') trackMoengageEvent('update_bank_document_viewed');
        if (route === 'userManagement') trackMoengageEvent('user_management_section_viewed');
        history.push(`/account/${route}`)
    }

    return (
        <React.Fragment>
            <div className="AccountPage mainContentZ">
                <MainLogoHeader />
                <div  style={{}} className="list-container">
                    {Object.keys(accountPagesEnum).map(el => {
                        return (
                        <div className=" row" key={el}>
                            <div className="col"></div>
                            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 bg_gray" onClick={handleAccountPages.bind(this, el)}>
                                <h5>{accountPagesEnum[el]}</h5> 
                                <h5>{`>`}</h5>
                            </div>
                            <div className="col"></div>
                        </div>
                        )
                    })}
                </div>
            </div>
            <div className="navigation-container">
                <NavigationTab currentTab={4} />
            </div>
        </React.Fragment>
    )
}

export default AccountPage