import { useRef } from 'react';

const useMoengage = () => {
  const maxTry = useRef(1);

  const createMoengageUser = obj => {
    if (window.Moengage) {
      window.Moengage.add_unique_user_id(obj.id);
      window.Moengage.add_user_attribute('Full Name',obj.name);
      window.Moengage.add_user_attribute('Email Id',obj.email);
      window.Moengage.add_user_attribute('Mobile Number',obj.phoneNumber);
      window.Moengage.add_user_attribute('userType','Mitra Leader');
      window.Moengage.add_user_attribute('Role',obj.role);
      window.Moengage.add_user_attribute('dateOfJoining',obj.createdAt);
      window.Moengage.add_user_attribute('acquisitionSource',obj.acquisitionSource);
      window.Moengage.add_user_attribute('homeCity',obj.homeCity);
      // window.Moengage.add_user_attribute('bankAccountDetailsAvailable',obj.createdAt);
      window.Moengage.add_user_attribute('mitraId',obj.id);
      window.Moengage.add_user_attribute('managerMitraId',obj.managerMitraID);
    } else if (maxTry.current < 3) {
      maxTry.current += 1;
      setTimeout(() => {
        createMoengageUser(obj.id);
      }, 1000);
    }
  };

  const trackEvent = (eventName, eventAttributes = {}) => {
    if (window.Moengage) {
      window.Moengage.track_event(eventName, eventAttributes);
    } else if (maxTry.current < 3) {
      maxTry.current += 1;
      setTimeout(() => {
        trackEvent(eventName, eventAttributes);
      }, 1000);
    }
  };

  return [createMoengageUser, trackEvent];
};

export default useMoengage;
