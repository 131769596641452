import axios from 'axios';


export const getBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/listBulkUploadCandidates`;
    return axios.get(url, {params: data, withCredentials: true});
};

export const uploadBulkUniquenessList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/postBulkUniquenessData`;
    return axios.post(url, data, {withCredentials: true});
};

export const getpopularCities = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/form-io/getPremiumJobsCities`;
    return axios.get(url, {withCredentials: true});
};
export const bulkIVRUpload = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/bulkIVRUpload`;
    return axios.post(url, data, {withCredentials: true});
}
export const getAudioFile = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getIVRCallAudio`;
    return axios.get(url, {withCredentials: true, params: data});
}
export const getScheduledIVRList = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getScheduledIVRCallsByMitra`;
    return axios.get(url, {withCredentials: true, params: data});
}