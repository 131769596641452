import React, {useReducer, useState} from 'react';
import {useHistory} from 'react-router-dom';
import '../../css/lead.css';
import trackingConstants from '../../constants/trackingConstants';
import {AdditionalFormsURLEnum} from '../../utils/utility';
import Modal from 'react-modal';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import moment from "moment";
import useMoengage from '../../hooks/useMoengage';
import { getAllStatuses } from './../../service/reportService'




const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


Modal.setAppElement('#root')

const LeadCard = ({lead, setShowDetail, setSelectedLead, fromSearch, setShowContactBottomSheet}) => {

  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

  const history = useHistory();
  const documents = ['Aadhar', 'PAN', 'RC', 'Bank account'];
  const [createMoengageUser, trackEvent] = useMoengage();
  // const handleOpenAndAddDocuments = async () => {
  //   if (lead && lead.id && lead.mitraId) {
  //     logEvent(
  //       'ClickDocumentsPage',
  //       trackingConstants.EVENT_GROUP_VIEW_LEAD,
  //       fromSearch
  //         ? trackingConstants.EVENT_SCREEN_SEARCH
  //         : trackingConstants.EVENT_SCREEN_CANDIDATE
  //     );
  //     window.open(process.env.REACT_APP_JF1_BASE_URL + `/documents?l=${lead.id}&m=${lead.mitraId}`);
  //   } else {
  //     console.log('Lead and Mitra id not found');
  //   }
  // };
  // const openForm = (lead) => {
  // localStorage.setItem('lead', JSON.stringify(lead))
  // setDynamicForm(true);
  // };

  // const checkClientAdditionalForm = (clientName) => {
  //   return clientName in AdditionalFormsURLEnum;
  // };

  const diffBetweenTwoDates = (first, last) => {
    const diff = last.diff(first, "days")
    if (diff < 10) {
      return `0${diff}`
    }
    else return diff;
  }

    const checkAtRisk = (lead) => {
      let data = false
      if(lead && lead.latestMilestone){
          const DateObj = new Date();
          const differnceInDates = (DateObj.getTime() - Date.parse(lead.activatedAt)) / (1000 * 3600 * 24);
          if (differnceInDates >= 7 && differnceInDates <= 14 && lead.latestMilestone === 'activation_date'  && lead.companyName && (lead.companyName.toLowerCase() === "shadowfax" || lead.companyName.toLowerCase() === "shadowfax_3pl")) {
            data =  true
          }
      }
      return data
    }

  const mapStatusToCompany = (companyName, status) => {
    if (status !== null && companyName !== null) {
      if (companyName.toLowerCase() === "shadowfax" || companyName.toLowerCase() === "shadowfax_3pl") {
        if (status.toLowerCase() === 'uploaded') return 'Application Created'
        if (status.toLowerCase() === 'activation_date') return 'Activated'
        if (status.toLowerCase() === 'created') return 'Candidate Unique'
        if (status.toLowerCase() === 'not uploaded') return 'Candidate Not Unique'
        if (status.toLowerCase() === 'first_date_of_work') return '1st Trip Completed'
        if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'

      }
      else if (companyName.toLowerCase() === "zomato") {
        if (status.toLowerCase() === 'api failed' || status.toLowerCase() === '') return 'Application Created'
        if (status.toLowerCase() === 'uploaded') return 'Candidate Unique'
        if (status.toLowerCase() === 'not uploaded') return 'Candidate Not Unique'
        if (status.toLowerCase() === 'activation_date') return 'Activated'
        if (status.toLowerCase() === 'first_date_of_work') return '1st Trip Completed'
        if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'
      }
      else if (companyName.toLowerCase() === 'rapido') {
        if (status.toLowerCase() === 'activation_date') return 'Activated'
        if (status.toLowerCase() === 'first_date_of_work') return '1st Trip Completed'
        if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'
      }
      else if (companyName.toLowerCase() === 'uber' || companyName.toLowerCase() === 'uber moto' || companyName.toLowerCase() === 'uber' || companyName.toLowerCase() === 'uber auto') {
        if (status.toLowerCase() === 'activation_date') return 'Activated'
        if (status.toLowerCase() === 'first_date_of_work') return '1st Trip Completed'
        if (status.toLowerCase() === 'uploaded') return 'Candidate Unique'
        if (status.toLowerCase() === 'not uploaded') return 'Candidate Not Unique'
        if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'

      }
      else if (companyName.toLowerCase() === 'dunzo') {
        if(status.toLowerCase() === 'activation_date') return 'Activated'
        if(status.toLowerCase() === 'first_date_of_work') return '1st Trip Completed'
        if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'
        return 'Application Created'
      }
      else if (companyName.toLowerCase() === 'swiggy') {
        if(status.toLowerCase() === 'activation_date') return 'Activated'
        if(status.toLowerCase() === 'first_date_of_work') return '1st Trip Completed'
        if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'
        return 'Application Created'
      }
      if(status.toLowerCase() === 'application_created') return 'Application Created'
    }
    else{
      return "Application Created";
    }
  }
  const viewAdditionalForm = (lead) => {
    localStorage.setItem('lead', JSON.stringify(lead))
    if (lead.scheduledInterviewId) {
      history.push('/moreInfo')

    } else {
      history.push({
        pathname: '/moreInfo',
        route: 'jobRecommendationPage'
      })
    }


  }

  const trackViewDetails = () => {
    trackEvent('view_details_button_clicked', {});
  }

  const viewDetailsHandler = () => {
    getAllStatuses({scheduleInterviewId: lead.scheduledInterviewId, companyName: lead.companyName})
    .then(response => {
      const newLeadData = {
        name: lead.name,
        phoneNumber: lead.phoneNumber,
        userId: lead.userId,
        jobs: [
          {status: response.data.status.length !== 0 ? response.data.status : [{title: null, StatusTimeStamp: null}], companyName: lead.companyName, createdAt: lead.createdAt} 
        ]
      }
      setShowDetail(true);
      setSelectedLead(newLeadData)
    })

  }

  return (
    <div className="detail-body-container">
      <div className="detail-element-body">
        <div className="card-container">
          <div style={{width: '80%'}}>
            <div className="contact-details-container">
              <div className="contact-details">
                <div className="lead-name">
                  {lead.name}
                </div>
                <div className="phone-container" onClick={(e) => {
                  if (window.innerWidth > 700) {
                    viewDetailsHandler()
                  }
                  else {
                    setShowContactBottomSheet(true);
                  }
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                  setSelectedLead(lead);
                  e.stopPropagation();
                }}>
                  <i className="fas fa-phone phone-icon"></i>
                  <div className="phoneNumber">{lead.phoneNumber}</div>
                </div>
              </div>
            </div>
            <div className="job-container">
              {
                // lead ? lead.jobs ?
                // lead.jobs.map((job, index) => (
                  <div className="single-job-container">
                    <div className="client-logo">
                      <img className="imgResponse" src={"/image/" + lead.companyName + ".png"} />
                    </div>
                    {
                      !isNaN(diffBetweenTwoDates(moment(lead.activatedAt), moment()))
                        ?
                        <div className="onboarding-schedule">
                          <div className="diffInDate">
                            Last Updated: {diffBetweenTwoDates(moment(lead.activatedAt), moment())} days ago.
                          </div>
                          <div className="leadsCardStatus d-flex flex-row" style={{color: '#D34612'}}>
                            {mapStatusToCompany(lead.companyName, lead.latestMilestone)}
                            {
                              // index == lead.jobs.length - 1 && AdditionalFormsURLEnum[job.companyName] && !job.scheduledInterviewId ? <>
                              //   <span className="complete-application-button upload-Icon" style={{background: 'rgb(211, 70, 18)', color: '#fff', 'marginRight': '5px'}} onClick={() => {viewAdditionalForm(job)}}>
                              //     <i className="fas fa-upload" aria-hidden="true"></i>
                              //     <span className="btntext">Complete Application</span>
                              //   </span>
                              // </>
                              //   : null
                            }
                          </div>
                        </div>
                        :
                        <div className="onboarding-schedule">
                          <div className="diffInDate">
                            Last Updated: {diffBetweenTwoDates(moment(lead.activatedAt), moment())} days ago.
                          </div>
                          <div className="leadsCardStatus d-flex flex-row" style={{color: '#D34612'}}>
                            Application Created
                            {
                              // index == lead.jobs.length - 1 && AdditionalFormsURLEnum[job.companyName] && !job.scheduledInterviewId ? <>
                              //   <span className="complete-application-button upload-Icon" onClick={() => {viewAdditionalForm(job)}} style={{background: 'rgb(211, 70, 18)', color: '#fff'}}>
                              //     <i className="fas fa-upload" aria-hidden="true"></i>
                              //     <span className="btntext">Complete Application</span>
                              //   </span>
                              // </>
                              //   : null
                            }
                          </div>
                        </div>
                    }
                  </div>
                // ))
                //   : null : null
              }
            </div>
          </div>
          <div className="user-image-view-detail" style={{width: '20%'}}>
            <div className="user-image-container">
              <div className="user-image">
                {lead.name.charAt(0).toUpperCase()}
                {
                  checkAtRisk(lead) ? <div className="counter counter-lg"><i className="fas fa-exclamation"></i></div> : null
                }
              </div>
            </div>
            <div className="d-flex justify-content-end" style={{marginTop: 'auto'}}>
              {/* {
              lead.jobs.length && AdditionalFormsURLEnum[lead.jobs[lead.jobs.length - 1].companyName] && !lead.jobs[lead.jobs.length - 1 ].scheduledInterviewId? <div onClick={() => {viewAdditionalForm(lead.jobs[lead.jobs.length - 1])}} className="complete-application-button upload-Icon-mobile" style={{background: 'rgb(211, 70, 18)', color: '#fff'}}>
                <i className="fas fa-upload" aria-hidden="true"></i>
              </div> : null */}
              

              <div className="view-details-container"
                onClick={(e) => {
                  // setShowDetail(true);
                  viewDetailsHandler()
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                  setSelectedLead(lead);
                  GAEventsTracker('OpenViewDetails')
                  e.stopPropagation();
                }}>
                <i className="fas fa-chevron-right right-arrow"></i>
                <button className="view-details-button" onClick={trackViewDetails}>View Details</button>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div>
        {lead.createdAtForSearch ? (
          <div className="lead-added">Added - {lead.createdAtForSearch}</div>
        ) : null}
      </div>
    </div>
  );
};
export default LeadCard;
