import axios from 'axios';

export const getDashboardData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getPerformanceDashboardData` ;
    return axios.get(url, {
        params: data ,
        withCredentials: true
    }, );
}

export const getCandidatesData = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/reports/getCandidates` ;
    return axios.get(url, {
        params: data ,
        withCredentials: true
    }, );
}

export const getAllStatuses = (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/getAllStatusOfLead` ;
    return axios.post(url, data ,{ withCredentials: true });
}