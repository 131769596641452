import React, {useState, useEffect, useRef} from 'react';
import {TextField} from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import {Button} from '@rmwc/button';
import '@rmwc/button/styles';
import '../../css/login.css';
import '../../css/signup.css';
import * as loginService from '../../service/loginService';
import {logEvent} from '../../service/tracking';
import ClipLoader from 'react-spinners/ClipLoader';
import trackingConstants from '../../constants/trackingConstants';
import {NavLink, Redirect} from "react-router-dom";
import {Form} from "react-formio"
import {checkMitraDuplicacy, mitraSignup, postOnboardingQuestions, sendOTPToPhone} from '../../service/signupService';
import TraingSessionForm from './TraingSessionForm';
import GTMTracking from '../tracking/GTMTracking.component';
import ReactPixel from 'react-facebook-pixel';
import OnboardingQuestions from './OnboardingQuestions';
const SignupPage = (props) => {
	const [signupData, setSignupData] = useState('');
	const [otpSent, setOtpSent] = useState(null);
	const [otpVerified, setOtpVerified] = useState(null);
	const [otp, setOtp] = useState('');
	const [mitra, setMitra] = useState(null);
	const [validSession, setValidSession] = useState(false);
	const [loading, setLoading] = useState(true);
	const [otpTextErrorMsg, setOtpTextErrorMsg] = useState(null);
	const [cookiesAccept, setCookiesAccept] = useState(true);
	const onboardingQuestions = useRef([])
	const pixelOption = {
		autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
		debug: false, // enable logs
	};
	ReactPixel.init('903648060275990', {}, pixelOption);
	useEffect(() => {
		logEvent('OpenSignupScreen', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		loginService
			.validateSession()
			.then((response) => {
				setValidSession(true);
				setLoading(false);
			})
			.catch((error) => setLoading(false));
		//initially check if cookies are already accepted by user
		let consent = localStorage.getItem('cookiesAccepted');
		if (consent && consent === 'YES') {
			ReactPixel.track('SingupPage');
			setCookiesAccept(true)
			ReactPixel.grantConsent();

		} else if (consent && consent === 'NO') {
			setCookiesAccept(null)
			ReactPixel.revokeConsent();

		} else {
			setCookiesAccept(false)
			ReactPixel.revokeConsent();
		}
	}, []);

	const verifyOTP = async (e) => {
		logEvent('Verifying OTP', trackingConstants.EVENT_GROUP_LOGIN, trackingConstants.EVENT_SCREEN_LOGIN);
		try {
			const signupRes = await mitraSignup(otp, signupData);
			if (signupRes.data.otpVerified) {
				postOnboardingQuestions(onboardingQuestions.current, signupRes.data.mitra).then().catch()
				setMitra(signupRes.data.mitra)
				setOtpVerified(true)
				if (cookiesAccept)
					ReactPixel.trackCustom('successfulLeadCreation ', {});
			}
			else {
				setOtpTextErrorMsg("Wrong or Expired OTP")
				setOtpVerified(false)
			}
		} catch (error) {
			setOtpTextErrorMsg("Something Went Wrong")
			setOtpVerified(false)
		}
	};

	const handleOTPChange = (event) => {
		setOtp(event.target.value);
	};

	const sendOTP = async (phoneNumber) => {
		await sendOTPToPhone(phoneNumber)
		setOtpSent(true)
		if (cookiesAccept) {
			ReactPixel.track('OTPVerifyPage');
			ReactPixel.trackCustom('successfulSignUpFormSubmission', {});
		}

	};

	const handleResendOTP = () => {
		sendOTP(signupData.phoneNumber);
	};

	const handleBackArrowClick = () => {
		setOtpSent(false);
		setOtp('');
		setOtpVerified(null);
		setOtpTextErrorMsg(null)
	};

	const signupFormOptions = {
		hooks: {
			beforeSubmit: async (submission, next) => {
				try {
					const res = await checkMitraDuplicacy(submission.data.phoneNumber)
					if (res.data.isMitra) {
						return next([{message: "Phone number is already registered!"}])
					}
					setSignupData(submission.data)
				} catch (error) {
					next([{message: error.response ? error.response.data.message : "Service Unavailable"}])
				}
			}
		}
	}

	
	//function for handling user consent response 
	const handleCookieConsent = (consent) => {
		if (consent) {
			ReactPixel.grantConsent();
			localStorage.setItem('cookiesAccepted', 'YES')
			window.location.reload();
			setCookiesAccept(true)
		} else {
			ReactPixel.revokeConsent();
			localStorage.setItem('cookiesAccepted', 'NO')
			setCookiesAccept(null)
		}
	}
	return <> {loading ? (
		<div className="loader_clip">
			<ClipLoader size={100} />
		</div>
	) : validSession ? (
		<Redirect to="/"></Redirect>
	) : otpVerified ?
		<React.Fragment> <TraingSessionForm mitra={mitra} /> <GTMTracking />  </React.Fragment> : (
			<div className="Signup">
				{/* <h2 className="heading_1">Vahan Mitra</h2>
			<h3 className="subheading">Helping people find jobs</h3> */}
				<img className="vahan-logo" alt="vahan" style={{marginTop: '2.5rem'}} src="/image/mitra-logo.png" />
				<h3 className="subheading">by</h3>
				<img className="vahan-logo vahan" alt="vahan" src="/image/vahan-final-logo-black.png" />
				<p className="mt-5 mt-md-0">By signing up, you will receieve more details regarding the Mitra Leader Program</p>
				{otpSent ? (
					<div>
						<Button className="arrow-back" icon={require('../../arrow_back.svg')} onClick={handleBackArrowClick} />
						<div className="main-login-container">
							<div className="cards-elements-surface-filled" id="OpenMobileVerification">
								<GTMTracking />
								{otpVerified === false ? (
									<h5 className="OtpMessage marginTopClass">OTP SMSed to {signupData.phoneNumber}</h5>
								) : (
									<h5 className="OtpMessage">OTP SMSed to {signupData.phoneNumber}</h5>
								)}
								<div>
									<TextField
										id="enter-otp"
										label="Enter OTP"
										outlined
										placeholder="Enter OTP"
										minLength={4}
										maxLength={4}
										pattern="[0-9]*"
										value={otp}
										onChange={handleOTPChange}
									/>
									<h5 className="errorMsg">{otpTextErrorMsg}</h5>
									<div className="buttons-contained-enabled-copy">
										<Button id="log-in" className="OTPsubmission" raised label="SIGN UP" onClick={(e) => verifyOTP(e)} />
									</div>
								</div>
							</div>
						</div>
						<h5 style={{margin: '0'}} className="OtpMessage">
							Didn't get SMS?
							<Button id="resend-otp" className="btn_tertiary" label="Resend OTP" onClick={handleResendOTP} />{' '}
						</h5>
					</div>
				) : (
					<div className="main-login-container">
						<div className="signup_form" id="OpenSignupScreen">
							{/* <div className="cards-elements-surface-filled"> */}
							{/* {phoneNumberVerified === false ? <h3 className="marginTopClass login-font">LOG IN</h3> : <h3 className="login-font">LOG IN</h3>}
						<div>
							<TextField
								id= "enter-mobile"
								label="Enter Mobile Number"
								outlined
								className="surface"
								placeholder="+91"
								minLength={10}
								maxLength={10}
								pattern="[0-9]*"
								value={phoneNumber}
								onChange={handleNumberChange}
							/>
							<h5 className="errorMsg">{phoneNumberTextErrorMsg}</h5>
							<div className="buttons-contained-enabled-copy">
								<Button id= "get-otp" raised label="Get OTP" onClick={handleGetOTPClick} />
							</div>
						</div> */}
							{ !signupData ? 
								<Form src="https://wsjzvhriwjszprr.form.io/mitrasignupform" options={signupFormOptions} />
								: <OnboardingQuestions signupData={signupData} sendOTP={sendOTP} onboardingQuestions={onboardingQuestions} />
							}
							<GTMTracking />
							{ !signupData && <p>Existing user? <NavLink to="/" > Login here</NavLink></p> }
						</div>
					</div>
				)}
				<div className="illustration" />
			</div>
		)}
		{/* HTML code for user consent agreement  */}
		{cookiesAccept === false ? <div id="myModal" className="modal-cookie">

			<div className="modal-cookie-content">

				<p><b>We uses cookies to provide you with the experience.By proceeding , you consent to all cookies for purpose of enhancing website experience and analytics.</b></p>
				<div style={{textAlign: 'center'}}>
					<button type="button" onClick={() => {handleCookieConsent(true)}} className="btn btn-outline-success" >Accept</button><br />
					<button type="button" onClick={() => {handleCookieConsent(false)}} className="btn btn-outline-danger" style={{margin: '10px'}}>Decline</button>
				</div>

			</div>

		</div> : null}
	</>
};

export default SignupPage;
