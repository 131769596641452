import React, {useState} from 'react'
import SideSheet from 'react-side-sheet';
import BulkAction from './Bulk-action.component';

const BulkActionSidesheet = ({open, setOpen}) => {

    const [header, setHeader] = useState('Bulk Actions')
    const [parentHeader, setParentHeader] = useState('Bulk Actions')


    return (
        <SideSheet isOpen={open} onDismiss={() => setOpen(false)} max-width='300px'>
            <div style={{display: 'flex', flexDirection:'row', padding: '15px 30px'}}>
                <div className="bulk-action-heading">
                    {header}
                </div>
                <i className="fas fa-times" style={{width: '10%', textAlign: 'right', verticalAlign: 'middle', margin: 'auto'}} onClick={() => {setOpen(false)}}></i>
            </div>
            <hr style={{color: '#000000', margin: '10px 30px'}} />
            <BulkAction setParentHeader={setHeader} />
        </SideSheet>
    )
}

export default BulkActionSidesheet;
