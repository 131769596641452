import React, { useState, useEffect } from 'react';
import useGAEventsTracker from '../service/useGSEventsTracker';
import * as jobDemandsService from '../service/jobDemandsService';
import * as loginService from '../service/loginService';
import {useHistory} from 'react-router-dom';
import NavigationTab from './common/NavigationTab.component';
// import JobDemandsTable from './JobDemandsTable.component';
import {Button} from '@rmwc/button';
import useMoengage from '../hooks/useMoengage';
const DEFAULT = 'DEFAULT';


const JobDemandsPage = () => {
	const [ mitra, setMitra ] = useState();
	const [ jobDemands, setJobDemands ] = useState([]);
	const [ validJobDemandsCities, setValidJobDemandsCities ] = useState([]);
	const [ validJobDemandsClients, setValidJobDemandsClients ] = useState([]);
	const [ validJobDemandsVerticals, setValidJobDemandsVerticals ] = useState([]);
	const [ city, setCity ] = useState(DEFAULT);
	const [ client, setClient ] = useState(DEFAULT);
	const [ vertical, setVertical ] = useState(DEFAULT);
	const [selected, setSelected] = useState({});
	const [selectedLocalities, setSelectedLocalities] = useState({});
	const [originalLocalities, setOriginalLocalities] = useState({});
	const [viewLocations, setViewLocations] = useState(true);
	const [moreLocalities, setMoreLocalities] = useState(false);
	const [createMoengageUser, trackEvent] = useMoengage();
	const history = useHistory();
	const JobsGAEventsTracker = useGAEventsTracker("jobs")


	useEffect(() => {
		const getJobDemandsData = async (e) => {
			try {
				jobDemandsService.getJobDemandsData().then((result) => {
					if (result && result.data.viewData) {
						if (result.data.viewData.allJobDemands) {
							setJobDemands(result.data.viewData.allJobDemands);
						}
						if (result.data.viewData.validJobDemandsCities) {
							setValidJobDemandsCities(result.data.viewData.validJobDemandsCities);
						}
						if (result.data.viewData.validJobDemandsClients) {
							setValidJobDemandsClients(result.data.viewData.validJobDemandsClients);
						}
						if(result.data.viewData.validJobDemandsVerticals) {
							setValidJobDemandsVerticals(result.data.viewData.validJobDemandsVerticals);
						}
					}
				});
			} catch (error) {
				console.log(error);
			}
		};
		getJobDemandsData();
		trackEvent('demand_hub_viewed', {});
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
			})
			.catch((error) => history.push('/'));
	}, []);

	const getJobDemandsOnFiltersChange = async (city, client, vertical) => {
		try {
			jobDemandsService.getJobDemandsOnFiltersChange(city, client, vertical).then((result) => {
				if (result && result.data.viewData.allJobDemands) {
					setJobDemands(result.data.viewData.allJobDemands);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleCityChange = (e) => {
		setCity(e.target.value);
		getJobDemandsOnFiltersChange(e.target.value, client, vertical);
	};

	const handleClientChange = (e) => {
		setClient(e.target.value);
		getJobDemandsOnFiltersChange(city, e.target.value, vertical);
	};

	const handleVerticalChange = (e) => {
		setVertical(e.target.value);
		getJobDemandsOnFiltersChange(city, client, e.target.value);
	};

	const handleViewLocations = (selected) => {
		setClient(selected.companyName);
		setVertical(selected.vertical);
		setViewLocations(false);
		setMoreLocalities(false);
		setSelected(selected);
	};

	const handleMoreLocalities = (selected) => {
		setMoreLocalities(true);
		setOriginalLocalities(selected);
		setSelectedLocalities(selected);
	};

	const searchLocality = (e) => {
		if (e.target.value !== '') {
			const searchResult = originalLocalities.data.filter(ele => {
				return ele.locality.toLowerCase().includes(e.target.value.toLowerCase());
			})
			const search= {city: selectedLocalities.city, data: searchResult};
			setSelectedLocalities(search);
		} else {
			setSelectedLocalities(originalLocalities);
		}
	}
	return (
		<div>
			<div className="Home mainContentZ">
			{viewLocations ?
			<div className="jobs-page">
			<h4>Jobs</h4>
			<div style={{background: '#F0F2F5'}}>
				<select id="client-filter" value={client} onChange={(e) => {handleClientChange(e); JobsGAEventsTracker("ClickOnJobsClientFilter")}} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Client</option>
					{validJobDemandsClients.map((client) => (
						<option className="Dropdown-option" value={client.companyName}>
							{client.companyName}
						</option>
					))}
				</select>
				<select id="vertical-filter" value={vertical} onChange={(e) => {handleVerticalChange(e); JobsGAEventsTracker("ClickOnJobsJobTypeFilter")}} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Job Type</option>
					{validJobDemandsVerticals.map((vertical) => (
						<option className="Dropdown-option" value={vertical.vertical}>
							{vertical.vertical}
						</option>
					))}
				</select>
			</div>
			<div className="tabs-container">
				<button id="allCandidates"
					className="jobs-section active-tab tab"
					>
					Job Requirements
				</button>
			</div>
			<div className="form_control job-demands-table">
				{jobDemands.map((v, i) => (
				<div className="detail-element-body">
					<div className="card-container" style={{ display:'inline-block', textAlign:'left'}} key={i}>
						<div className="client-logo">
							<img className="imgResponse" src={"/image/" + v.companyName + ".png"} />
						</div>
						<div>
							<h6><br></br>Type of Job</h6>
							<h6 className="content">{v.jobType + ' - ' + v.vertical}</h6>
						</div>
						<div>
							<h6><br></br>Salary Range</h6>
							<h6 className="content">{v.maxSalary > v.baseSalary ? 'Rs.'+ v.baseSalary+ ' - Rs.' + v.maxSalary : 'Rs.' +
							v.maxSalary} {v.requirements && v.requirements.jobPreference ? v.requirements.jobPreference : ''}</h6>
						</div>
						<div>
							<h6><br></br>Requirements</h6>
							<h6 className="content" style={{lineHeight: '1.3rem'}}>{v.requirements && v.requirements.education ? v.requirements.education.map((v,i) => (<li>{v}</li>)) : '' }</h6>
							<h6 className="content" style={{lineHeight: '1.3rem'}}>{v.requirements && v.requirements.englishProficiency ? v.requirements.englishProficiency.map((v,i) => (<li>{v}</li>)) : '' }</h6>
							<h6 className="content" style={{lineHeight: '1.3rem'}}>{v.requirements && v.requirements.experience ? v.requirements.experience.map((v,i) => (<li>{v}</li>)) : '' }</h6>
						</div>
						<button className="viewLocations" onClick={(e) => {handleViewLocations(v); JobsGAEventsTracker("ViewLocations")}}>
							<i className="fas fa-search"></i>
							&nbsp;&nbsp;View Locations
						</button>
					</div>
				</div>
				))}
			</div>
			</div>
			:
			<div className="jobs-page">
				{!viewLocations && !moreLocalities ?
				<div className="header">
					<div className="header-text">
						<span className="small-size-screen" style={{cursor: 'pointer'}}  onClick={(e) => {setViewLocations(true)}} ><i className="fas fa-arrow-left">&nbsp;&nbsp;</i></span>
						<span className="big-screen">
							<span onClick={(e) => {setViewLocations(true)}} style={{cursor: 'pointer'}}>Jobs</span> &nbsp;&nbsp;
							<i className="fas fa-chevron-right" style={{color: '#666565'}}></i>&nbsp;&nbsp;
						</span>
						<span className="requirements-title">Job Requirements</span>
					</div>
				</div>
				: (!viewLocations && moreLocalities ?
				<div className="header">
					<div className="header-text">
						<span className="small-size-screen" style={{cursor: 'pointer'}}  onClick={(e) => {setMoreLocalities(false)}} ><i className="fas fa-arrow-left">&nbsp;&nbsp;</i></span>
						<span className="big-screen">
						<span onClick={(e) => {setViewLocations(true)}} style={{cursor: 'pointer'}}>Jobs</span> &nbsp;&nbsp;
							<i className="fas fa-chevron-right" style={{color: '#666565'}}></i>&nbsp;&nbsp;
						<span onClick={(e) => {setMoreLocalities(false)}} style={{cursor: 'pointer'}}>Jobs Requirements</span> &nbsp;&nbsp;
							<i className="fas fa-chevron-right" style={{color: '#666565'}}></i>&nbsp;&nbsp;
						</span>
						<span className="requirements-title">Localities</span>
					</div>
					<div className="header-search">
					<input className="search-input" onInput={searchLocality} type="text" placeholder="Search" />
					<div className="search-icon">
						<i className="fas fa-search"></i>
					</div>
				</div>
				</div>
				: null)}
				<div className="row" style={{background: '#F0F2F5'}}>
				<div className="col" style={{padding: '0', background: '#F0F2F5'}}>
				<h6 className="allCities"><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;All Cities</h6> </div>
				<div className="col" style={{textAlign: 'right', padding: '0', background: '#F0F2F5'}}><select id="city-filter" value={city} onChange={(e) => {handleCityChange(e); JobsGAEventsTracker("ClickOnJobsCityFilter")}} className="button Dropdown-control">
					<option value={DEFAULT} disabled>Filter</option>
					{validJobDemandsCities.map((city) => (
						<option className="Dropdown-option" value={city.city}>
							{city.city}
						</option>
					))}
				</select>
				</div>
				</div>
				<div style={{textAlign: 'left', paddingLeft: '1rem' }}>
					<div className="row" style={{background: 'white'}}>
					<div className="col-5 client-logo" style={{textAlign: 'left'}}>
						<img className="imgResponse" src={"/image/" + selected.companyName + ".png"} />
					</div>
					<div className="col-7" style={{textAlign: 'left'}}>
						<h6 className="content">{selected.jobType} - {selected.vertical}</h6>
						<h6>Salary - {selected.maxSalary > selected.baseSalary ? 'Rs.'+ selected.baseSalary+ ' - Rs.' + selected.maxSalary : 'Rs.' +
								selected.maxSalary}</h6>
					</div>
					</div>

					{!viewLocations && !moreLocalities ?
					selected.demands.map((v, i) => (
					<div><br></br><br></br>
					<h6 className="cityHeading"><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;<b className="bold">{v.city}</b> - {v.data.length} {v.data.length > '1' ? 'Localities' : 'Locality'}</h6>
						<div className="row">
							<div className="col" style={{background: '#F0F0F0'}}><b className="bold">{v.data[0].locality}</b></div>
							<div className="col">Part Time <br></br> {selected.requirements && selected.requirements.jobPreference ? (selected.requirements.jobPreference === 'Part time' ? <b className="bold">{v.data[0].numOpenings}</b> : '-') : '-' }</div>
							<div className="col">Full Time <br></br> {selected.requirements && selected.requirements.jobPreference ? (selected.requirements.jobPreference === 'Full time' ? <b className="bold">{v.data[0].numOpenings}</b> : '-') : '-' }</div>
							<div className="col">Both Part Time and Full Time <br></br> {selected.requirements && selected.requirements.jobPreference ? (selected.requirements.jobPreference === 'Both Full time and Part time' ? <b className="bold">{v.data[0].numOpenings}</b> : '-') : '-' }</div>
						</div>
						{v.data.length > '1' ?
						<button className="moreLocalities" onClick={(e) => {handleMoreLocalities(v); JobsGAEventsTracker("ClickOnLocalityButton")}}>
							&nbsp;&nbsp;+ {v.data.length-1} more {v.data.length-1 > '1' ? 'localities' : 'locality'}
						</button>
						 : null}
					</div>
					)) : (!viewLocations && moreLocalities ?
						<div><br></br><br></br>
						<h6 className="cityHeading"><i className="fas fa-map-marker-alt"></i> &nbsp;&nbsp;<b className="bold">{selectedLocalities.city}</b> - {selectedLocalities.data.length} {selectedLocalities.data.length > '1' ? 'Localities' : 'Locality'}</h6>
						{selectedLocalities.data.map((m,n) => (
							<div className="row">
								<div className="col" style={{background: '#F0F0F0'}}><b className="bold">{m.locality}</b></div>
								<div className="col">Part Time <br></br> {selected.requirements && selected.requirements.jobPreference ? (selected.requirements.jobPreference === 'Part time' ? <b className="bold">{m.numOpenings}</b> : '-') : '-' }</div>
								<div className="col">Full Time <br></br> {selected.requirements && selected.requirements.jobPreference ? (selected.requirements.jobPreference === 'Full time' ? <b className="bold">{m.numOpenings}</b> : '-') : '-' }</div>
								<div className="col">Both Part Time and Full Time <br></br> {selected.requirements && selected.requirements.jobPreference ? (selected.requirements.jobPreference === 'Both Full time and Part time' ? <b className="bold">{m.numOpenings}</b> : '-') : '-' }</div>
							</div>
						))}
						</div>
					: null)}

			</div>
			</div>
		}
		</div>
		<div className="navigation-container">
			<NavigationTab currentTab={2} />
		</div>
	</div>
	);
};

export default JobDemandsPage;
