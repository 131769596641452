import React, {useState, useEffect} from 'react';
import Lead from './Lead.component';
import NavigationTab from '../common/NavigationTab.component';
import Contact from '../leads/Contact.component';
import FilterPopup from './Filter-popup.component';
import CandidateProfilePopup from './candidateProfilePopup.component'
import '../../css/lead.css';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import * as loginService from '../../service/loginService';
import {useHistory} from 'react-router-dom';
// import {logEvent} from '../../service/tracking';
// import trackingConstants from '../../constants/trackingConstants';
import {CSVLink} from "react-csv"
import Moment from 'react-moment';
import moment from "moment";
import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import useMoengage from '../../hooks/useMoengage';
import mitras from './../../constants/downloadAllCandidates'
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

import * as homeService from '../../service/homeService';

const LeadsPage = () => {

	const getfirstDateOfMonth = () => {
		const date = moment();
		const firstDate = moment().startOf('month');
		return firstDate;
	}

	const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AllCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const CriticalCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")

	const [leads, setLeads] = useState([]);
	const [prevLeads, setPrevLeads] = useState([]);
	const [disableFilters, setDisableFilters] = useState(false);
	const [startDate, setStartDate] = useState(getfirstDateOfMonth());
	const [endDate, setEndDate] = useState(moment());
	const [atRiskStartDate, setAtRiskStartDate] = useState(moment().subtract(30, 'd').format("YYYY-MM-DD"));
	const [atRiskEndDate, setAtRiskEndDate] = useState(moment().subtract(7, 'd').format("YYYY-MM-DD"));
	const [focusedInput, setFocusedInput] = useState(null);
	const [showCategoryFilterPopup, setCategoryShowFilterPopup] = useState(false);
	const [showStatusFilterPopup, setStatusShowFilterPopup] = useState(false);
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [atRiskTab, setAtRiskTab] = useState(false);
	const [breadcrumb, setbreadcrumb] = useState('All Candidates')
	const [atRiskLeads, setAtRiskLeads] = useState([]);
	const [showDateChip, setShowDateChip] = useState(false)
	const [showWarningMessageAtRisk, setShowWarningMessageAtRisk] = useState(true)
	const [showContactBottomSheet, setShowContactBottomSheet] = useState(false)
	const [showBreadcrumb, setShowBreadcrumb] = useState(false);
	const [clientStatus, setClientStatus] = useState([]);

	const [extraSearch, setExtraSearch] = useState(false)
	const [downloadAllCandidates, setDownloadAllCandidates] = useState(false);
	const [clientSelected, setClientSelcted] = useState([
		{label: 'Zomato', value: false},
		{label: 'Dunzo', value: false},
		{label: 'Swiggy', value: false},
		{label: 'Shadowfax', value: false},
		{label: 'Shadowfax_3PL', value: false},
		{label: 'Uber', value: false},
		{label: 'Rapido', value: false},
		{label: 'Loadshare', value: false},
		{label: 'Porter', value: false},
		{label: 'Uber_3PL', value: false},
		{label: 'Rapido_3PL', value: false},
	])
	const [statusesSelected, setStatusesSelected] = useState([
		{label: 'Application Created', value: false, storedStatus: 'Uploaded'},
		{label: 'Candidate Not Unique', value: false, storedStatus: 'Not Uploaded'},
		{label: 'Candidate Unique', value: false, storedStatus: 'Created'},
		{label: 'Activated', value: false, storedStatus: 'Activated'},
		{label: '1st Trip Completed', value: false, storedStatus: 'Work Started'},
		// {label: 'Document Pending', value: false},
		// {label: 'Rejected', value: false},
		// {label: 'Expired', value: false},
		// {label: 'Interested', value: 'Interested'},
		// {label: 'Created', value: 'Created'},
		// {label: 'Not Interested', value: 'Not Interested'},
		// {label: 'First Trip Completed', value: 'Order Started'},
		// {label: 'Invalid', value: 'Invalid'},
		// {label: 'Inactive', value: 'Inactive'},
		// {label: 'Onboarded', value: 'Onboarded'},
		// {label: 'Expired', value: 'Expired'},
		// {label: 'Onboarded', value: 'Joined'},
		// {label: 'Registered', value: 'Registered'},
		// {label: 'Declined', value: 'Declined'},
		// {label: 'Pending for Referral', value: 'undefined'},
		// {label: 'Signup', value: 'Signup'},
		// {label: 'Application', value: 'Application'},
		// {label: 'Qualified lead', value: 'Qualified lead'},
		// {label: 'App downloaded', value: 'App downloaded'},
		// {label: 'Profile completed', value: 'Profile completed,'},
		// {label: 'Training completed', value: 'Training completed'},
		// {label: 'VKYC completed', value: 'VKYC completed'},
		// {label: 'BGV Pending', value: 'BGV Pending'},
		// {label: 'BGV rejected', value: 'BGV rejected'},
		// {label: 'BGV Passed', value: 'BGV Passed'},
		// {label: 'First Milestone', value: 'First Milestone'},
		// {label: 'Second Milestone', value: 'Second Milestone'},
		// {label: 'Third Milestone', value: 'Third Milestone'},
	]);
	const history = useHistory();
	const [createMoengageUser, trackEvent] = useMoengage();
	useEffect(() => {
		breadcrumb === 'Critical Candidates' ? trackEvent('critical_candidates_page_viewed', {}) : trackEvent('candidates_page_viewed', {});
		window.setTimeout(function () {
			if (window.location.href.indexOf('/leads') > -1) {
				window.location.reload();
			}
		}, 500000);

		let ClientList = [];
		let statusList = [];

		clientSelected.map((client) => {
			if (client.value === true) {
				ClientList.push(client.label.toLowerCase());
			}
			return null;
		});

		statusesSelected.map((single_status) => {
			if (single_status.value === true) {
				statusList.push(single_status.label);
			}
			return null;
		});


		if (ClientList.length === 0 && statusList.length === 0) {
			setShowBreadcrumb(false)
		}

		homeService.getClientStatus().then((result) => {
			setClientStatus(result.data.data)
		});
		loginService
			.validateSession()
			.then((response) => {
				setMitra(response.data.mitra);
				console.log(response)
				mitras.map(single_id => {
					if(response.data.mitra.id === single_id){
						setDownloadAllCandidates(true);
					}
				})
				addFiltersData(ClientList, moment(startDate).format("YYYY-M-D"), moment(endDate).add(1, 'days').format("YYYY-M-D"), statusList);
			})
			.catch((error) => history.push('/'));
	}, [atRiskTab, clientSelected, statusesSelected, startDate, endDate, atRiskStartDate, atRiskEndDate])

	const addFiltersData = (category, startDate, endDate, status) => {
		const arr = []
		const data = new FormData();
		if (!atRiskTab) {
			if (startDate === 'Invalid date' && endDate === 'Invalid date') {
				data.append('startDate', getfirstDateOfMonth().format("YYYY-M-D"))
				data.append('endDate', moment().add(1, 'days').format("YYYY-M-D"));
			}
			else {
				if (startDate == '' || startDate === 'Invalid date') {
					data.append('startDate', getfirstDateOfMonth().format("YYYY-M-D"))
				}
				else {
					data.append('startDate', startDate);
				}
				if (endDate === 'Invalid date') {
					data.append('endDate', moment(startDate).add(1, 'days').format("YYYY-M-D"));
				}
				else {
					data.append('endDate', endDate);
				}
			}
		}
		else {
			data.append('startDate', atRiskStartDate);
			data.append('endDate', atRiskEndDate);
		}
		data.append('categoryOrClient', JSON.stringify(category));
		data.append('status', JSON.stringify(arr));
		data.append('withJobs', true);
		let url = process.env.REACT_APP_BACKEND_URL + '/addFilters';
		setLoaded(false);
		let response = axios.post(url, data, {withCredentials: true});
		response.then(function (result) {
			if (result.data.leads) {
				if (!atRiskTab) {
					modifyLeadArray(result.data.leads, status)
					setDisableFilters(false)
					document.getElementById('category-filter').style.background = '#FFF'
					document.getElementById('status-filter').style.background = '#FFF'
					document.getElementById('category-filter').style.color = 'rgba(0, 0, 0, 0.87)'
					document.getElementById('status-filter').style.color = 'rgba(0, 0, 0, 0.87)'
				}
				else if (atRiskTab) {
					document.getElementById('category-filter').style.background = '#F5F5F5'
					document.getElementById('status-filter').style.background = '#F5F5F5'
					document.getElementById('category-filter').style.color = 'rgba(0, 0, 0, 0.25)'
					document.getElementById('status-filter').style.color = 'rgba(0, 0, 0, 0.25)'
					modifyLeadArray(result.data.leads, arr)
					setDisableFilters(true)
				}
				setLoaded(true);
				return result;
			} else {
				setLeads([]);
			}
		});
		return false;
	};


	// const getFiltersData = (mitra) => {
	// 	try {
	// 		const data = new FormData();
	// 		data.append('month', currentMonth);
	// 		data.append('withJobs', true);
	// 		let url = process.env.REACT_APP_BACKEND_URL + '/getFilters';
	// 		let response = axios.post(url, data, {withCredentials: true});
	// 		response.then(function (result) {
	// 			if (result) {
	// 				// setLeadsForStatusFilter(result.data.viewData.leads);
	// 				setLeads(result.data.viewData.leads);
	// 				setLoaded(true);
	// 				const Category = result.data.viewData.category;
	// 				const totalCategories = Category.map((c) => c.jobType);
	// 				// setCategories(totalCategories);
	// 			}
	// 		});
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// 	return false;
	// };

	// const getStatuses = (month, category) => {
	// 	const data = new FormData();
	// 	data.append('month', month);
	// 	data.append('categoryOrClient', category);
	// 	let url = process.env.REACT_APP_BACKEND_URL + '/getStatuses';
	// 	setLoaded(false);
	// 	let response = axios.post(url, data, {withCredentials: true});
	// 	response.then(function (result) {
	// 		if (result.data.statuses) {
	// 			let statuses = result.data.statuses;
	// 			statuses = statuses.map((c) =>
	// 				c.value === 'Not Uploaded' ?
	// 					c.label = 'Not Unique'
	// 					: c.value === 'Expired' ?
	// 						c.label = 'Lead Expired'
	// 						: c.value === 'Uploaded' ?
	// 							c.label = 'Unique'
	// 							: c.value === 'Joined' ?
	// 								c.label = 'Onboarded'
	// 								: c.value === 'Order Started' ?
	// 									c.label = 'First Trip Completed'
	// 									: c.label = c.label
	// 			)
	// 			// setStatuses(result.data.statuses);
	// 			setLoaded(true);
	// 			return result;
	// 		}
	// 	});
	// 	return false;
	// };

	// const handleJobTypeChange = (e) => {
	// 	setClientSelcted(e.target.value);
	// 	logEvent('FilterCandidatesByCategory', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE, {categorySelected: e.target.value});
	// addFiltersData(e.target.value, moment(startDate).format("YYYY-MM-DD") , moment(endDate).format("YYYY-MM-DD") , status);

	// addFiltersData(e.target.value, moment(startDate).format("YYYY-MM-DD") , moment(endDate).format("YYYY-MM-DD") , status, completeStatus);
	// getStatuses(month, e.target.value);
	// category, multi select
	// if(e.length > 0) {
	// let totalLeads = [];
	// const selectedCategories = e;
	// logEvent('FilterCandidatesByCategory',
	// 	trackingConstants.EVENT_GROUP_VIEW_LEAD,
	// 	trackingConstants.EVENT_SCREEN_CANDIDATE,
	// 	{categorySelected: selectedCategories.map(category => category.value)});
	// for(let i = 0; i < selectedCategories.length; i++) {
	// 	if(selectedCategories[i].value === 'Show All') {
	// 		setLeads(leadsForStatusFilter);
	// 		return;
	// 	} else {
	// 		for(let j = 0; j < leadsForStatusFilter.length; j++) {
	// 			if(leadsForStatusFilter[j].categories.includes(selectedCategories[i].value)) {
	// 				if(totalLeads.indexOf(leadsForStatusFilter[j]) === -1){
	// 					totalLeads.push(leadsForStatusFilter[j]);
	// 				}
	// 			} else if(leadsForStatusFilter[j].currentCompany === selectedCategories[i].value){
	// 				if(totalLeads.indexOf(leadsForStatusFilter[j]) === -1){
	// 					totalLeads.push(leadsForStatusFilter[j]);
	// 				}
	// 			}
	// 		}
	// 		setLeads([]);
	// 		setLeads(totalLeads);
	// 		totalLeads = [];
	// 	}
	// }
	// }
	// };

	const deleteChip = (name, item) => {
		if (name === 'status') {
			let newStatus = [...statusesSelected]
			newStatus.map((single_status, index) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setStatusesSelected(newStatus)
		}
		else if (name === 'category') {
			let newCategory = [...clientSelected]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setClientSelcted(newCategory)
		}
		else if (name === 'date') {
			if (!atRiskTab) {
				setStartDate(getfirstDateOfMonth())
				setEndDate(moment())
			}
			else {
				setStartDate(moment().subtract(13, 'd'));
				setEndDate(moment().subtract(7, 'd'))
			}
		}
	}



	// const handleStatusChange = (e) => {
	// 	logEvent('FilterCandidatesByStatus', trackingConstants.EVENT_GROUP_VIEW_LEAD, trackingConstants.EVENT_SCREEN_CANDIDATE);
	// 	const new_status = [...status];
	// 	setStatus(e.target.value);
	// };

	// const handleDocumentStatusChange = (e) => {
	// 	if (leadsForStatusFilter.length > 0 && e.length > 0) {
	// 		const totalLeads = [];
	// 		const selectedStatus = e;
	// 		logEvent('FilterCandidatesByDocuments',
	// 			trackingConstants.EVENT_GROUP_VIEW_LEAD,
	// 			trackingConstants.EVENT_SCREEN_CANDIDATE,
	// 			{documentsSelected: selectedStatus.map(status => status.value)});
	// 		for (let i = 0; i < selectedStatus.length; i++) {
	// 			if (selectedStatus[i].value === 'Show All') {
	// 				// setLeads(leadsForStatusFilter);
	// 				modifyLeadArray(leadsForStatusFilter)

	// 				return;
	// 			} else {
	// 				const filterImage = selectedStatus[i].value;
	// 				for (let j = 0; j < leadsForStatusFilter.length; j++) {
	// 					if (filterImage === 'Bank') {
	// 						if (!leadsForStatusFilter[j].documents.includes('Bank Passbook Image') &&
	// 							!leadsForStatusFilter[j].documents.includes('Bank Passbook or Cancelled Cheque Image') &&
	// 							!leadsForStatusFilter[j].documents.includes('Bank Cancelled Cheque Image')) {
	// 							if (totalLeads.indexOf(leadsForStatusFilter[j]) === -1) {
	// 								totalLeads.push(leadsForStatusFilter[j]);
	// 							}
	// 						}
	// 					} else {
	// 						if (!leadsForStatusFilter[j].documents.includes(filterImage)) {
	// 							if (totalLeads.indexOf(leadsForStatusFilter[j]) === -1) {
	// 								totalLeads.push(leadsForStatusFilter[j]);
	// 							}
	// 						}
	// 					}
	// 				}
	// 				// setLeads(totalLeads);
	// 				modifyLeadArray(totalLeads);
	// 			}
	// 		}
	// 	}
	// };

	const handleActiveTabStatus = (active, inactive) => {
		document.getElementById(inactive).style.color = "rgba(0, 0, 0, 0.85)"
		document.getElementById(inactive).style.borderBottomColor = "#F0F0F0"
		document.getElementById(active).style.fontWeight = 'normal'
		document.getElementById(active).style.color = "#D34612"
		document.getElementById(active).style.fontWeight = 'bold'
		document.getElementById(active).style.borderBottomColor = "#D34612"
		setStatusesSelected([]);
		setClientSelcted([]);
	}

	// code for CSV generator
	const headers = [
		{label: "Candidate Name", key: "Candidate Name"},
		{label: "Phone no.", key: "Phone no."},
		{label: "Client Name of the application", key: "Client Name of the application"},
		{label: "Application Date", key: "Application Date"},
		{label: "Activation Date", key: "Activation Date"}
	];

	let data = atRiskLeads.map(lead => {
		const localData = {
			"Candidate Name": lead.name.toUpperCase(),
			"Phone no.": lead.phoneNumber,
			"Client Name of the application": "Shadowfax",
			"Application Date": lead.jobs[0].createdAtForGrouping,
			"Activation Date": moment(lead.jobs[0].status[0].StatusTimeStamp).format("DD MMM")
		}
		return localData;
	})

	const CSVDATA = {
		headers: headers,
		data: data,
		fileame: 'At_risk_applications.csv'
	};

	// csv generator ending


	//excel generator

	const handleSheet = async () => {
        const columns = [
            { header: 'Name', key: 'name' },
            { header: 'Phone Number', key: 'phoneNumber' },
            { header: 'Client Name', key: 'clientName' },
            { header: 'Status', key: 'candidateStatus' },
            { header: 'Status Date', key: 'latestStatusTimestamp' },
            { header: 'Team Member Name', key: 'teamMemberName' },
        ];

        let data = [];

        leads.map(lead => {
			console.log(lead)
			lead.jobs.map(single_job =>{
				let status;
				if(single_job.companyName.toLowerCase() === 'shadowfax' || single_job.companyName.toLowerCase() === 'shadowfax_3pl'){
					if(single_job.status[0].title.toLowerCase() === 'not uploaded') {status = 'Not Unique'}
					if(single_job.status[0].title.toLowerCase() === 'created') {status = 'Unique'}
					if(single_job.status[0].title.toLowerCase() === 'uploaded') {status = 'Application Created'}
				}
				if(single_job.companyName.toLowerCase() === 'zomato'){
					if(single_job.status[0].title.toLowerCase() === 'not uploaded') {status = 'Not Unique'}
					if(single_job.status[0].title.toLowerCase() === 'uploaded') {status = 'Unique'}
					if(single_job.status[0].title.toLowerCase() === 'api failed') {status = 'Application Created'}
				}
				if(single_job.status[0].title === null) {status = 'Application Created'}
				data.push({name: lead.name, phoneNumber: lead.phoneNumber, clientName: single_job.companyName, candidateStatus: status, latestStatusTimestamp: single_job.status[0].StatusTimeStamp ? moment(single_job.status[0].StatusTimeStamp).format("DD MMM YYYY") : moment(single_job.createdAt).format("DD MMM YYYY"), teamMemberName: mitra.name})
			})
          })

          const workSheetName = 'Worksheet-1';
            const workBookName = 'MyWorkBook';
            const myInputId = 'myInput';
            const fileName = 'Report'
          const workbook = new Excel.Workbook();

        try {
            const worksheet = workbook.addWorksheet(workSheetName);
            worksheet.columns = columns;
            worksheet.getRow(1).font = { bold: true };

            data.forEach(singleData => {
                worksheet.addRow(singleData);
              });

              worksheet.eachRow({ includeEmpty: false }, row => {
                // store each cell to currentCell
                const currentCell = row._cells;
        
                // loop through currentCell to apply border only for the non-empty cell of excel
                currentCell.forEach(singleCell => {
                  // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                  const cellAddress = singleCell._address;
        
                  // apply border
                  worksheet.getCell(cellAddress).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                  };
                });
              });
              // write the content using writeBuffer
                const buf = await workbook.xlsx.writeBuffer();

                // download the processed file
                saveAs(new Blob([buf]), `${fileName}.xlsx`);
                } catch (error) {
                console.error('<<<ERRROR>>>', error);
                console.error('Something Went Wrong', error.message);
                } finally {
                // removing worksheet's instance to create new one
                workbook.removeWorksheet(workSheetName);
                }
            }

	//excel ending

	// const documentStatuses = [
	// 	{label: 'Show All', value: 'Show All'},
	// 	{label: 'Missing DL', value: 'DL Image'},
	// 	{label: 'Missing PAN', value: 'PAN Image'},
	// 	{label: 'Missing Aadhaar', value: 'Aadhaar Image'},
	// 	{label: 'Missing Bank document', value: 'Bank'},
	// 	{label: 'Missing Resume', value: 'Resume'},
	// 	{label: 'Missing RC Image', value: 'Vehicle RC Image'}
	// ];

	const modifyLeadArray = (leads, statusList) => {
		let leadsCopy = [...leads]
		let leadsWithStatusClubedArray = [];

		// starting code for at-risk applications

		// block 1: making the status field an array
		for (let index = 0; index < leadsCopy.length; index++) {
			// if(!leadsCopy[index].scheduledInterviewId && (leadsCopy[index].companyName.toLowerCase() !== 'shadowfax' || leadsCopy[index].companyName.toLowerCase() !== 'shadowfax_3pl')){
			// 	continue;
			// }
			leadsCopy[index].status = [{title: leadsCopy[index].status, StatusTimeStamp: leadsCopy[index].activatedAt}]
		}
		// ending block 1

		//block 2: merging all the status belonging to same application
		let index = 0, index2 = index + 1;
		for (index; index <= leadsCopy.length - 1; index++) {
			index2 = index + 1;
			for (index2; index2 < leadsCopy.length; index2++) {
				if (leadsCopy[index].userId === leadsCopy[index2].userId && leadsCopy[index].companyName === leadsCopy[index2].companyName) {
					leadsCopy[index].status.push(leadsCopy[index2].status[0])
					leadsCopy.splice(index2, 1)
					index2--;
				}
			}

			leadsCopy[index].status.sort((a, b) => new Date(b.StatusTimeStamp) - new Date(a.StatusTimeStamp))
			leadsWithStatusClubedArray.push({name: leadsCopy[index].name, userId: leadsCopy[index].userId, phoneNumber: leadsCopy[index].phoneNumber, jobs: [leadsCopy[index]]})
		}
		if (leadsCopy && leadsCopy[index - 1] && leadsCopy[index - 1].phoneNumber !== leadsCopy[index2 - 1].phoneNumber) {
			leadsWithStatusClubedArray.push({name: leadsCopy[index].name, userId: leadsCopy[index].userId, phoneNumber: leadsCopy[index].phoneNumber, jobs: [leadsCopy[index]]})
		}
		//ending block 2

		//atrisk
		if (atRiskTab) {
			let atRiskLeads = []
			leadsWithStatusClubedArray.map((lead) => {
				lead.jobs.map(single_job => {
					const leadRegisterDate = moment(single_job.status[0].StatusTimeStamp);
					let const_startDate, const_endDate;
					if (startDate === 'Invalid date' || endDate === 'Invalid date') {
						const_startDate = moment().subtract(13, 'd');
						const_endDate = moment().subtract(7, 'd')
					}
					else if (endDate === null) {
						const_endDate = moment().subtract(7, 'd')
					}
					else {
						const_startDate = startDate;
						const_endDate = endDate;
					}
					if (leadRegisterDate.isBetween(moment(const_startDate).add(-1, "days"), moment(const_endDate)) && single_job && single_job.displayInUI && single_job.status && single_job.status[0].title && single_job.status[0].title.toLowerCase() === 'activated' && single_job.companyName && (single_job.companyName.toLowerCase() === 'shadowfax' || single_job.companyName.toLowerCase() === 'shadowfax_3pl')) {
						atRiskLeads.push((lead));
					}
				})

			})

			setLeads(atRiskLeads);
			setPrevLeads(atRiskLeads);
			return
		}
		//atrisk

		// block 3: filtering wrt status selected
		if (statusList.length > 0) {
			let status_filtered_leads = [];
			leadsWithStatusClubedArray.map(lead => {
				for (let i = 0; i < statusList.length; i++) {
					if (statusList[i] === 'Application Created') {
						if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Uploaded' && lead.jobs[0].companyName && (lead.jobs[0].companyName.toLowerCase() === 'shadowfax' || lead.jobs[0].companyName.toLowerCase() === 'shadowfax_3pl')) {
							status_filtered_leads.push(lead);
						}
						else if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'API Failed' && lead.jobs[0].companyName && lead.jobs[0].companyName.toLowerCase() === 'zomato') {
							status_filtered_leads.push(lead);
						}
						else if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === null) {
							status_filtered_leads.push(lead);
						}
					}
					else if (statusList[i] === 'Candidate Unique') {
						if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Created' && (lead.jobs[0].companyName.toLowerCase() === 'shadowfax' || lead.jobs[0].companyName.toLowerCase() === 'shadowfax_3pl')) {
							status_filtered_leads.push(lead);
						}
						else if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Uploaded' && lead.jobs[0].companyName.toLowerCase() === 'zomato') {
							status_filtered_leads.push(lead);
						}
					}
					else if (statusList[i] === 'Candidate Not Unique' && lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Not Uploaded') {
						status_filtered_leads.push(lead);
					}
					else if (statusList[i] === 'Activated' && lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Activated') {
						status_filtered_leads.push(lead);
					}
					else if (statusList[i] === '1st Trip Completed' && lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Work Started') {
						status_filtered_leads.push(lead);
					}
				}
			})
			leadsWithStatusClubedArray = status_filtered_leads;
		}

		// ending block 3

		// block 4: to club all the application of a single lead
		index = 0; index2 = index + 1;
		for (index; index < leadsWithStatusClubedArray.length - 1; index++) {
			index2 = index + 1;
			// if (statusList.length === 0) {
			for (index2; index2 < leadsWithStatusClubedArray.length; index2++) {
				if (leadsWithStatusClubedArray[index].userId === leadsWithStatusClubedArray[index2].userId) {
					for (let i = 0; i < leadsWithStatusClubedArray[index2].jobs.length; i++) {
						leadsWithStatusClubedArray[index].jobs.push(leadsWithStatusClubedArray[index2].jobs[i]);
					}
					leadsWithStatusClubedArray.splice(index2, 1)
					index2--;
				}
			}
			// }
			// else {
			// 	statusList.map(single_status => {
			// 		if (leadsWithStatusClubedArray[index].jobs[0].status[0].title === single_status.label) {
			// 			for (index2; index2 < leadsWithStatusClubedArray.length; index2++) {
			// 				if (leadsWithStatusClubedArray[index].userId === leadsWithStatusClubedArray[index2].userId && leadsWithStatusClubedArray[index2].jobs[0].status[0].title === single_status.label) {
			// 					for (let i = 0; i < leadsWithStatusClubedArray[index2].jobs.length; i++) {
			// 						leadsWithStatusClubedArray[index].jobs.push(leadsWithStatusClubedArray[index2].jobs[i]);
			// 					}
			// 					leadsWithStatusClubedArray.splice(index2, 1)
			// 					index2--;
			// 				}
			// 			}
			// 		}

			// 	})
			// }
			// for (index2; index2 < leadsWithStatusClubedArray.length; index2++) {
			// 	if (leadsWithStatusClubedArray[index].userId === leadsWithStatusClubedArray[index2].userId) {
			// 		for (let i = 0; i < leadsWithStatusClubedArray[index2].jobs.length; i++) {
			// 			leadsWithStatusClubedArray[index].jobs.push(leadsWithStatusClubedArray[index2].jobs[i]);
			// 		}
			// 		leadsWithStatusClubedArray.splice(index2, 1)
			// 		index2--;
			// 	}
			// }
			leadsWithStatusClubedArray[index].jobs.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
		}

		//ending block 4

		//temp logic for showing only cross referral incomplete applications
		leadsWithStatusClubedArray.forEach((ele, ind) => {
			let jobs = ele.jobs.filter((ele) => {
				return (ele.companyName.toLowerCase() === 'zomato') || (ele.status[ele.status.length - 1].title != null && ele.companyName.toLowerCase() === 'shadowfax' && !ele.scheduledInterviewId && ele.status[ele.status.length - 1].title !== 'Uploaded' && ele.status[ele.status.length - 1].title !== 'Not Uploaded');
			});
			if (jobs.length !== 2) {
				ele.jobs = ele.jobs.filter((ele) => {
					return ele.scheduledInterviewId;
				});
				if (!ele.jobs.length) {
					leadsWithStatusClubedArray[ind] = null;
				}
			} else {
				ele.jobs = ele.jobs.filter((ele) => {
					return ele.scheduledInterviewId || (ele.companyName.toLowerCase() == 'zomato' || ele.companyName.toLowerCase() == 'shadowfax');
				});
			}
		});

		leadsWithStatusClubedArray = leadsWithStatusClubedArray.filter(ele => ele)

		if (!atRiskTab) {
			// if (statusList.length === 0) {
				setLeads(leadsWithStatusClubedArray);
				setPrevLeads(leadsWithStatusClubedArray);

			// }
			// else {
			// 	const final_leads_array = [];
			// leadsWithStatusClubedArray.map(lead => {
			// 	for (let i = 0; i < statusList.length; i++) {
			// 		if (statusList[i] === 'Application Created') {
			// 			if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Uploaded' && lead.jobs[0].companyName && (lead.jobs[0].companyName.toLowerCase() === 'shadowfax' || lead.jobs[0].companyName.toLowerCase() === 'shadowfax_3pl')) {
			// 				final_leads_array.push(lead);
			// 			}
			// 			else if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'API Failed' && lead.jobs[0].companyName && lead.jobs[0].companyName.toLowerCase() === 'zomato') {
			// 				final_leads_array.push(lead);
			// 			}
			// 			else if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === null) {
			// 				final_leads_array.push(lead);
			// 			}
			// 		}
			// 		else if (statusList[i] === 'Candidate Unique') {
			// 			if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Created' && (lead.jobs[0].companyName.toLowerCase() === 'shadowfax' || lead.jobs[0].companyName.toLowerCase() === 'shadowfax_3pl')) {
			// 				final_leads_array.push(lead);
			// 			}
			// 			else if (lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Uploaded' && lead.jobs[0].companyName.toLowerCase() === 'zomato') {
			// 				final_leads_array.push(lead);
			// 			}
			// 		}
			// 		else if (statusList[i] === 'Candidate Not Unique' && lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Not Uploaded') {
			// 			final_leads_array.push(lead);
			// 		}
			// 		else if (statusList[i] === 'Activated' && lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Activated') {
			// 			final_leads_array.push(lead);
			// 		}
			// 		else if (statusList[i] === '1st Trip Completed' && lead && lead.jobs && lead.jobs[0].status && lead.jobs[0].status[0].title === 'Work Started') {
			// 			final_leads_array.push(lead);
			// 		}
			// 	}
			// })
			// 	setLeads(final_leads_array);
			// }

		}







		// starting code for cross referral


		// for (const lead of leads) {
		// 	if (lead.jobs[0].companyName.toLowerCase() === 'shadowfax' && lead.status !== 'Created' && !lead.scheduledInterviewId) {

		// 	} else {
		// 		const index = leadsWithStatusClubedArray.findIndex(ele => {
		// 			return ele.phoneNumber == lead.phoneNumber
		// 		})
		// 		if (index > -1) {
		// 			leadsWithStatusClubedArray[index].jobs.push(lead);
		// 			let d = leadsWithStatusClubedArray[index].jobs.sort((a, b) =>
		// 				new Date(a.createdAt) - new Date(b.createdAt)
		// 			).map(el => el.string);
		// 		} else {
		// 			leadsWithStatusClubedArray.push({name: lead.name, phoneNumber: lead.phoneNumber, companyName: lead.companyName, jobs: [lead]})
		// 		}

		// 	}
		// }

		// setLeads(leadsWithStatusClubedArray);
	}
	const searchCandidate = (e) => {
		if (e.target.value == '') {
			setLeads(prevLeads);
		}
		if (!prevLeads.length) {
			setPrevLeads(leads);
		}
		const searchResult = prevLeads.filter(ele => {
			return ele.phoneNumber.includes(e.target.value) || ele.name.toLowerCase().includes(e.target.value.toLowerCase());
		})
		setLeads(searchResult);
	}




	return (
		<>
			<div className="mainContentZ">
				<div className="header">
					<div className="header-text">
						<div className="breadcrumb-new" style={{color: '#D34612'}}>Candidates</div>
						<i className="fas fa-chevron-right icon-right Show-breadcrumb" style={{paddingRight: '10px'}}></i>
						<div className="breadcrumb-new Show-breadcrumb">{breadcrumb}</div>
						{
							showBreadcrumb ?
								<i className="fas fa-chevron-right icon-right Show-breadcrumb" style={{paddingRight: '10px'}}></i> : null
						}
						{
							showBreadcrumb ?
								<div className="breadcrumb-new Show-breadcrumb">Filter Applied</div> : null
						}
					</div>


					<div style={{display: 'flex', flexDirection: 'row', textAlign: 'right', marginLeft: 'auto'}}>
						<div style={{display: 'flex'}}>
							<div className="header-search">
								<input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
							</div>
							<div className="search-icon" onClick = {() => {if(window.innerWidth < 840) setExtraSearch(!extraSearch)}}>
									<i className="fas fa-search"></i>
							</div>
						</div>
						{ downloadAllCandidates && !atRiskTab ? 
							<div className="header-download">
								<button className="download-button" onClick={() => {handleSheet(); AllCandidatesGAEventsTracker("DownloadAllCandidatesData");}}>
									<i class="fas fa-download"></i>&nbsp;
									Export Sheets
								</button>
							</div>
						: null }
					</div>
				</div>
			{
				extraSearch ?
					<div style={{margin: '10px 0px', padding: '0px 30px'}}>
							<input className="search-input" onChange={searchCandidate} type="text" placeholder="Search Candidates" />
					</div>
				: null
			}
			<div className="more-filters">
				<div className="filter-set-container">
					<div className="tool-tip">
						<DateRangePicker
							startDate={startDate}
							startDateId="s_id"
							endDate={endDate}
							endDateId="e_id"
							onDatesChange={({startDate, endDate}) => {
																		setStartDate(startDate);
																		setEndDate(endDate);
																		setShowDateChip(true);
																		if(atRiskTab){
																			CriticalCandidatesGAEventsTracker("Edit Date Filter Critical Candidates")
																		}
																		else{
																			AllCandidatesGAEventsTracker("Edit Date Filter All Candidates")
																		}
																	}}
							focusedInput={focusedInput}
							onFocusChange={e => setFocusedInput(e)}
							displayFormat="D/M/YYYY"
							isOutsideRange={(date) => {
								if (date.isAfter(moment())) {
									return true
								}
								if (atRiskTab && date.isAfter(moment().subtract(7, 'd'))) { //if the date is before 14 days => disabled
									return true

								}
								if (atRiskTab && date.isBefore(moment().subtract(14, 'd'))) { //if the date is before 14 days => disabled
									return true
								}
								return false;
							}}
							numberOfMonths={1}
						/>
						<span className="tooltiptext">
							{!atRiskTab ? "Application Date" : "Activation Date"}
						</span>
					</div>
					<div id="category-filter"
						onClick={() => {
							if (!disableFilters) {
								setCategoryShowFilterPopup(true)
								AllCandidatesGAEventsTracker("Click On Client Filter")
								}
							}}
							className=" Dropdown-control"
						>
							<div className="filter-label">Clients</div>
							<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
						</div>

						<div id="status-filter"
							onClick={() => {
								if (!disableFilters) {
									setStatusShowFilterPopup(true);
									AllCandidatesGAEventsTracker("Click On Status Filter")
								}
							}}
							className="Dropdown-control">
							<div className="filter-label">Status</div>
							<i style={{lineHeight: 'inherit'}} className="fas fa-chevron-down"></i>
						</div>
					</div>
					<div className="Chips-container">
						{
							(startDate !== null && endDate !== null && showDateChip) ?
								<div className="single-chip">
									<span className="chip-text">
										<Moment format="D MMM" withTitle>{startDate}</Moment>
										{" - "}
										<Moment format="D MMM" withTitle>{endDate}</Moment>
									</span>
									<span className="chip-cross" onClick={() => {deleteChip('date'); setShowDateChip(false)}}> <i className="fas fa-times"></i> </span>
								</div>
								: null
						}

						{
							clientSelected.map((single_category) => {
								if (single_category.value) {
									return <div className="single-chip">
										<span className="chip-text">{single_category.label}</span>
										<span className="chip-cross" onClick={() => {deleteChip('category', single_category)}}> <i className="fas fa-times"></i> </span>
									</div>
								}
							})
						}

						{
							statusesSelected.map((single_status) => {
								if (single_status.value) {
									return <div className="single-chip">
										<span className="chip-text">{single_status.label}</span>
										<span className="chip-cross" onClick={() => {deleteChip('status', single_status)}}> <i className="fas fa-times"></i> </span>
									</div>
								}
							})
						}
					</div>
				</div>

			<div className="tabs-container">
				<button id="allCandidates"
					className="tab active-tab"
					onClick={(e) => {
						setAtRiskTab(false);
						handleActiveTabStatus('allCandidates', 'atRiskApplication')
						setbreadcrumb("All Candidates")
						GAEventsTracker("OpenAllCandidatesTab")
						setStartDate(getfirstDateOfMonth()); setEndDate(moment());
					}}>
					All Candidates
				</button>
				<button id="atRiskApplication"
					className="tab"
					onClick={(e) => {
						setAtRiskTab(true);
						handleActiveTabStatus('atRiskApplication', 'allCandidates')
						setbreadcrumb("Critical Candidates")
						GAEventsTracker("OpenAtRiskTab")
						setStartDate(moment().subtract(13, 'd')); setEndDate(moment().subtract(7, 'd'));
					}}>
					<i className="fas fa-exclamation-circle" style={{color: '#D34612'}}> &nbsp; </i>
					<span>Critical Candidates</span>
				</button>
			</div>

				<div className="leads-wrapper">



					{
						atRiskTab && showWarningMessageAtRisk ?
							<div className="warning-message-at-risk-container">
								<div className="warning-message-atrisk">
									<div style={{width: '9%', lineHeight: 'inherit', textAlign: 'right', margin: 'auto'}}>
										<i className="fas fa-exclamation-circle" style={{color: '#D34612'}}> &nbsp; </i>
									</div>
									<div style={{width: '82%', textAlign: 'justify'}}>
										You can view the list of Shadowfax candidates that have not taken any trips even after 7 days since activation in this section.
									</div>
									<div style={{textAlign: 'center', alignItems: 'center', margin: 'auto'}}>
										<i className="fas fa-times icon-cross" onClick={() => {setShowWarningMessageAtRisk(false)}}></i>
									</div>
								</div>
							</div>
							: null

					}


					{
						(mitra) ?
							(mitra.role === 'admin') ?
								atRiskTab ?
									(leads.length > 0) ?
										<div className="export-file-container">
											<div className="export-list">Download Sheet</div>
											<div className="download-file-container">
												<button className="download-file-button" onClick={() => {setAtRiskLeads(leads); GAEventsTracker("DownloadCSV"); trackEvent('critical_candidates_list_downloaded', {})}}>
													<CSVLink {...CSVDATA} style={{color: "#D34612", textDecoration: 'none'}}>
														<i className="fas fa-download"></i>
														&nbsp;
														Download Sheet
													</CSVLink>
												</button>
											</div>
										</div>
										: null
									: null
								: null
							: null
					}

					{loaded ? leads.length === 0 ? (
						<div className="leads-error">No leads found</div>
					) : (
						leads.map((lead, index) => (
							<Lead
								key={index}
								lead={lead}
								setShowDetail={setShowDetail}
								setSelectedLead={setSelectedLead}
								setShowContactBottomSheet={setShowContactBottomSheet}
							/>
						))
					) : (
						<div className="loader_clip">
							<ClipLoader size={100} />
						</div>
					)}
					<br />
				</div>
				<div className="bottom-detail-wrapper">
					<FilterPopup heading="Select Application Status" visible={showStatusFilterPopup} setShowFilterPopup={setStatusShowFilterPopup} setCategory={setStatusesSelected} list={statusesSelected} setShowBreadcrumb={setShowBreadcrumb} />
					<FilterPopup heading="Select Client" visible={showCategoryFilterPopup} setShowFilterPopup={setCategoryShowFilterPopup} setCategory={setClientSelcted} list={clientSelected} setShowBreadcrumb={setShowBreadcrumb} />
					<Contact visible={showContactBottomSheet} setShowContact={setShowContactBottomSheet} lead={selectedLead} />
					<CandidateProfilePopup visible={showDetail} setShowDetail={setShowDetail} lead={selectedLead} statuses={clientStatus}></CandidateProfilePopup>
				</div>
			</div>
			<div className="navigation-container">
				<NavigationTab currentTab={1} />
			</div>
		</>

	);
};

export default LeadsPage;
