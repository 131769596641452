import React, {useEffect, useState} from 'react';
import NavigationTab from '../common/NavigationTab.component';
import '../../css/logout.css';
import {Button} from '@rmwc/button';
import * as loginService from '../../service/loginService';
import * as logoutService from '../../service/logoutService';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import useMoengage from '../../hooks/useMoengage';

const DEFAULT = 'DEFAULT';

const LogoutPage = () => {
    const {register} = useForm();
    const [mitra, setMitra] = useState({});
    const [createUser, setCreateUser] = useState(false);
    const [createUserForm, setCreateUserForm] = useState(false);

    const [viewUserFlag, setViewUserFlag] = useState(false);
    const [viewUser, setViewUser] = useState([]);
    const [updateRowIndex, setUpdateRowIndex] = useState(null);
    const [updateRow, setUpdateRow] = useState({});


    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(DEFAULT);
    const [role, setRole] = useState(DEFAULT);
    const [managerId, setManagerId] = useState('');
    const [error, setError] = useState('');
    const [createMoengageUser, trackEvent] = useMoengage();
    const history = useHistory();
	const AccountGAEventsTracker = useGAEventsTracker("Accounts")

    useEffect(() => {

        loginService
            .validateSession()
            .then((response) => {
                setMitra(response.data.mitra);
                setManagerId(response.data.mitra.id);
            })
            .catch((error) => history.push('/'));
    }, []);

    useEffect(() => {
        var regex = new RegExp("^[0-9]{10}$");
        if (!regex.test(phoneNumber)) {
            setError('Please enter only the 10 digit mobile no without any prefix (+91 or 0) and without any spaces between the digits.')
        }
        else {
            setError('')
        }
    }, [phoneNumber])
    const handleLogout = (e) => {
        loginService
            .inValidateSession()
            .then((response) => {
                history.push('/');
                setMitra({});
            })
            .catch((error) => {
                console.log('Error logging out: ', error);
            });
    };
    const handleUser = (e) => {
        setCreateUser(!createUser);
    }
    const handleCreateUser = (e) => {
        AccountGAEventsTracker("Open Create New User")
        setCreateUserForm(true);
    }
    const isUserFormValid = () => {
        return name && phoneNumber.length === 10 && status !== DEFAULT && role !== DEFAULT && !error;
    };
    const submitBasicForm = async () => {
        AccountGAEventsTracker("Submit New Team Member")
        logoutService.postUserInfoForm(name, phoneNumber, status, role, email, managerId).then((response) => {
            if (response && response.data) {
                alert(response.data.message);
                if (response.data.validLead) {
                    setCreateUserForm(false);
                    setCreateUser(false);
                    trackEvent('team_member_added', {team_member_phonenumber: phoneNumber})
                }
            }
        });
    };
    const handleViewUser = (e) => {
        AccountGAEventsTracker("Open View User")
        setViewUserFlag(true);
        fetchUsers()
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handleNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };
    const breadCrumReset = (e) => {
        e.preventDefault();
        setCreateUserForm(false);
        setViewUserFlag(false);
    }
    const fetchUsers = async () => {
        const users = await logoutService.getMitrasByManager();
        setViewUser(users.data.data);
    }
    const handleSelect = (value, name) => {
        let row = updateRow;
        row[name] = value;
        setUpdateRow(row);
    }
    const setUpdateRowInd = (index) => {
        if (updateRowIndex == index) {
            setUpdateRowIndex(null);
            return

        }
        setUpdateRowIndex(index);
        setUpdateRow(viewUser[index]);
    }
    const submitUpdate = async (e) => {
        AccountGAEventsTracker("Update User")
        console.log(updateRow);
        const update = await logoutService.updateMitrasById(updateRow);
        if (update.data.status) {
            alert(update.data.message);
            setUpdateRowInd(null);
            setUpdateRow({});
            fetchUsers();
        } else {
            alert(update.data.message);

        }
    }
    return (
        <div className="main-className">
            {
                !createUserForm && !viewUserFlag ?
                    <div>
				        <div className="mainContentZ">
                            <div className="mitraName">
                                <h3>
                                    Name <span>{mitra.name}</span>
                                </h3>
                            </div>
                            {
                                mitra && mitra.role == 'admin' ? <div className="mitraName" >
                                    <h3 id="user-management" style={{marginBottom: 0, cursor: 'pointer'}} onClick={handleUser}>User Management</h3>
                                    {createUser ? <h2 id="create-new-user" style={{marginTop: 0}} onClick={handleCreateUser}>Create New User</h2> : null}
                                    {createUser ? <h2 id="view-user" style={{marginTop: 0}} onClick={handleViewUser}>View User</h2> : null}
                                </div> : null
                            }
                            <Button className="btn_tertiary" id="log-out" label="Log out" onClick={handleLogout} />
                        </div>
                        <div className="navigation-container">
                            <NavigationTab currentTab={4} />
                        </div>
                        <div className="illustration" />
                    </div> : null
            }
            {createUserForm ?
                <div className="Home Logout container">
                    <div style={{float: 'left'}}> <a href="" onClick={breadCrumReset}>Accounts</a>/Create User</div>

                    <form>
                        <div className="form-control">
                            <div className="input-text-title field-required">Name</div>
                            <input
                                id="create-mitra-form-name"
                                label="Name"
                                name="name"
                                type="text"
                                onChange={handleNameChange}
                                placeholder="Name"
                                ref={register({required: true, minLength: 2})}
                            />
                        </div>
                        <div className="form-control">
                            <div className="input-text-title field-required">Phone number</div>
                            <input
                                id="create-mitra-form-phone"
                                label="Phone Number"
                                name="phoneNumber"
                                type="tel"
                                onChange={handleNumberChange}
                                placeholder="Phone Number"
                                ref={register({required: true, minLength: 10, maxLength: 10})}
                            />
                            <p className='errorMsg'>
                                {error}
                            </p>
                        </div>
                        <div className="form-control">
                            <div className="input-text-title field-required">Status</div>
                            <select
                                id="create-mitra-form-status"
                                name="status"
                                onChange={handleStatusChange}
                                defaultValue={DEFAULT}
                                ref={register({required: true})}
                                value={status}
                            >
                                <option value={DEFAULT} disabled>
                                    Status
                                </option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                        <div className="form-control">
                            <div className="input-text-title field-required">Role</div>
                            <select
                                id="create-mitra-form-role"
                                name="role"
                                onChange={handleRoleChange}
                                defaultValue={DEFAULT}
                                ref={register({required: true})}
                                value={role}
                            >
                                <option value={DEFAULT} disabled>
                                    Role
                                </option>
                                <option value="teamMember">Team Member</option>
                                <option value="teamLeader">Team Leader</option>
                            </select>
                        </div>
                        <div className="form-control">
                            <div className="input-text-title">Email</div>
                            <input
                                id="create-mitra-form-email"
                                label="Email"
                                name="email"
                                type="email"
                                onChange={handleEmailChange}
                                placeholder="Email"
                                ref={register({required: true, minLength: 2})}
                            />
                        </div>
                        <div className="form-control">
                            <input id="create-mitra-form-submit" className="Home submit-mitra" type="button" value="Save" disabled={!isUserFormValid()} onClick={submitBasicForm} />
                        </div>
                    </form>
                    <div className="navigation-container">
                        <NavigationTab currentTab={4} />
                    </div>
                </div>

                :
                null
            }

            {
                viewUserFlag ? <div className="container">
                    <div style={{float: 'left'}}> <a href="" onClick={breadCrumReset}>Accounts</a>/View User</div>

                    <table className="table">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Role</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewUser.length && viewUser.map((user, index) => (

                                <tr key={index + 'User'}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{user.name}</td>
                                    <td>{index == updateRowIndex ? <select id="role-select" onChange={(e) => {handleSelect(e.target.value, 'role')}}>
                                        <option value='' selected disabled> Select a role</option>
                                        <option value='admin'>Admin</option>
                                        <option value='teamMember'>Team Member</option>
                                        <option value='teamLeader'>Team Leader</option>
                                    </select> : user.role}


                                    </td>
                                    <td>{user.phoneNumber}</td>
                                    <td>
                                        {
                                            index == updateRowIndex ? <select id="role-select" onChange={(e) => {handleSelect(e.target.value, 'status')}} >
                                                <option value='' selected disabled> Select a Status</option>
                                                <option value='Active'>Active</option>
                                                <option value='Inactive'>Inactive</option>
                                            </select> : user.status
                                        }


                                        {/* {user.status} */}

                                    </td>
                                    <td>
                                        <button type="button" onClick={() => {
                                            setUpdateRowInd(index)
                                            if(index !== updateRowIndex){
                                                AccountGAEventsTracker("Clicks Edit User")
                                            }
                                        }} className="btn btn-secondary">{index == updateRowIndex ? 'Cancel' : 'Edit'}</button>

                                        {index == updateRowIndex ? <button type="button" onClick={submitUpdate} className="btn btn-info ml-1" style={{'marginLeft': '9px'}}> Update</button> : null}


                                    </td>
                                </tr>

                            ))}

                        </tbody>
                    </table>
                    {!viewUser.length ? <h4>No user Found</h4> : null}
                </div> : null
            }
        </div >
    );
};
export default LogoutPage;
