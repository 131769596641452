import React, {useReducer, useState} from 'react';
import {useHistory} from 'react-router-dom';
import '../../css/lead.css';
import {logEvent} from '../../service/tracking';
import trackingConstants from '../../constants/trackingConstants';
// import {BrowserRouter as Route, Router, Redirect} from 'react-router-dom';
import {AdditionalFormsURLEnum} from '../../utils/utility';
import Modal from 'react-modal';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import moment from "moment";
import useMoengage from '../../hooks/useMoengage';



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


Modal.setAppElement('#root')

const Lead = ({lead, setShowDetail, setSelectedLead, fromSearch, setShowContactBottomSheet}) => {

  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")

  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [openDynamicForm, setDynamicForm] = useState(false);
  const history = useHistory();
  const documents = ['Aadhar', 'PAN', 'RC', 'Bank account'];
  const [createMoengageUser, trackEvent] = useMoengage();
  // const handleOpenAndAddDocuments = async () => {
  //   if (lead && lead.id && lead.mitraId) {
  //     logEvent(
  //       'ClickDocumentsPage',
  //       trackingConstants.EVENT_GROUP_VIEW_LEAD,
  //       fromSearch
  //         ? trackingConstants.EVENT_SCREEN_SEARCH
  //         : trackingConstants.EVENT_SCREEN_CANDIDATE
  //     );
  //     window.open(process.env.REACT_APP_JF1_BASE_URL + `/documents?l=${lead.id}&m=${lead.mitraId}`);
  //   } else {
  //     console.log('Lead and Mitra id not found');
  //   }
  // };
  // const openForm = (lead) => {
  // localStorage.setItem('lead', JSON.stringify(lead))
  // setDynamicForm(true);
  // };

  // const checkClientAdditionalForm = (clientName) => {
  //   return clientName in AdditionalFormsURLEnum;
  // };

  const diffBetweenTwoDates = (first, last) => {
    const diff = last.diff(first, "days")
    if (diff < 10) {
      return `0${diff}`
    }
    else return diff;
  }

  const checkAtRisk = (lead) => {
    let data = false
    if (lead && lead.jobs) {
      lead.jobs.map(single_job => {
        const DateObj = new Date();
        const differnceInDates = (DateObj.getTime() - Date.parse(single_job.status[0].StatusTimeStamp)) / (1000 * 3600 * 24);
        if (differnceInDates >= 7 && differnceInDates <= 14 && single_job && single_job.displayInUI && single_job.status && single_job.status[0].title.toLowerCase() === "activated" && single_job.companyName && (single_job.companyName.toLowerCase() === "shadowfax" || single_job.companyName.toLowerCase() === "shadowfax_3pl")) {
          // if (differnceInDates >= 7 && differnceInDates <= 14 && single_job.status && single_job.status[0].title.toLowerCase() === "activated"  && single_job.companyName  && (single_job.companyName.toLowerCase() === "shadowfax" || single_job.companyName.toLowerCase() === "shadowfax_3pl")) {
          data = true
        }
      })
    }
    return data
  }

  const mapStatusToCompany = (companyName, status) => {
    if (status !== null && companyName !== null) {
      if (companyName.toLowerCase() === "shadowfax" || companyName.toLowerCase() === "shadowfax_3pl") {
        if (status.toLowerCase() === 'uploaded') return 'Application Created'
        if (status.toLowerCase() === 'created') return 'Candidate Unique'
      }
      else if (companyName.toLowerCase() === "zomato") {
        if (status.toLowerCase() === 'api failed' || status === '') return 'Application Created'
        if (status.toLowerCase() === 'uploaded') return 'Candidate Unique'
      }
      else if (companyName.toLowerCase() === 'uber' || companyName.toLowerCase() === 'uber moto' || companyName.toLowerCase() === 'uber' || companyName.toLowerCase() === 'uber auto') {
        if (status.toLowerCase() === 'uploaded') return 'Candidate Unique'
      }

      else if (companyName.toLowerCase() === 'rapido') {
        if (status.toLowerCase() === 'uploaded') return 'Candidate Unique'
      }

      if (status.toLowerCase() === 'not uploaded') return 'Candidate Not Unique'
      if (status.toLowerCase() === 'signup completed') return 'Signup Completed'
      if (status.toLowerCase() === 'referred to client') return 'Referred to Client'
      if (status.toLowerCase() === 'documents uploaded') return 'Documents Uploaded'
      if (status.toLowerCase() === 'bgc passed') return 'BGC Passed'
      if (status.toLowerCase() === 'bgc rejected') return 'BGC Rejected'
      if (status.toLowerCase() === 'activated') return 'Activated'
      if (status.toLowerCase() === 'onboarded') return 'Onboarded'
      if (status.toLowerCase() === 'order started') return 'Order Started'
      if (status.toLowerCase() === 'work started') return '1st Trip Completed'
      if (status.toLowerCase() === '5th_order_date') return '5th Trip Completed'
      if (status.toLowerCase() === '10th_order_date') return '10th Trip Completed'
      if (status.toLowerCase() === '15th_order_date') return '15th Trip Completed'
      if (status.toLowerCase() === '20th_order_date') return '20th Trip Completed'
      if (status.toLowerCase() === '25th_order_date') return '25th Trip Completed'
      if (status.toLowerCase() === '30th_order_date') return '30th Trip Completed'
      if (status.toLowerCase() === '35th_order_date') return '35th Trip Completed'
      if (status.toLowerCase() === '40th_order_date') return '40th Trip Completed'
      if (status.toLowerCase() === '45th_order_date') return '45th Trip Completed'
      if (status.toLowerCase() === '50th_order_date') return '50th Trip Completed'
    }
  }

  const viewAdditionalForm = (lead) => {
    localStorage.setItem('lead', JSON.stringify(lead))
    if (lead.scheduledInterviewId) {
      history.push('/moreInfo')

    } else {
      history.push({
        pathname: '/moreInfo',
        route: 'jobRecommendationPage'
      })
    }


  }

  const trackViewDetails = () => {
    trackEvent('view_details_button_clicked', {});
  }

  return (
    <div className="detail-body-container">
      <div className="detail-element-body">
        <div className="card-container">
          <div className="lead-card-left">
            <div className="contact-details-container">
              <div className="contact-details">
                <div className="lead-name">
                  {lead.name}
                </div>
                <div className="phone-container" onClick={(e) => {
                  if (window.innerWidth > 700) {
                    setShowDetail(true);
                  }
                  else {
                    setShowContactBottomSheet(true);
                  }
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                  setSelectedLead(lead);
                  e.stopPropagation();
                }}>
                  <i className="fas fa-phone phone-icon"></i>
                  <div className="phoneNumber">{lead.phoneNumber}</div>
                </div>
              </div>
            </div>
            <div className="job-container">
              {
                lead ? lead.jobs ?
                  lead.jobs.map((job, index) => (
                    <div key={index} className="single-job-container">
                      <div className="client-logo">
                        <img className="imgResponse" src={"/image/" + job.companyName + ".png"} />
                      </div>
                      {
                        (lead.jobs[index].status && (lead.jobs[index].status[0].title !== null && lead.jobs[index].status[0].title !== '') && !isNaN(diffBetweenTwoDates(moment(lead.jobs[index].status[0].StatusTimeStamp), moment())))
                          ?
                          <div className="onboarding-schedule">
                            <div className="diffInDate">
                              Last Updated: {diffBetweenTwoDates(moment(lead.jobs[index].status[0].StatusTimeStamp), moment())} {diffBetweenTwoDates(moment(lead.jobs[index].status[0].StatusTimeStamp), moment()) > 1 ? ' days' : ' day'} ago.
                            </div>
                            <div className="leadsCardStatus d-flex flex-row" style={{color: '#D34612'}}>
                              {mapStatusToCompany(job.companyName, job.status[0].title)}
                              {
                                index == lead.jobs.length - 1 && AdditionalFormsURLEnum[job.companyName] && !job.scheduledInterviewId ? <>
                                  <span className="complete-application-button upload-Icon" style={{background: 'rgb(211, 70, 18)', color: '#fff', 'marginRight': '5px'}} onClick={() => {viewAdditionalForm(job); trackEvent('complete_application_clicked_on_candidate_card', {candidate_phone_number: lead.phoneNumber, application_id: lead.jobs[index].scheduledInterviewId})}}>
                                    <i className="fas fa-upload" aria-hidden="true"></i>
                                    <span className="btntext">Complete Application</span>
                                  </span>
                                </>
                                  : null
                              }
                            </div>
                          </div>
                          :
                          <div className="onboarding-schedule">
                            <div className="diffInDate">
                              Last Updated: {diffBetweenTwoDates(moment(lead.jobs[index].createdAt), moment())} days ago.
                            </div>
                            <div className="leadsCardStatus d-flex flex-row" style={{color: '#D34612'}}>
                              Application Created
                              {
                                index == lead.jobs.length - 1 && AdditionalFormsURLEnum[job.companyName] && !job.scheduledInterviewId ? <>
                                  <span className="complete-application-button upload-Icon" onClick={() => {viewAdditionalForm(job); trackEvent('complete_application_clicked_on_candidate_card', {candidate_phone_number: lead.phoneNumber, application_id: lead.jobs[index].scheduledInterviewId})}} style={{background: 'rgb(211, 70, 18)', color: '#fff'}}>
                                    <i className="fas fa-upload" aria-hidden="true"></i>
                                    <span className="btntext">Complete Application</span>
                                  </span>
                                </>
                                  : null
                              }
                            </div>
                          </div>
                      }
                    </div>
                  ))
                  : null : null
              }
            </div>
          </div>
          <div className="lead-card-right">
            <div className="user-image-container">
              <div className="user-image">
                {lead.name.charAt(0).toUpperCase()}
                {
                  checkAtRisk(lead) ? <div className="counter counter-lg"><i className="fas fa-exclamation"></i></div> : null
                }
              </div>
            </div>
            <div className="d-flex justify-content-end" style={{marginTop: 'auto'}}>
              {lead.jobs.length && AdditionalFormsURLEnum[lead.jobs[lead.jobs.length - 1].companyName] && !lead.jobs[lead.jobs.length - 1].scheduledInterviewId ? <div onClick={() => {viewAdditionalForm(lead.jobs[lead.jobs.length - 1])}} className="complete-application-button upload-Icon-mobile" style={{background: 'rgb(211, 70, 18)', color: '#fff'}}>
                <i className="fas fa-upload" aria-hidden="true"></i>
              </div> : null}

              <div className="view-details-container"
                onClick={(e) => {
                  setShowDetail(true);
                  // logEvent('ShowDetails', trackingConstants.EVENT_GROUP_VIEW_LEAD, fromSearch? trackingConstants.EVENT_SCREEN_SEARCH : trackingConstants.EVENT_SCREEN_CANDIDATE);
                  setSelectedLead(lead);
                  GAEventsTracker('OpenViewDetails')
                  e.stopPropagation();
                }}>
                <i className="fas fa-chevron-right right-arrow"></i>
                <button className="view-details-button" onClick={trackViewDetails}>View Details</button>
              </div>
            </div>

          </div>

        </div>

      </div>
      <div>
        {lead.createdAtForSearch ? (
          <div className="lead-added">Added - {lead.createdAtForSearch}</div>
        ) : null}
      </div>
    </div>
  );
};
export default Lead;
